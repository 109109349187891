import React, { useEffect } from "react";

const GuideBox = ({ guideBoxData }) => {
  const design = JSON.parse(guideBoxData.design || "{}");

  const applyStyles = (selectors, style) => {
    selectors.forEach((selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        Object.assign(element.style, style);
      });
    });
  };

  useEffect(() => {
    design.styles.forEach((styleObject) => {
      applyStyles(styleObject.selectors, styleObject.style);
    });
  }, [design.styles]);

  return (
    <div className="bg-White overflow-x-auto mx-2 my-3">
      <div id="card-title">
        <div id="box-content" className="p-3">
          <div
            dangerouslySetInnerHTML={{
              __html: guideBoxData.content ?? "<p></p>",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default GuideBox;
