import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Table,
  Tag,
  notification,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import {
  UserAddOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { EditIcon } from "../../../../icons/common-icon";
import "../../bulletin-pages/userTable.css";
import {
  useDeleteMailUser,
  useGetMailUsers,
  usePostMailUser,
} from "../../../../hooks/mailUser/mailUser.hook";

const Users = () => {
  const {
    data: mailUserData,
    isLoading: isMailUserLoading,
    refetch: refetchMailUserData,
  } = useGetMailUsers();

  const [isDeleteMailUserModalOpen, setDeleteMailUserModalOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { mutate: mutateDeleteMailUser } = useDeleteMailUser(
    () => {
      notification.success({ description: "Mail User Deleted" });
      refetchMailUserData();
      setDeleteMailUserModalOpen(false);
    },
    () => notification.error({ description: "You can not delete this user" })
  );
  const onMailUserDelete = () => {
    mutateDeleteMailUser(selectedUserId);
  };
  const onMailUserDeleteCancel = () => {
    setDeleteMailUserModalOpen(false);
  };
  const [isNewMailUserModalOpen, setNewMailUserModalOpen] = useState(false);
  const { mutate: mutateMailUser } = usePostMailUser(
    () => notification.success({ message: "New User Created Successfully" }),
    () => notification.error({ message: "New User Could Not Created !" })
  );
  const handleNewMailUser = (value) => {
    mutateMailUser(
      { ...value, canSendMail: true },
      {
        onSuccess: () => {
          setNewMailUserModalOpen(false)
          refetchMailUserData()
        },
      }
    );
  };

  const handleNewMailUserClose = () => {
    setNewMailUserModalOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const pagination = {
    defaultCurrent: 1,
    current: currentPage,
    pageSize: 10, // Define the number of items per page
    total: mailUserData ?  mailUserData.length : 0 , // Define the total number of items
    onChange: handlePageChange, // Define the function to handle page changes
    itemRender: (current, type, originalElement) => {
      if (type === "prev") {
        return (
          <>
            {" "}
            <ArrowLeftOutlined /> Previous
          </>
        );
      }
      if (type === "next") {
        return (
          <>
            Next <ArrowRightOutlined />{" "}
          </>
        );
      }
      if (type === "page") {
        return <div className="rounded border-0">{current}</div>;
      }
      return originalElement;
    },
    position: ["bottomCenter"],
  };
  return (
    <>
      {isMailUserLoading ? (
        <div>Loading</div>
      ) : (
        <div>
          <Row className="p-6 bg-White mt-6 rounded-md">
            <Col span={21}>
              <Row>
                <Col
                  span={1}
                  className="flex justify-center items-center mr-3 flex-wrap"
                >
                  <UserAddOutlined className="text-2xl pt-1 pb-3 px-3 rounded-full bg-Primary-50 text-Primary-500" />
                </Col>
                <Col span={22} className="flex flex-col justify-between">
                  <div className="font-medium text-base">
                    Bültenlere Kullanıcı Ekle
                  </div>
                  <div className="font-normal text-Grey-600">
                    Rehberlerinizi ve veritabanlarınızı büyük kategorilere ve
                    alt kategorilere düzenleyin. Yeni kategori ekleyin ve alt
                    kategoriler oluşturun.
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={3} className="flex justify-end items-center flex-wrap ">
              <Button
                size="large"
                className="rounded-lg bg-Primary-500 text-White hover:border-Primary-500 hover:text-Primary-500"
                onClick={() => setNewMailUserModalOpen(true)}
              >
                Kullanıcı Ekle
              </Button>
            </Col>
          </Row>
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0
                ? " text-Black text-sm font-medium bg-White "
                : "text-black text-sm font-medium bg-Grey-50 border"
            }
            className="flex-1 mt-6"
            columns={[
              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    Kullanıcı Adı
                  </div>
                ),
                dataIndex: "userName",
                key: "userName",
                render: (_, mailUser) => (
                  <div>{`${mailUser?.firstName} ${mailUser?.lastName}`}</div>
                ),
              },
              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    Eposta Adresi
                  </div>
                ),
                dataIndex: "email",
                key: "email",
                render: (_, mailUser) => (
                  <div className="font-normal  text-sm text-Grey-500">
                    {mailUser?.emailAddress}
                  </div>
                ),
              },
              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    Bültenler
                  </div>
                ),
                dataIndex: "bulletins",
                key: "bulletins",
                render: (_, mailUser) => (
                  <>
                    {mailUser?.mailGroupList.map((tag, idx) => {
                      let color = idx === 0 ? "purple" : "blue";
                      if (idx < 3) {
                        return (
                          <Tag
                            className="rounded-lg border-0 font-medium"
                            color={color}
                            key={tag}
                          >
                            {tag?.category?.name?.toUpperCase()}
                          </Tag>
                        );
                      }
                    })}
                    {mailUser?.mailGroupList.length - 3 > 0 && (
                      <Tag className="rounded-lg border-0 font-medium">
                        +{mailUser?.mailGroupList.length - 3}
                      </Tag>
                    )}
                  </>
                ),
              },
              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    Sisteme Eklenme Tarihi
                  </div>
                ),
                dataIndex: "created",
                key: "created",
                render: (text) => (
                  <div className="font-normal  text-sm text-Grey-500">
                    {text}
                  </div>
                ),
              },
              {
                dataIndex: "",
                key: "x",
                render: (_, mailUser) => (
                  <div className="flex gap-4 ">
                    <Button
                      className="bg-Error-200 border-Error-500 hover:border-Error-200 focus:border-Error-200 rounded-md "
                      icon={<DeleteOutlined className="text-Error-500" />}
                      onClick={() => {
                        setDeleteMailUserModalOpen(true);
                        setSelectedUserId(mailUser?.id);
                      }}
                    />
                    <Button
                      className="bg-White border-Primary-500 hover:border-Primary-200 focus:border-Primary-200  flex justify-center items-center rounded-md"
                      icon={
                        <div className="w-4 h-4 ml-0.5">
                          <EditIcon className="stroke-Primary-500 w-full h-full" />
                        </div>
                      }
                    />
                  </div>
                ),
              },
            ]}
            pagination={false}
            footer={() => (
              <Row className="flex items-center justify-center">
                <Pagination
                  className="flex-1 text-center"
                  {...pagination}
                />
              </Row>
            )}
            dataSource={mailUserData.slice(
              (currentPage-1) * pagination.pageSize,
              currentPage * pagination.pageSize
            )}
          />
        </div>
      )}
      <NewMailUserMadal
        isOpen={isNewMailUserModalOpen}
        handleClose={handleNewMailUserClose}
        handleOk={handleNewMailUser}
      />
      <DeleteMailUserModal
        isOpen={isDeleteMailUserModalOpen}
        onCancel={onMailUserDeleteCancel}
        onSubmit={onMailUserDelete}
      />
    </>
  );
};

const NewMailUserMadal = ({ isOpen, handleOk, handleClose }) => {
  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      footer={null}
      centered
    >
      <div className="flex">
        <div>
          <MailOutlined className=" pb-2 pt-1 px-3 rounded-full bg-Secondary-100 text-2xl text-Secondary-500 font-bold" />
        </div>
        <div className="flex flex-col ml-2">
          <h6 className="font-medium">Mail Kullanıcıları</h6>
          <div className="text-Grey-600 font-normal">Yeni Kullanıcı Ekleme</div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <Form onFinish={handleOk} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: "User name is required",
              },
            ]}
            name={"firstName"}
            label={"First Name"}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "User last name is required",
              },
            ]}
            name={"lastName"}
            label={"Last Name"}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "User email is required",
                type: "email",
              },
            ]}
            name={"emailAddress"}
            label={"Email Address"}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item className="flex flex-col mb-0 items-end">
            <Button
              className=" bg-Primary-500 text-White rounded-lg"
              size="large"
              htmlType="submit"
            >
              Submit Form
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

const DeleteMailUserModal = ({ isOpen, setOpen, onCancel, onSubmit }) => {
  return (
    <Modal
      centered
      onCancel={() => {
        setOpen(false);
      }}
      open={isOpen}
      setOpen={setOpen}
      footer={null}
      closable={false}
    >
      <div className="flex flex-col">
        <div className="flex items-center mb-6">
          <div className="ml-3 font-Inter flex-1">
            <div className="font-medium text-Black text-base text-center">
              Emin misiniz ?
            </div>
          </div>
        </div>
        <div className="flex justify-between ml-3">
          <Button
            onClick={onSubmit}
            className="rounded-lg bg-Primary-500 text-Primary-50 border border-Primary-500 hover:bg-Primary-50 hover:border hover:border-Primary-500 hover:text-Primary-500"
          >
            Submit
          </Button>
          <Button
            onClick={onCancel}
            className="rounded-lg bg-Secondary-50 text-Secondary-500 border border-Primary-50 hover:bg-Secondary-500 hover:border hover:border-Secondary-500 hover:text-Primary-50"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default Users;
