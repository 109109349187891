import Navbar from "../components/navbar/navbar";
import React from "react";
const MainLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div>
        <div className="px-11 py-8">{children}</div>
      </div>
    </>
  );
};

export default MainLayout;
