import React from "react";

const LanguageChangeButton = ({ handleSetLanguage }) => {
  return (
    <button
      className="font-Inter font-medium transition-all text-primary-500 p-2 rounded-md hover:bg-Grey-100"
      onClick={handleSetLanguage}
    >
      <span
        className={
          window.localStorage.getItem("languagePreference") === "tr-TR" &&
          "underline p-1"
        }
      >
        TR
      </span>
      /<span className={
          window.localStorage.getItem("languagePreference") === "en-US" &&
          "underline p-1"
        }>EN</span>
    </button>
  );
};

export default LanguageChangeButton;
