import * as api from "../api";

export const getUser = async (userId) => {
  return api.get(`/user/${userId}`);
};

export const updateUser = async (userId, body) => {
  return api.put(`/user/${userId}`, body);
};

export const getAuthors = (page, size, searchKey) => {
  return api.get(`/user?page=${page}&searchKey=${searchKey}&size=${size}`);
};

export const getAuthor = (authorId) => {
  return api.get(`/user/${authorId}`);
};

export const postUser = (body) => {
  return api.post(`/user`, body);
};

export const postResetPasswordUser = (body) => {
  return api.post(`/user/reset-password`, body);
};

export const deleteUser = (id) => {
  return api.del(`/user/${id}`);
};
