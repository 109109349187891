import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Checkbox,
  Button,
  Spin,
  notification,
} from "antd";
import { useState } from "react";
import { BadgeSuccess } from "../../../common/components/buttons/badge";
import InformationBox from "../../../common/views/info-box/information-box";
import { useCreateUser, useGetAuthors } from "../../../hooks/user/user.hooks";
import { AddLibrarianIcon, PlusSign } from "../../../icons/common-icon";
import getUserRole from "../../../util/get-user-role";
import { localization } from "../../../localization/localization";
import AddButton from "../../../common/components/buttons/add-button";
import AuthorListView from "../../../common/views/author/author-list-view";

const pageData = {
  pageName: localization(
    "/pages/app/author-pages/author-list-editor/librarians"
  ),
  contentName: localization(
    "/pages/app/author-pages/author-list-editor/librarian"
  ),
  placeholderName: localization(
    "/pages/app/author-pages/author-list-editor/search-author"
  ),
  informationText: localization(
    "/pages/app/author-pages/author-list-editor/discover-authors"
  ),
};

const getSelections = (selectedFilters) => {
  // TODO: Add filters for database and guides
};
const AddAuthorModalContent = ({
  handleSubmit,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [isVisible, setIsVisible] = useState("Kapalı");
  const onSubmit = (formData) => {
    handleSubmit({
      ...formData,
      userRole: formData.isAdmin ? "ADMIN" : "EDITOR",
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      centered
      width={600}
      open={isModalVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <Row>
          <div className="flex font-Inter font-medium text-Black items-center">
            <div className="mr-2 bg-Secondary-100 rounded-full p-3">
              <PlusSign className={"stroke-Secondary-500"} />
            </div>
            {getUserRole() === "ADMIN" && (
              <div>
                <div className="text-base">
                  <h>
                    {localization(
                      "/pages/app/author-pages/author-list-editor/add"
                    )}
                  </h>
                </div>
                <div className="text-Grey-600 font-normal">
                  {localization(
                    "/pages/app/author-pages/author-list-editor/enter-info"
                  )}
                </div>
              </div>
            )}
          </div>
        </Row>
        <Row gutter={12} className="text-Black text-base font-Inter mt-6">
          <Col span={12}>
            <Form.Item
              className="font-medium"
              name="name"
              label={localization(
                "/pages/app/author-pages/author-list-editor/name"
              )}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(),
                },
              ]}
            >
              <Input
                placeholder={localization(
                  "/pages/app/author-pages/author-list-editor/enter-name"
                )}
                size="large"
                className="rounded-md font-normal hover:border-Primary-500"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="font-medium"
              name="surname"
              label={localization(
                "/pages/app/author-pages/author-list-editor/surname"
              )}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(),
                },
              ]}
            >
              <Input
                placeholder={localization(
                  "/pages/app/author-pages/author-list-editor/enter-surname"
                )}
                size="large"
                className="rounded-md font-normal hover:border-Primary-500"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={"description"}
              size="large"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(),
                },
                {
                  min: 20,
                  max: 1500,
                  message: localization(
                    "/pages/app/author-pages/author-list-editor/min-20-max-1500"
                  ),
                },
              ]}
              className="font-medium"
              label={localization(
                "/pages/app/author-pages/author-list-editor/librarian-description"
              )}
            >
              <Input.TextArea
                className="font-normal hover:border-Primary-500"
                placeholder={localization(
                  "/pages/app/author-pages/author-list-editor/add-description"
                )}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="email"
              size="large"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(),
                },
              ]}
              className="font-medium"
              label={localization(
                "/pages/app/author-pages/author-list-editor/email"
              )}
            >
              <Input
                type="email"
                className="rounded-md font-normal hover:border-Primary-500"
                placeholder={localization(
                  "/pages/app/author-pages/author-list-editor/enter-email"
                )}
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="emailVisible"
              initialValue={false}
              valuePropName="checked"
            >
              <Row>
                <Col span={2}>
                  <Checkbox
                    onChange={(e) =>
                      e.target.checked
                        ? setIsVisible("Açık")
                        : setIsVisible("Kapalı")
                    }
                    defaultChecked={false}
                  />
                </Col>
                <Col span={22}>
                  <Row className="gap-2">
                    <p className="text-Black text-md font-medium">
                      {localization(
                        "/pages/app/author-pages/author-list-editor/email-visibility"
                      )}
                    </p>
                    {":"}
                    <p className="text-Grey-300 text-md font-medium">
                      {isVisible}
                    </p>
                  </Row>
                  <Row>
                    <p className="text-Grey-600 font-normal text-sm">
                      {localization(
                        "/pages/app/author-pages/author-list-editor/visibility-description"
                      )}
                    </p>
                  </Row>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              name="isAdmin"
              initialValue={false}
              valuePropName="checked"
            >
              <Row>
                <Col span={2}>
                  <Checkbox defaultChecked={false} />
                </Col>
                <Col span={22}>
                  <Row>
                    <p className="text-Black text-md font-medium">
                      {localization(
                        "/pages/app/author-pages/author-list-editor/add-as-admin"
                      )}
                    </p>
                  </Row>
                  <Row>
                    <p className="text-Grey-600 font-normal text-sm">
                      {localization(
                        "/pages/app/author-pages/author-list-editor/admin-description"
                      )}
                    </p>
                  </Row>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            htmlType="submit"
            className="bg-Primary-500 text-White font-Inter text-base rounded-md"
            size="large"
          >
            {localization("/pages/app/author-pages/author-list-editor/add")}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

const AuthorListEditorPage = () => {
  const [searchKey, setSearchKey] = useState("");
  const {
    data: authorData,
    isLoading: isAuthorDataLoading,
    refetch,
  } = useGetAuthors(0, 999, searchKey);

  const onCreateUserSuccess = () => {
    notification.success({
      message: localization("/hooks/user/user.hooks/add-new-user"),
      description: localization(
        "/hooks/user/user.hooks/user-added-successfully"
      ),
    });
    refetch();
  };

  const { mutate: createUserMutate } = useCreateUser(onCreateUserSuccess);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOnAddAuthorClick = () => {
    setIsModalVisible((prevState) => {
      return !prevState;
    });
  };

  const handleOnCreateUserFormSubmit = (formData) => {
    createUserMutate(formData);
  };

  return (
    <>
      <div className="rounded-md">
        <InformationBox
          setSearchKey={setSearchKey}
          searchBarPlaceholder={pageData.placeholderName}
          title={pageData.pageName}
          text={pageData.informationText}
        >
          <Spin spinning={isAuthorDataLoading}>
            <BadgeSuccess
              leftChildren={authorData?.totalElements}
              rightChildren={pageData.contentName}
              info
            />
          </Spin>
        </InformationBox>
      </div>
      <AddButton
        onClick={handleOnAddAuthorClick}
        text={localization(
          "/pages/app/author-pages/author-list-editor/new-librarian"
        )}
        icon={<AddLibrarianIcon />}
      />
      <div className="mt-4 grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <AuthorListView
            authorData={authorData}
            isAuthorDataLoading={isAuthorDataLoading}
          />
          <AddAuthorModalContent
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            handleSubmit={handleOnCreateUserFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default AuthorListEditorPage;
