import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./app";
import PublicRoutes from "./public";
import getToken from "../util/get-token";
import { generateColorPalatte } from "../util";
import { useGetTenantDetail } from "../hooks/tenant/tenant.hooks";
import setLanguagePreference from "../util/set_language_preference";

const AppLicationRoutes = () => {
  const [primaryColor, setPrimaryColor] = useState("#001F54");
  const [secondaryColor, setSecondaryColor] = useState("#001F54");
  const [userAuthenticationToken, setUserAuthenticationToken] = useState(
    getToken()
  );
  window.addEventListener("storage", () => {
    setUserAuthenticationToken((prevState) => {
      return getToken();
    });
  });
  const { data: tenantData, isLoading } = useGetTenantDetail();

  useEffect(() => {
    if (!isLoading) {
      setPrimaryColor(
        generateColorPalatte(tenantData.primaryColorHexCode, "primary").join(
          " "
        )
      );
      setSecondaryColor(
        generateColorPalatte(
          tenantData.secondaryColorHexCode,
          "secondary"
        ).join(" ")
      );
    }
  }, [isLoading]);
  return (
    <div>
      <div>
        <style>:root {`{${primaryColor} ${secondaryColor}}`}</style>
      </div>
      <Routes>
        {userAuthenticationToken ? (
          <Route path="*" element={ <AppRoutes />} />
        ) : (
          <Route path="*" element={ <PublicRoutes />} />
        )}
      </Routes>
    </div>
  );
};
export default AppLicationRoutes;
