import { Button, Col, Modal, Row } from "antd";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  TwitterOutlined,
  FacebookFilled,
  LinkedinFilled,
  CopyOutlined,
} from "@ant-design/icons";
import { ShareButtonIcon } from "../../../icons/info-box-js";
import { localization } from "../../../localization/localization";
import { WhatsappIcon, TelegramIcon } from "../../../icons/share-icon";

const ShareOnSocialMedia = ({ title, url, isModalOpen, header }) => {
  
  return (
    <div>
      <Modal centered open={isModalOpen} footer={null} width={600}>
        <Row>
          <div className="my-auto mr-4 rounded-full bg-Secondary-100 p-3">
            <ShareButtonIcon />
          </div>
          <Col className="font-Inter">
            <div className="font-medium text-Black">{header}</div>
            <div className="font-normal text-Grey-600">
              {localization(
                "/common/components/buttons/share-buttons/sharing-the-content"
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-6 flex flex-wrap gap-9 text-Grey-700">
          <TwitterShareButton url={url} title={title}>
            <TwitterOutlined className="text-2xl px-1.5 pb-1 pt-0.5 rounded-md border border-Grey-200 hover:shadow-md" />
          </TwitterShareButton>
          <FacebookShareButton url={url} title={title}>
            <FacebookFilled className="text-2xl px-1.5 pb-1 pt-0.5 rounded-md border  border-Grey-200 hover:shadow-md" />
          </FacebookShareButton>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinFilled className="text-2xl px-1.5 pb-1 pt-0.5 rounded-md border  border-Grey-200 hover:shadow-md" />
          </LinkedinShareButton>
          <WhatsappShareButton url={url} title={title}>
            <div className="h-full px-1.5 flex items-center rounded-md border  border-Grey-200 hover:shadow-md">
              <WhatsappIcon />
            </div>
          </WhatsappShareButton>
          <TelegramShareButton url={url} title={title}>
            <div className="h-full px-1.5 flex items-center rounded-md border  border-Grey-200 hover:shadow-md">
              <TelegramIcon />
            </div>
          </TelegramShareButton>
        </Row>
        <Row className="mt-6 font-Inter">
          <Col>
            <Button
              size="large"
              icon={<CopyOutlined className="text-lg" />}
              className="text-Primary-500 flex items-center text-base border border-Grey-200 rounded-md shadow-sm 
              hover:text-White hover:bg-Primary-500 hover:border-Primary-500"
              onClick={() => {
                navigator.clipboard.writeText(url);
              }}
            >
              {localization(
                "/common/components/buttons/share-buttons/copy-the-link"
              )}
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ShareOnSocialMedia;
