import Dropdown, { DropdownButton } from "./navbar-dropdown";
import { EditIcon, LockIcon, LogOutIcon } from "../../../icons/common-icon";
import { useEffect, useState } from "react";

import NavbarButton from "../buttons/navbar-button";
import clsx from "clsx";
import getUserRole from "../../../util/get-user-role";
import { useGetTenantDetail } from "../../../hooks/tenant/tenant.hooks";
import { useGetUserMe } from "../../../hooks/user/user.hooks";
import { useNavigate } from "react-router-dom";
import { useLogOutUserMe } from "../../../hooks/auth/auth-hooks";
import { localization } from "../../../localization/localization";
import { GGuidesFullIcon } from "../../../icons/gguides-icon";
import { useDownloadFile } from "../../../hooks/file/file.hooks";
import LanguageChangeButton from "../buttons/languate-change-button";
import setLanguagePreference from "../../../util/set_language_preference";
import defaultPhoto from "../../../assets/avatar_image.png"

const AppNavbar = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const { mutate: downloadImage, isLoading:isDownloading } = useDownloadFile();

  const buttons = [
    {
      name: localization("/common/components/navbar/AppNavbar/home"),
      linkTo: "/app/categories",
    },
    {
      name: localization("/common/components/navbar/AppNavbar/guides"),
      linkTo: "/app/guides",
    },
    {
      name: localization("/common/components/navbar/AppNavbar/databases"),
      linkTo: "/app/databases",
    },
    {
      name: localization("/common/components/navbar/AppNavbar/authors"),
      linkTo: "/app/authors",
    },
  ];

  const {
    data: authenticatedUserData,
    error: authenticatedUserError,
    isLoading: isAuthenticatedUserDataLoading,
  } = useGetUserMe();

  const handleSetLanguage = () => {
    if (window.localStorage.getItem("languagePreference") === "tr-TR") {
      setLanguagePreference("en-US");
    } else {
      setLanguagePreference("tr-TR");
    }
    window.location.reload();
  };

  useEffect(() => {
    if (authenticatedUserData) {
      downloadImage(authenticatedUserData.photo?.id, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setImage(bufferObject);
        },
      });
    }
  },[authenticatedUserData])

  const { data: tenantInformations, isLoading: isTenantInformaationsLoading } =
    useGetTenantDetail();

  const logout = useLogOutUserMe();
  const [selected, setSelected] = useState(window.location.pathname);
  const [selectedAdminPage, setSelectedAdminPage] = useState();

  const handleOnClick = (linkTo, setPage) => {
    navigate(linkTo);
    setPage(linkTo);
  };

  useEffect(() => {
    if (authenticatedUserError) {
      logout(() => navigate("/"));
    }
  }, [authenticatedUserError]);

  const profileButtons = [
    <DropdownButton
      title={localization("/common/components/navbar/AppNavbar/edit-profile")}
      to="/app/profile-update"
    >
      <EditIcon />
    </DropdownButton>,
    <DropdownButton
      title={localization(
        "/common/components/navbar/AppNavbar/change-password"
      )}
      to="/app/reset-password"
    >
      <LockIcon />
    </DropdownButton>,
    <DropdownButton
      title={localization("/common/components/navbar/AppNavbar/log-out")}
      onClicked={() => logout(() => navigate("/"))}
    >
      <LogOutIcon />
    </DropdownButton>,
  ];

  return (
    <>
      <div className="flex flex-row h-20 py-5 px-12 bg-White justify-between">
        <div className="flex flex-row">
          {!isTenantInformaationsLoading && (
            <div className="rounded-full w-20  mr-20">
              <GGuidesFullIcon />
            </div>
          )}

          {buttons.map((button, index) => {
            return (
              <NavbarButton
                key={index}
                buttonName={button.name}
                selected={button.linkTo === selected}
                onClick={() => handleOnClick(button.linkTo, setSelected)}
              />
            );
          })}
          {getUserRole() === "ADMIN" && (
            <NavbarButton
              buttonName={localization(
                "/common/components/navbar/AppNavbar/management-panel"
              )}
              selected={selected === "MANAGEMENT_PANEL"}
              onClick={() => setSelected("MANAGEMENT_PANEL")}
            />
          )}
        </div>

        <div className="flex gap-x-2">
          <div className="flex items-center">
            <LanguageChangeButton handleSetLanguage={handleSetLanguage} />
          </div>

          <div className="float-right h-10">
            {!isAuthenticatedUserDataLoading && (
              <Dropdown buttons={profileButtons} className="">
                <div className="border-2 border-Primary-500 rounded-full ">
                  <img
                    src={clsx(
                      image || defaultPhoto
                    )}
                    className="rounded-full w-10 h-10"
                    alt="profile_pic"
                  />
                </div>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "flex flex-row bg-White border-t border-Grey-200",
          selected !== "MANAGEMENT_PANEL" && "hidden"
        )}
      >
        <div className="ml-8 my-2">
          <NavbarButton
            buttonName={localization(
              "/common/components/navbar/AppNavbar/institution-settings"
            )}
            selected={selectedAdminPage === "/app/update-tenant-detail"}
            onClick={() =>
              handleOnClick("/app/update-tenant-detail", setSelectedAdminPage)
            }
          />
        </div>
        <div className="my-2">
          <NavbarButton
            buttonName={localization(
              "/common/components/navbar/AppNavbar/filters"
            )}
            selected={selectedAdminPage === "/app/edit-types"}
            onClick={() =>
              handleOnClick("/app/edit-types", setSelectedAdminPage)
            }
          />
        </div>
        <div className="my-2">
          <NavbarButton
            buttonName={localization(
              "/common/components/navbar/AppNavbar/guide-categories"
            )}
            selected={selectedAdminPage === "/app/edit-categories"}
            onClick={() =>
              handleOnClick("/app/edit-categories", setSelectedAdminPage)
            }
          />
        </div>
        <div className="my-2">
          <NavbarButton
            buttonName={localization(
              "/common/components/navbar/AppNavbar/bulletin-management"
            )}
            selected={selectedAdminPage === "/app/bulletin-management"}
            onClick={() =>
              handleOnClick("/app/bulletin-management", setSelectedAdminPage)
            }
          />
        </div>
      </div>
    </>
  );
};

export default AppNavbar;
