import * as api from "../api";

export const getVendors = (page, size) => {
  return api.get(`/vendor?page=${page}&size=${size}`);
};

export const postVendor = (body) => {
  return api.post(`/vendor`, body);
};

export const deleteVendor = (id) => {
  return api.del(`/vendor/${id}`);
};
