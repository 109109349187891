import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteDatabaseType,
  postDatabaseType,
  getDatabaseTypes,
} from "../../api/databaseType/databaseType.api";
import { localization } from "../../localization/localization";

export const useAddDatabaseType = (onSuccess, onError) => {
  return useMutation(
    "post-new-database-type",
    (body) => {
      return postDatabaseType(body);
    },
    { onSuccess }
  );
};

export const useDeleteDatabaseType = (onSuccess) => {
  return useMutation(
    "delete-database-type",
    (id) => {
      return deleteDatabaseType(id);
    },
    {
      onSuccess,
      onError: (error) => {
        notification.error({
          description: localization("/hooks/database-type/database-type.hooks/delete-failed"),
          message: error.message,
        });
      },
    }
  );
};

export const useGetDatabaseTypes = (page, size) => {
  return useQuery("get-database-types", () => {
    return getDatabaseTypes(page, size);
  });
};
