import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteDatabaseSubject,
  getDatabaseSubjects,
  postDatabaseSubject,
} from "../../api/databaseSubject/databaseSubject.api";
import { localization } from "../../localization/localization";

export const useCreateDatabaseSubject = (onSuccess, onError) => {
  return useMutation(
    "create-database-subject",
    (body) => {
      return postDatabaseSubject(body);
    },
    { onSuccess, onError }
  );
};

export const useDeleteDatabaseSubject = (onSuccess) => {
  return useMutation(
    "delete-database-subject",
    (id) => {
      return deleteDatabaseSubject(id);
    },
    {
      onSuccess,
      onError: (error) => {
        notification.error({
          description: localization("/hooks/database-subject/database-subject.hook/delete-failed"),
          message: error.message,
        });
      },
    }
  );
};

export const useGetDatabaseSubjects = (page, size) => {
  return useQuery("get-database-subjetcs", () => {
    return getDatabaseSubjects(page, size);
  });
};
