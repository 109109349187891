import DatabaseInformationBox from "../info-box/database-box";

const DatabaseListView = ({ databases, isLoading, isEdit }) => {
  return (
    <div className="col-span-3">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="col-span-3">
          <div className="flex flex-col gap-y-3">
            {databases.content.map((database, index) => {
              return (
                <DatabaseInformationBox
                  key={`database-${index}`}
                  id={database.id}
                  title={database.name}
                  text={database.description}
                  sourceName={database.sourceName}
                  tagList={database.subjects}
                  types={database.types}
                  databaseUrl={database.url}
                  databaseStatus={database.status}
                  databaseUserManual={database.userManual}
                  databaseDescription={database.description}
                  friendlyUrl={database?.friendlyUrl}
                  databaseVendorName={database.vendor.name}
                  isEdit={isEdit}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatabaseListView;
