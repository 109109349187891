import { CrossIcon, HashtagIcon, PlusSign } from '../../../../icons/common-icon'
import { BadgeInfo } from '../../../components/buttons/badge'
import { localization } from '../../../../localization/localization'
import { Button, Modal } from 'antd'
import { useState } from 'react';

const DeleteModal = ({ isOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={null}
      closable={false}
    >
      <div className="flex flex-col">
        <div className="flex justify-center">
          <div className="font-Inter font-semibold text-base mb-4">
            {localization(
              "common/views/app/edit-types/edit-types/are-u-sure-about-delete"
            )}
          </div>
        </div>
        <div className="flex justify-between gap-2 mt-4 pr-4">
          <Button
            type="submit"
            onClick={handleOk}
            size="middle"
            className="bg-Error-500 text-Primary-50 border-Error-500 hover:border-Error-500 rounded-lg hover:text-Error-500 hover:shadow-sm hover:bg-Primary-50"
          >
            {localization("common/views/app/edit-types/edit-types/confirm")}
          </Button>
          <Button
            onClick={handleCancel}
            size="middle"
            className="text-Primary-50 bg-Primary-500 border-Primary-500 hover:border-Primary-500 hover:text-Primary-500 hover:bg-Primary-50 hover:shadow-sm rounded-lg"
          >
            {localization("common/views/app/edit-types/edit-types/cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const CancelButton = ({ onRemoveClicked, id }) => {
  const [isDeleteModelOpen, setDeleteModalOpen] = useState(false);

  const showModal = () => {
    setDeleteModalOpen(true);
  };

  const handleOk = () => {
    onRemoveClicked(id)
    setDeleteModalOpen(false);
  };

  const handleCancel = () => {
    setDeleteModalOpen(false);
  };

  return (
    <>
      <div onClick={showModal}>
        <CrossIcon />
      </div>
      <DeleteModal
        isOpen={isDeleteModelOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

const EditTypes = ({
	title,
	statement,
	data,
	onRemoveClicked,
	onAddNewClicked,
}) => {
	return (
		<>
			<div className='bg-White p-6 rounded-lg h-full flex flex-col justify-between'>
				<div>
					<div id='heading-section' className='gap-6'>
						<div
							id='heading-section-icon'
							className='flex w-12 h-12 bg-Primary-50 rounded-full'
						>
							<div className=' m-auto'>
								<HashtagIcon />
							</div>
						</div>
						<div id='heading-section-content'>
							<div className='heading-section-content-title mt-2'>
								<p className='font-Inter text-Black text-base'>
									{title}
								</p>
							</div>
							<div id='heding-section-content-statement'>
								<p className='font-Inter text-Grey-600 text-base'>
									{statement}
								</p>
							</div>
						</div>
					</div>

					<div id='content-section'>
						<div className='flex flex-row flex-wrap'>
							{data &&
								data.map((element, index) => {
									return (
										<div className='m-2 ml-0' key={index}>
											<BadgeInfo
												leftChildrenClassName={
													'text-xs'
												}
												leftChildren={element.name}
											>
												<CancelButton
													onRemoveClicked={
														onRemoveClicked
													}
													key={index}
													id={element.id}
												/>
											</BadgeInfo>
										</div>
									)
								})}
						</div>
					</div>
				</div>

				<div
					id='new-type'
					className='border border-Grey-200 text-base rounded-lg inline-block mt-4 cursor-pointer self-start'
				>
					<div
						className='flex flex-row gap-x-3 py-2 px-3 justify-around '
						onClick={onAddNewClicked}
					>
						<div className=' my-auto'>
							<PlusSign className={'stroke-Primary-500'} />
						</div>
						<p className='font-Inter text-Primary-500 text-base'>
							{localization(
								'common/views/app/edit-types/edit-types/add'
							)}
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
export default EditTypes
