export const ShareButtonIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      className="stroke-Grey-700 hover:stroke-Grey-800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.15833 11.2582L11.85 14.5748M11.8417 5.42484L6.15833 8.7415M16.5 4.1665C16.5 5.54722 15.3807 6.6665 14 6.6665C12.6193 6.6665 11.5 5.54722 11.5 4.1665C11.5 2.78579 12.6193 1.6665 14 1.6665C15.3807 1.6665 16.5 2.78579 16.5 4.1665ZM6.5 9.99984C6.5 11.3805 5.38071 12.4998 4 12.4998C2.61929 12.4998 1.5 11.3805 1.5 9.99984C1.5 8.61913 2.61929 7.49984 4 7.49984C5.38071 7.49984 6.5 8.61913 6.5 9.99984ZM16.5 15.8332C16.5 17.2139 15.3807 18.3332 14 18.3332C12.6193 18.3332 11.5 17.2139 11.5 15.8332C11.5 14.4525 12.6193 13.3332 14 13.3332C15.3807 13.3332 16.5 14.4525 16.5 15.8332Z"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const BookmarkIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      className="stroke-Grey-700 hover:stroke-Grey-800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99984 9.83333V4.83333M4.49984 7.33333H9.49984M12.8332 16.5V5.5C12.8332 4.09987 12.8332 3.3998 12.5607 2.86502C12.321 2.39462 11.9386 2.01217 11.4681 1.77248C10.9334 1.5 10.2333 1.5 8.83317 1.5H5.1665C3.76637 1.5 3.06631 1.5 2.53153 1.77248C2.06112 2.01217 1.67867 2.39462 1.43899 2.86502C1.1665 3.3998 1.1665 4.09987 1.1665 5.5V16.5L6.99984 13.1667L12.8332 16.5Z"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
