import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SearchBar from "../../../common/components/searchBar/search-bar";
import AuthorDetailView from "../../../common/views/author/author-detail-view";
import DatabaseListView from "../../../common/views/database/database-list-view";
import {
  AuthorDatabaseFilterView,
  AuthorGuideFilterView,
} from "../../../common/views/filter-view/filter-view";
import GuideListView from "../../../common/views/guide/guide-list-view";
import PageSelector from "../../../common/views/page-selection/page-selector";
import { useGetAuthor } from "../../../hooks/user/user.hooks";
import { useGetFilteredPublicDatabases } from "../../../hooks/database/database.hooks";
import { useGetFilteredPublicGuides } from "../../../hooks/guide/guide.hooks";
import { Col, Row } from "antd";
import { localization } from "../../../localization/localization";
import { useDownloadFile } from "../../../hooks/file/file.hooks";

const AuthorDetailEditorPage = () => {
  const { id } = useParams();
  const {
    data: authorData,
    isLoading: isAuthorDataLoading,
    isError: isAuthorDataError,
  } = useGetAuthor(id);

  const [image, setImage] = useState(null);
  const { mutate: downloadImage } = useDownloadFile();

  useEffect(() => {
    if (authorData) {
      downloadImage(authorData?.photo?.id, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setImage(bufferObject);
        },
        onError: () => {
          setImage(null);
        },
      });
    }
  }, [authorData]);

  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  const sortingType = [
    {
      name: localization("/pages/app/author-pages/author-detail-editor/guides"),
      fields: "guides",
    },
    {
      name: localization(
        "/pages/app/author-pages/author-detail-editor/databates"
      ),
      fields: "databases",
    },
  ];

  const [searchKey, setSearchKey] = useState("");
  const [filterList, setFilterList] = useState({});

  const getSelections = (selectedFilters) => {
    setFilterList(selectedFilters);
  };

  const { data: databases, isLoading: isDatabaseLoading } =
    useGetFilteredPublicDatabases(
      0,
      999,
      searchKey,
      {
        ...filterList,
        authors: [id],
      },
      "",
      ""
    );

  const { data: guidesData, isLoading: isGuideLoading } =
    useGetFilteredPublicGuides(0, 999, searchKey, {
      ...filterList,
      authors: [id],
    });

  const onSearchClicked = (value) => {
    setSearchKey(value);
  };

  return (
    <>
      {isAuthorDataLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="pb-6">
          <div className="bg-White rounded-md p-6">
            <AuthorDetailView image={image} authorData={authorData} />
          </div>
          <div
            id="guide-pages"
            className="py-3 px-6 bg-White mt-6 flex flex-row flex-wrap"
          >
            <div className="my-auto">
              <PageSelector
                buttonInformation={sortingType}
                setSelectedPage={setSelectedPageIndex}
              />
            </div>
          </div>
          <div className="my-6 bg-White px-6 py-3 rounded-md">
            <div className="flex flex-row">
              <div className="block w-full">
                <SearchBar
                  className="my-auto "
                  placeholder={localization(
                    "/pages/app/author-pages/author-detail-editor/search-in-authors"
                  )}
                  onSearchClicked={onSearchClicked}
                />
              </div>
            </div>
          </div>
          {sortingType.at(selectedPageIndex).fields === "guides" ? (
            <>
              <Row gutter={24}>
                <Col span={16}>
                  <GuideListView
                    guidesData={guidesData}
                    isLoading={isGuideLoading}
                  />
                </Col>
                <Col span={8}>
                  <AuthorGuideFilterView getSelections={getSelections} />
                </Col>
              </Row>
            </>
          ) : sortingType.at(selectedPageIndex).fields === "databases" ? (
            <div className="mt-4 grid grid-cols-4 gap-4">
              <DatabaseListView
                databases={databases}
                isLoading={isDatabaseLoading}
              />
              <AuthorDatabaseFilterView getSelections={getSelections} />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
};

export default AuthorDetailEditorPage;
