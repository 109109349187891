import { Navigate, Route, Routes } from 'react-router-dom'

import AuthorDetailPage from './author-pages/author-detail'
import AuthorsPage from './author-pages/authors'
import DatabasesPage from './database-pages/databases'
import EmailSentPage from './auth-pages/email-sent'
import ForgotPasswordPage from './auth-pages/forgot-password'
import GuideCategoriesPage from './category-pages/categories'
import GuideDetailPage from './guide-pages/guide-detail'
import GuidesPage from './guide-pages/guides'
import LoginPage from './auth-pages/login'
import PasswordChangedPage from './auth-pages/password-changed'

const PublicRoutes = () => {
	return (
		<Routes>
			<Route exact path='/guides' element={<GuidesPage />} />
			<Route
				exact
				path='/guide-detail/:guideId'
				element={<GuideDetailPage />}
			/>
			<Route exact path='/databases' element={<DatabasesPage />} />
			<Route exact path='/authors' element={<AuthorsPage />} />
			<Route exact path='/author-detail/:authorId' element={<AuthorDetailPage />} />
			<Route exact path='/login' element={<LoginPage />} />
			<Route exact path='/forgot-password' element={<ForgotPasswordPage />} />
			<Route exact path='/password-changed' element={<PasswordChangedPage />} />
			<Route exact path='/email-sent' element={<EmailSentPage />} />
			<Route exact path='/categories' element={<GuideCategoriesPage />} />
			<Route exact path='/guide-category/:id' element={<GuidesPage />} />
			<Route exact path='/database-category/:id' element={<DatabasesPage />} />
			<Route path='*' element={<Navigate to='/categories' replace />} />
		</Routes>
	)
}

export default PublicRoutes
