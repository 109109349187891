import * as api from '../api'

export const postCategory = (body) => {
	return api.post(`/category`, body)
}

export const deleteCategory = (guideCategoryId) => {
	return api.del(`/category/${guideCategoryId}`)
}

export const updateCategory = (guideCategoryId,body) => {
	return api.put(`/category/${guideCategoryId}`,body)
}

export const getCategory = (guideCategoryId) => {
	return api.get(`/category/${guideCategoryId}`)
}

export const getCategories = () => {
	return api.get(`/category`)
}
