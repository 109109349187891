import { Button } from 'antd'
import React, { useState } from 'react'
import CategoryModal from './category-modal'

const CategoryButton = ({ edit, buttonClass, text, size, child }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleClick = () => {
    setIsModalVisible(true)
  }

  return (
    <span>
      <Button className={buttonClass} size={size} onClick={handleClick}>
        {text}
      </Button>
      <CategoryModal
        edit={edit}
        setIsModalVisible={setIsModalVisible}
        ancestorCategoryId={child?.id}
        categoryName={edit ? child?.name : null}
        initialCategoryPhoto={edit ? child?.photo : null}
        onCancel={handleCancel}
        isModalVisible={isModalVisible}
      />
    </span>
  )
}

export default CategoryButton
