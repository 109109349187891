import { BadgeGuide, BadgeSuccess } from "../../components/buttons/badge";
import ShareOnSocialMedia from "../../components/buttons/share-buttons";
import { ShareButtonIcon } from "../../../icons/info-box-js";
import { DotIcon } from "../../../icons/common-icon";
import getUserRole from "../../../util/get-user-role";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { localization } from "../../../localization/localization";
import { useGetPublicGuide } from "../../../hooks/guide/guide.hooks";
import { useDownloadFile } from "../../../hooks/file/file.hooks";
import IcoProfile from "../../components/photo/ico-profile";
import defaultAvatarIco from "../../../assets/avatar_image.png";

const GuideInformationBox = ({
  title,
  id,
  text,
  isNew,
  tagList,
  authorName,
  photoId,
}) => {
  const { data: guideData } = useGetPublicGuide(id);
  const [isShareModalOpen, setIsShareModalOpen] = useState();
  const onShareClick = () => {
    setIsShareModalOpen((prevState) => {
      return !prevState;
    });
  };

  const [avatarImage, setAvatarImage] = useState(defaultAvatarIco);
  const { mutate: downloadImage, isLoading: isDownloading } = useDownloadFile();

  useEffect(() => {
    if (photoId) {
      downloadImage(photoId, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setAvatarImage(bufferObject);
        },
        onError: () => {
          setAvatarImage(defaultAvatarIco);
        },
      });
    }
  }, []);

  return (
    <div className="bg-White w-full rounded-md py-4 px-6 mb-6">
      <div className="flex flex-row pb-2">
        <div className="basis-5/6">
          <Link
            reloadDocument
            to={
              getUserRole() === "ADMIN"
                ? `/app/update-guide/${guideData?.friendlyUrl || id}`
                : `/guide-detail/${guideData?.friendlyUrl || id}`
            }
          >
            <p className="basis-5/6 text-Black text-lg font-Inter cursor-pointer">
              {title}
            </p>
          </Link>
        </div>
        <div className="w-full flex flex-row-reverse items-center text-Black text-lg font-Inter gap-x-3">
          <span className="p-2 cursor-pointer" onClick={onShareClick}>
            <ShareButtonIcon />
            <ShareOnSocialMedia
              header={localization(
                "/common/views/info-box/guide-box/share-guide"
              )}
              title={title}
              url={
                window._env_.BASE_URL +
                  "/guide-detail/" +
                  guideData?.friendlyUrl || id
              }
              isModalOpen={isShareModalOpen}
            />
          </span>
        </div>
      </div>
      <div className="w-full flex flex-row pb-4">
        <p className="basis-1/2 font-Inter text-Grey-500 text-sm">{text}</p>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-row gap-x-2 basis-5/6">
          {isNew && (
            <div className="my-auto">
              <BadgeSuccess
                leftChildren={<DotIcon />}
                rightChildren={localization(
                  "/common/views/info-box/guide-box/new"
                )}
              />
            </div>
          )}
          {tagList.map((item, index) => {
            return <BadgeGuide key={index} leftChildren={item.name} />;
          })}
        </div>
        <div className="flex flex-row-reverse basis-1/6 gap-4">
          <IcoProfile
            label={authorName}
            image={avatarImage}
            isImageLoading={isDownloading}
          />
        </div>
      </div>
    </div>
  );
};

export default GuideInformationBox;
