import { Button, Col, Row } from "antd";
import { useState } from "react";
import { BadgeDatabase, BadgeInfo } from "../../../common/components/buttons/badge";
import AZList from "../../../common/components/filter/a-to-z-list";
import MainLayout from "../../../common/layouts/main-layout";
import DatabaseListView from "../../../common/views/database/database-list-view";
import { DatabaseFilterView } from "../../../common/views/filter-view/filter-view";
import InformationBox from "../../../common/views/info-box/information-box";
import { useGetFilteredPublicDatabases } from "../../../hooks/database/database.hooks";
import { useGetTenantDetail } from "../../../hooks/tenant/tenant.hooks";
import { ExternalAccessIcon } from "../../../icons/common-icon";
import { useParams } from 'react-router-dom';
import { localization } from "../../../localization/localization";

const DatabasesPage = () => {
  const [filterList, setFilterList] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const getSelections = (selections) => {
    setFilterList(selections);
  };

  const [firstChar, setFirstChar] = useState("");

  const { id: categoryIds } = useParams()

  const { data: databases, isLoading } = useGetFilteredPublicDatabases(
    0,
    40,
    searchKey,
    filterList,
    firstChar,
    categoryIds
  );
  const { data: tenantSettings, isLoading: isTenantDetailLoading } =
    useGetTenantDetail();

  const onReadDescriptionClicked = (address) => {
    window.open(`https://${address}`, "_blank");
  };
  return (
    <MainLayout>
      <div className="grid grid-cols-12 gap-x-4 mb-6">
        <div className="col-span-9 rounded-md ">
          <div className="h-full">
            {!(isTenantDetailLoading || isLoading) && (
              <InformationBox
                searchBarPlaceholder={localization(
                  "/pages/public/database-pages/databases/search-database"
                )}
                setSearchKey={setSearchKey}
                title={localization(
                  "/pages/public/database-pages/databases/database-list"
                )}
                text={tenantSettings.databaseDescription}
                extendedText
              >
                <BadgeInfo
                  leftChildren={databases.totalElements}
                  rightChildren={localization(
                    "/pages/public/database-pages/databases/database"
                  )}
                />
              </InformationBox>
            )}
          </div>
        </div>
        <div className="col-span-3 p-6 bg-White">
          <div className="flex flex-col gap-y-2">
            <div className="m-auto">
              <ExternalAccessIcon />
            </div>

            <div className="m-auto font-Inter text-Grey-600 text-xl">
              {" "}
              {localization(
                "/pages/public/database-pages/databases/access-from-off-campus"
              )}
            </div>
            <Button
              size="large"
              className="text-Grey-700 text-base rounded-md font-Inter font-medium mt-4"
              onClick={() =>
                onReadDescriptionClicked(tenantSettings.databaseExternalLink)
              }
            >
              {localization(
                "/pages/public/database-pages/databases/learn-description"
              )}
            </Button>
          </div>
        </div>
      </div>
      <Row className="mb-2">
        <Col span={18}>
          <AZList setFirstElement={setFirstChar} />
          <div className="d-flex align-items-center mb-3">
            <p className="py-1 font-Inter font-medium text-5xl mt-6 text-Grey-500 border-b border-Grey-200">
              {firstChar}
            </p>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={18}>
          <DatabaseListView databases={databases} isLoading={isLoading} />
        </Col>
        <Col span={6}>
          <DatabaseFilterView getSelections={getSelections} />
        </Col>
      </Row>
    </MainLayout>
  );
};

export default DatabasesPage;
