import { Button, Col, Form, Input, notification, Row, Switch } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import UpdatePhoto from "../../../common/components/photo/update-photo";
import defaultPhoto from "../../../assets/avatar_image.png";
import { useBlob, useUploadFile } from "../../../hooks/file/file.hooks";
import { useGetUserMe, useUpdateUserMe } from "../../../hooks/user/user.hooks";
import { localization } from "../../../localization/localization";

const ProfileUpdatePage = () => {
  const { data: userInformation, isLoading } = useGetUserMe();
  const [userProfilePhoto, setProfilePhotoId] = useState(null);
  
  const [userPhoto, setUserPhoto] = useBlob(
    userInformation?.photo?.id
  );

  const { mutate: uploadFileMutaiton } = useUploadFile();
  const { mutate: updateUserMutation } = useUpdateUserMe();

  useEffect(() => {
    if (userInformation) {
      setProfilePhotoId(userInformation.photo?.id);
    }
  }, [userInformation]);

  const onSubmitForm = (formData) => {
    // If new file selected upload it first.
    const body = {
      name: formData.name,
      surname: formData.surname,
      userRole: "ADMIN",
      emailVisible: formData.emailVisible,
      description: formData.description,
    };

    if (userProfilePhoto && userProfilePhoto !== userInformation.photo?.id) {
      const formData = new FormData();
      formData.append("file", userProfilePhoto);
      uploadFileMutaiton(formData, {
        onSuccess: (data) => {
          body.photoId = data.id;
          updateUserMutation(body, {
            onSuccess: () => {
              notification.success({
                description: localization(
                  "/pages/app/author-pages/profile-update/changes-saved"
                ),
              });
            },
            onError: (response) => console.log(response),
          });
        },
        onError: (response) => console.log(response),
      });
    } else {
      userProfilePhoto
        ? (body.photoId = userInformation?.photo?.id)
        : (body.photoId = null);
      updateUserMutation(body, {
        onSuccess: () => {
          notification.success({
            description: localization(
              "/pages/app/author-pages/profile-update/changes-saved"
            ),
          });
        },
        onError: (response) => console.log(response),
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div>
          <Row>
            <Button
              htmlType="submit"
              form="profile-form"
              className="bg-Primary-500 rounded-lg  mb-8 px-4 text-center"
              size="large"
            >
              <p className="text-White font-Inter text-base">
                {localization(
                  "/pages/app/author-pages/profile-update/save-changes"
                )}
              </p>
            </Button>
          </Row>
          <Row className="bg-White p-6 rounded-md" gutter={[24]}>
            <Col>
              <UpdatePhoto
                setPhotoId={setProfilePhotoId}
                photo={userPhoto}
                setPhoto={setUserPhoto}
                defaultPhoto={defaultPhoto}
              />
            </Col>
            <Col span={22}>
              <ProfileUpdateForm
                userInformation={userInformation}
                onSubmitForm={onSubmitForm}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default ProfileUpdatePage;

const ProfileUpdateForm = ({ userInformation, onSubmitForm }) => {
  const [form] = Form.useForm();

  const [isEmailVisible, setIsEmailVisible] = useState(
    userInformation.emailVisible
  );

  useEffect(() => {
    form.setFieldsValue(userInformation);
  }, []);

  return (
    <Form
      form={form}
      name="profile-form"
      className="font-Inter"
      layout="vertical"
      labelCol={8}
      wrapperCol={16}
      onFinish={onSubmitForm}
    >
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label={
              <p className="text-sm font-semibold">
                {localization("/pages/app/author-pages/profile-update/name")}
              </p>
            }
            labelCol={24}
            wrapperCol={24}
            name="name"
            rules={[
              {
                required: true,
                message: localization(
                  "/pages/app/author-pages/profile-update/name-required"
                ),
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <p className="text-sm font-semibold">
                {localization("/pages/app/author-pages/profile-update/surname")}
              </p>
            }
            labelCol={24}
            wrapperCol={24}
            name="surname"
            rules={[
              {
                required: true,
                message: localization(
                  "/pages/app/author-pages/profile-update/surname-required"
                ),
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={
          <p className="text-sm font-semibold">
            {localization("/pages/app/author-pages/profile-update/description")}
          </p>
        }
        labelCol={24}
        wrapperCol={24}
        name="description"
        rules={[
          {
            required: true,
            message: localization(
              "/pages/app/author-pages/profile-update/description-required"
            ),
            min: 20,
          },
          {
            pattern: new RegExp(/^((?!\s{2}).)*$/),
            message: "No double space can be accepted",
          },
        ]}
      >
        <Input.TextArea autoSize showCount size="large" />
      </Form.Item>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={
              <p className="text-sm font-semibold">
                {localization("/pages/app/author-pages/profile-update/email")}
              </p>
            }
            labelCol={24}
            wrapperCol={24}
            name="email"
            rules={[
              {
                required: true,
                message: localization(
                  "/pages/app/author-pages/profile-update/email-invalid"
                ),
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row justify={"space-between"}>
            <Col>
              <p className="font-Inter text-Black font-medium text-base">
                {isEmailVisible
                  ? localization(
                      "/pages/app/author-pages/profile-update/email-visible"
                    )
                  : localization(
                      "/pages/app/author-pages/profile-update/email-not-visible"
                    )}
              </p>
              <p className="font-Inter text-Grey-600 font-normal text-base">
                {localization(
                  "/pages/app/author-pages/profile-update/email-can-be-sent"
                )}
              </p>
            </Col>
            <Col>
              <Form.Item
                name="emailVisible"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  defaultChecked={isEmailVisible}
                  onChange={() => setIsEmailVisible(!isEmailVisible)}
                  className={clsx(
                    isEmailVisible ? "bg-Success-600" : "bg-Primary-50"
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
