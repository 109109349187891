import { Button, Row } from "antd";
import {localization} from "../../../localization/localization";

const GuideRowCreator = ({ onCreateNewRowButtonClicked }) => {
  return (
    <>
      <Row justify="end" className="mt-8">
        <Button
          onClick={onCreateNewRowButtonClicked}
          size="large"
          className="bg-Primary-500 rounded-lg text-White font-Inter font-semibold"
        >
          {localization("/common/components/guide/guide-row-creator/create-new-chapter")}
        </Button>
      </Row>
    </>
  );
};

export default GuideRowCreator;
