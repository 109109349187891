import * as api from "../api";

export const postDatabaseType = (body) => {
  return api.post(`/database-type`, body);
};

export const deleteDatabaseType = (id) => {
  return api.del(`/database-type/${id}`);
};

export const getDatabaseTypes = (page, size) => {
  return api.get(`/database-type?page=${page}&size=${size}`);
};
