const map = {
  "tr-TR": {
    lang: "turkce",
    // /common/components/buttons/share-buttons.js
    "/common/components/buttons/share-buttons/share-guide": "Rehberi Paylaş",
    "/common/components/buttons/share-buttons/sharing-the-content":
      "İçeriği paylaşarak daha fazla kişinin öğrenmesini katkı sağla.",
    "/common/components/buttons/share-buttons/copy-the-link":
      "Bağlantıyı Kopyala",

    // /common/components/cards/category-card.js
    "/common/components/cards/category-card/guides": "Rehberler",
    "/common/components/cards/category-card/databases": "Veritabanları",

    // /common/components/cards/edit-category-card.js
    "/common/components/cards/edit-category-card/edit-category":
      "Kategoriyi Düzenle",

    // /common/components/carousel/category-carousel.js
    "/common/components/carousel/category-carousel/gguides-presentation": "GGuides ile Gelişmiş Öğrenme Deneyimleri Sunan Kütüphane 4.0",

    // /common/components/filter/filter.js
    "/common/components/filter/filter/filters": "Filtreler",
    "/common/components/filter/filter/clear-filters": "Filtreleri Temizle",

    // /common/components/guide/guide-form.js
    "/common/components/guide/guide-form/deletion-successful":
      "Silme işlemi başarılı.",
    "/common/components/guide/guide-form/deletion-success":
      "Seçtiğiniz rehber başarı ile silindi.",
    "/common/components/guide/guide-form/deletion-failed":
      "Silme işlemi başarısız.",
    "/common/components/guide/guide-form/define-name":
      "Rehberin ismini belirle.",
    "/common/components/guide/guide-form/open": "Açık",
    "/common/components/guide/guide-form/close": "Kapalı",
    "/common/components/guide/guide-form/access-granted":
      "Rehbere kullanıcılar ulaşılabilir.",
    "/common/components/guide/guide-form/define-name-prompt":
      "Rehbere isim belirle",
    "/common/components/guide/guide-form/select-topics":
      "Rehber konularını seç",
    "/common/components/guide/guide-form/select-categories": "Kategorileri seç",
    "/common/components/guide/guide-form/select-types": "Rehber türlerini seç",
    "/common/components/guide/guide-form/select-page-to-rename":
      "Sayfa ismini değiştirmek için önce bir sayfa seçiniz.",
    "/common/components/guide/guide-form/editing-not-possible":
      "Düzenleme işlemi yapılamaz.",
    "/common/components/guide/guide-form/update-page": "Sayfayı güncelle",
    "/common/components/guide/guide-form/page-name-label": "Sayfa İsmi",
    "/common/components/guide/guide-form/enter-new-page-name":
      "Yeni sayfa ismini giriniz.",
    "/common/components/guide/guide-form/save": "Kaydet",
    "/common/components/guide/guide-form/title": "Başlık",
    "/common/components/guide/guide-form/content": "İçerik",
    "/common/components/guide/guide-form/new-guide": "Yeni rehber",
    "/common/components/guide/guide-form/manage-guides":
      "Rehberlerini tek panelden kolayca oluştur ve yönet.",
    "/common/components/guide/guide-form/delete-button": "Sil",
    "/common/components/guide/guide-form/save-button": "Kaydet",
    "/common/components/guide/guide-form/define-friendly-url":
      "Kullanıcı dostu URL",
    "/common/components/guide/guide-form/visibility": "Görünürlük",
    "/common/components/guide/guide-form/define-types":
      "Rehberindeki türleri belirle.",
    "/common/components/guide/guide-form/define-categories":
      "Rehberindeki kategorileri belirle.",
    "/common/components/guide/guide-form/define-subjects":
      "Rehberindeki konuları belirle.",
    "/common/components/guide/guide-form/define-friendly-url-prompt":
      "Kullanıcı dostu bir URL oluştur ve paylaş",
    "/common/components/guide/guide-form/define-description":
      "Rehber için bir açıklama ekle",
    "/common/components/guide/guide-form/write-description":
      "Rehberin hakkında ilgi çekici bir açıklama yaz",
    "/common/components/guide/guide-form/are-u-sure-guide":
      "Bu rehberi silmek istediğinizden emin misiniz ?",
    "/common/components/guide/guide-form/are-u-sure-content":
      "Bu içeriği silmek istediğinizden emin misiniz ?",
    "/common/components/guide/guide-form/no": "Hayır",
    "/common/components/guide/guide-form/yes": "Evet",
    "/common/components/guide/guide-form/delete-the-page": "Sayfayı Sil",
    "/common/components/guide/guide-form/what-is-friendly-url":
      "Kullanıcı dostu URL oluştururken mutlaka yarattığınız guides hakkında anahtar kelimeleri barındırmalı. Örnek olarak kimya alanında elementleri tanıtan bir guide yarattıysanız 'iyte.gguides.com/chemistiry-table-of-elements' gibi bir url oluşturmalısınız. Not: Başındaki iyte.gguides.com/'u yazmanıza gerek yok biz sizin yerinize onu oluşturuyoruz. Tek yapmanız gereken yarattığınız guide için eşsiz bir anahtar kelime bütünü bulmanız.",

    // /common/components/guide/guide-row-creator.js
    "/common/components/guide/guide-row-creator/1-column": "1 sütun",
    "/common/components/guide/guide-row-creator/card-size": "kart boyutu",
    "/common/components/guide/guide-row-creator/create-new-row":
      "Yeni satır oluştur",
    "/common/components/guide/guide-row-creator/define-grid-system":
      "Izgara sistemini belirle ve içeriğini oluştur.",
    "/common/components/guide/guide-row-creator/create-new-chapter":
      "Yeni bölüm oluştur",

    // /common/components/category/category-delete-modal.js
    "/common/components/category/category-delete-modal/are-you-sure":
      "Kategoriyi silmekte emin misiniz?",
    "/common/components/category/category-delete-modal/submit": "Kesinleştir",
    "/common/components/category/category-delete-modal/cancel": "Vazgeç",

    // /common/components/category/category-add-edit-modal.js
    "/common/components/category/category-add-edit-modal/update-successfull":
      "Değişiklik başarılı!",
    "/common/components/category/category-add-edit-modal/create-a-new-category":
      "Yeni Kategori Oluştur",
    "/common/components/category/category-add-edit-modal/update-category":
      "Kategoriyi Düzenle",
    "/common/components/category/category-add-edit-modal/add-a-type-for-categorization.":
      "Kategorilendirme için yeni tür ekle.",
    "/common/components/category/category-add-edit-modal/update-for-categorization.":
      "Kategorilendirme için yeniden değiştir.",

    // /common/components/modal/basic-modal.js
    "/common/components/modal/basic-modal/save": "Kaydet",

    // /common/components/navbar/AppNavbar.js
    "/common/components/navbar/AppNavbar/home": "Anasayfa",
    "/common/components/navbar/AppNavbar/guides": "Rehberler",
    "/common/components/navbar/AppNavbar/databases": "Veri Tabanları",
    "/common/components/navbar/AppNavbar/authors": "Kütüphaneciler",
    "/common/components/navbar/AppNavbar/edit-profile": "Profili düzenle",
    "/common/components/navbar/AppNavbar/change-password": "Şifremi değiştir",
    "/common/components/navbar/AppNavbar/log-out": "Çıkış Yap",
    "/common/components/navbar/AppNavbar/management-panel": "Yönetim Paneli",
    "/common/components/navbar/AppNavbar/institution-settings":
      "Kurum Ayarları",
    "/common/components/navbar/AppNavbar/filters": "Filtreler",
    "/common/components/navbar/AppNavbar/guide-categories":
      "Rehber Kategorileri",
    "/common/components/navbar/AppNavbar/bulletin-management":
      "Bülten Yönetimi",

    // /common/components/navbar/navbar.js
    "/common/components/navbar/navbar/login": "Giriş Yap",

    "/common/components/navbar/navbar/home": "Anasayfa",
    "/common/components/navbar/navbar/guides": "Rehberler",
    "/common/components/navbar/navbar/databases": "Veri Tabanları",
    "/common/components/navbar/navbar/authors": "Kütüphaneciler",

    // /common/components/searchBar/search-bar.js
    "/common/components/searchBar/search-bar/search-guide":
      "Aramak istediğin rehberi yaz.",
    "/common/components/searchBar/search-bar/search": "Ara",

    // /common/views/app/edit-types/edit-types.js
    "common/views/app/edit-types/edit-types/add": "Ekle",
    "common/views/app/edit-types/edit-types/confirm": "Onayla",
    "common/views/app/edit-types/edit-types/cancel": "Vazgeç",
    "common/views/app/edit-types/edit-types/are-u-sure-about-delete":
      "Silmek istediğinizden emin misiniz ?",

    // /common/views/app/forms/database/database-form.js
    "/common/views/app/forms/database/database-form/delete-database":
      "Veri tabanını silmek istediğinizden emin misiniz?",
    "/common/views/app/forms/database/database-form/deletion-warn":
      "Eğer veri tabanını silerseniz, kullanıcıların veri tabanına tekrar ulaşması münkün değildir. İsterseniz sadece görünürlüğünü kapatabilirsiniz.",
    "/common/views/app/forms/database/database-form/cancel": "Vazgeç",
    "/common/views/app/forms/database/database-form/confirm": "Evet, eminim.",
    "/common/views/app/forms/database/database-form/save": "Kaydet",
    "/common/views/app/forms/database/database-form/delete": "Sil",
    "/common/views/app/forms/database/database-form/open": "Açık",
    "/common/views/app/forms/database/database-form/close": "Kapalı",
    "/common/views/app/forms/database/database-form/visibility": "Görünürlük",
    "/common/views/app/forms/database/database-form/accessible":
      "Veri Tabanına ulaşılabilinir.",
    "/common/views/app/forms/database/database-form/database-name":
      "Veri tabanının Ismi",
    "/common/views/app/forms/database/database-form/title":
      "Rehberine bir başlık belirle.",
    "/common/views/app/forms/database/database-form/description":
      "Veri tabanını Açıklaması",
    "/common/views/app/forms/database/database-form/description-error":
      "Veri tabanı açıklaması en az 20 karakterden oluşmalıdır.",
    "/common/views/app/forms/database/database-form/guide-description":
      "Rehberine bir açıklama belirle.",
    "/common/views/app/forms/database/database-form/add-connection":
      "Database bağlantısını ekle.(URL)",
    "/common/views/app/forms/database/database-form/select-database-document":
      "Veritabanı dökümanını seçiniz",
    "/common/views/app/forms/database/database-form/database-url":
      "Veritabanı için bir URL ekle",
    "/common/views/app/forms/database/database-form/database-status":
      "Veri tabanının durumu",
    "/common/views/app/forms/database/database-form/database-vendor":
      "Bir Veri Tabanı sağlayıcısı Seçin",
    "/common/views/app/forms/database/database-form/database-subjects":
      "Veri Tabanının Konularını Seçin",
    "/common/views/app/forms/database/database-form/database-types":
      "Veri Tabanın Türlerini Seçin",
    "/common/views/app/forms/database/database-form/database-categories":
      "Veri Tabanın Kategorilerini Seçin",
    "/common/views/app/forms/database/database-form/delete": "Kaldır",
    /*Validations*/
    "/common/views/app/forms/database/database-form/enter-database":
      "Veri tabanının ismini girmeniz gerekmektedir",
    "/common/views/app/forms/database/database-form/double-space-error":
      "Kelimeler arası boşluk sayısı 2 veya daha fazla olamaz",
    "/common/views/app/forms/database/database-form/select-vendor":
      "Veri tabanı için bir sağlayıcı seçmeniz gerekmektedir",
    "/common/views/app/forms/database/database-form/choose-category":
      "Veri tabanı için en az bir adet kategori seçmelisiniz",
    "/common/views/app/forms/database/database-form/choose-subject":
      "Veri tabanı için en az bir konu kategori seçmelisiniz",
    "/common/views/app/forms/database/database-form/choose-type":
      "Veri tabanı için en az bir tür kategori seçmelisiniz",
    "/common/views/app/forms/database/database-form/choose-status":
      "Veri tabanının durumunu seçmelisiniz",
    "/common/views/app/forms/database/database-form/database-url-error":
      "Veri tabanının URL'ini girmeniz gerekmektedir",

    // /common/views/author/author-card.js
    "/common/views/author/author-card/send-mail": "Mail Gönder",
    "/common/views/author/author-card/profile": "Profili Görüntüle",

    // /common/views/author/author-detail-view.js
    "/common/views/author/author-detail-view/give-admin-permissions":
      "Kullanıcıya admin yetkileri verildi.",
    "/common/views/author/author-detail-view/failed-permission-grant":
      "Kullanıcıya yetki verme işlemi başarısız oldu.",
    "/common/views/author/author-detail-view/successful-delete":
      "Kullanıcı silme işlemi başarılı.",
    "/common/views/author/author-detail-view/failed-delete":
      "Kullanıcı silme işlemi başarısız.",
    "/common/views/author/author-detail-view/give-admin-permissions-button":
      "Kullanıcıya admin yetkisi ver.",
    "/common/views/author/author-detail-view/delete-user": "Kullanıcıyı sil.",
    "/common/views/author/author-detail-view/send-email-button": "Mail Gönder",
    "/common/views/author/author-detail-view/librarian-label": "Kütüphaneci",

    //  /common/views/filter-view/filter-view.js
    "/common/views/filter-view/filter-view/types": "Türler",
    "/common/views/filter-view/filter-view/topics": "Konular",
    "/common/views/filter-view/filter-view/authors": "Kütüphaneciler",
    "/common/views/filter-view/filter-view/visibility": "Görünürlük",
    "/common/views/filter-view/filter-view/visible-databases":
      "Görünür veritabanları",
    "/common/views/filter-view/filter-view/visible-guides": "Görünür Rehberler",

    // /common/views/info-box/database-box.js
    "/common/views/info-box/database-box/no-description":
      "Açıklama bulunamadı.",
    "/common/views/info-box/database-box/new": "Yeni",
    "/common/views/info-box/database-box/share-database":
      "Veri Tabanını Paylaş",

    // /common/views/info-box/guide-box.js
    "/common/views/info-box/guide-box/new": "Yeni",
    "/common/views/info-box/guide-box/share-guide": "Rehberi Paylaş",

    //  /common/views/info-box/information-box.js
    "/common/views/info-box/information-box/search": "ARA",

    // /pages/app/admin-pages/tenant-settings.js
    "/pages/app/admin-pages/tenant-settings/change-saved":
      "Yaptığınız değişiklikler sisteme kaydedildi.",
    "/pages/app/admin-pages/tenant-settings/save": "Kaydet",
    "/pages/app/admin-pages/tenant-settings/institution-visual-identity":
      "Kurum Görsel Kimliği",
    "/pages/app/admin-pages/tenant-settings/institution-identity-arrangement":
      "Kurumunun görsel kimliğine göre GGuide’ı düzenle. Sana özel.",
    "/pages/app/admin-pages/tenant-settings/change-institution-logo":
      "Kurum logosunu değiştirmek için görselini sürükle bırak ya da dosyalarından seç.",
    "/pages/app/admin-pages/tenant-settings/select-file": " Dosya Seçin",
    "/pages/app/admin-pages/tenant-settings/file-format":
      "(PNG, SVG, JPEG Formatlarında 1:1 ölçüsünde(örn:800x800px) dosya formatı)",
    "/pages/app/admin-pages/tenant-settings/hex-code":
      "Kurum Renk Kodu (Hex Kodu)",
    "/pages/app/admin-pages/tenant-settings/enter-valid-hex-code":
      "Lütfen geçerli bir hex code girin!",
    "/pages/app/admin-pages/tenant-settings/secondary-hex-code":
      "İkincil kurum Renk Kodu (Hex Kodu)",
    "/pages/app/admin-pages/tenant-settings/institution-name": "Kurum Adı",
    "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank":
      "Bu alan boş bırakılamaz!",
    "/pages/app/admin-pages/tenant-settings/enter-valid-url":
      "Lütfen bir URL giriniz",
    "/pages/app/admin-pages/tenant-settings/at-least-20-char":
      "Bu alana en az 20 karakter giriniz.",
    "/pages/app/admin-pages/tenant-settings/must-max-7-char":
      "Bu alan en fazla 7 karakter ile doldurulmalıdır.",
    "/pages/app/admin-pages/tenant-settings/institution-abbreviation":
      "Kurum Kısaltması",
    "/pages/app/admin-pages/tenant-settings/institution-descriptions":
      "Kurum Açıklamaları",
    "/pages/app/admin-pages/tenant-settings/guide-your-users":
      "Kullanıcılarına yol göster, kurumunu açıkla ve yardım bağlantısı ekle.",
    "/pages/app/admin-pages/tenant-settings/guides-description":
      "Rehberler Açıklama Yazısı",
    "/pages/app/admin-pages/tenant-settings/add-a-description-guide-page":
      "Rehberler sayfasına bir açıklama yazısı ekle.",
    "/pages/app/admin-pages/tenant-settings/databases-description":
      "Veri Tabanları Açıklama Yazısı",
    "/pages/app/admin-pages/tenant-settings/add-a-description-databases-page":
      "Veritabanları sayfasına bir açıklama yazısı ekle.",
    "/pages/app/admin-pages/tenant-settings/databases-off-campus-access-help-link":
      "Veri Tabanları Kampüs Dışı Erişim Yardım Linki",
    "/pages/app/admin-pages/tenant-settings/max-7-char": "(maksimum 7 harf)",

    // /pages/app/auth-pages/reset-password.js
    "/pages/app/auth-pages/reset-password/set-your-new-password":
      "Yeni şifreni belirle",
    "/pages/app/auth-pages/reset-password/new-password-diffrent-you-used-before":
      "Yeni şifreniz daha önce kullandığınız şifrelerden farklı olmalıdır.",
    "/pages/app/auth-pages/reset-password/+9-characters-letters-and-numbers":
      "+9 karakter, harfler ve rakamlar",
    "/pages/app/auth-pages/reset-password/back-to-login-sceen":
      "Giriş Ekranına Geri Dön",
    "/pages/app/auth-pages/reset-password/reset-password": "Şifremi Sıfırla",
    "/pages/app/auth-pages/reset-password/password": "Şifre",
    "/pages/app/auth-pages/reset-password/password-again": "Şifre Tekrar",

    // /pages/app/author-pages/author-detail-editor.js
    "/pages/app/author-pages/author-detail-editor/guides": "Rehberler",
    "/pages/app/author-pages/author-detail-editor/databates": "Veritabanları",
    "/pages/app/author-pages/author-detail-editor/search-in-authors":
      "Yazarlar içinde ara !",

    // /pages/app/author-pages/author-list-editor.js
    "/pages/app/author-pages/author-list-editor/discover-authors":
      "Rehberleri oluşturan yazarları keşfet, yeni rehbehleri ve veri tabanlarını kaçırma!",
    "/pages/app/author-pages/author-list-editor/author": "Kütüphaneci",
    "/pages/app/author-pages/author-list-editor/add-new-user":
      "Yeni Kullanıcı Ekle",
    "/pages/app/author-pages/author-list-editor/enter-info":
      "Kullanıcının bilgilerini yaz, ona bir mail gönderelim.",
    "/pages/app/author-pages/author-list-editor/name": "Kullanıcının Adı",
    "/pages/app/author-pages/author-list-editor/enter-name":
      "Kullanıcının Adını Gir",
    "/pages/app/author-pages/author-list-editor/surname": "Kullanıcının Soyadı",
    "/pages/app/author-pages/author-list-editor/enter-surname":
      "Kullanıcının Soyadını Gir",
    "/pages/app/author-pages/author-list-editor/email": "E-posta",
    "/pages/app/author-pages/author-list-editor/enter-email":
      "Kullanıcının E-posta Adresini gir.",
    "/pages/app/author-pages/author-list-editor/add-as-admin":
      "Yönetici olarak ekle",
    "/pages/app/author-pages/author-list-editor/email-visibility":
      "Mail Görünürlüğünü Düzenle",
    "/pages/app/author-pages/author-list-editor/admin-description":
      "Yeni kullanıcılar ekleyebilir, düzenleyebilir. Kurum ayarlarını düzenleyebilir.",
    "/pages/app/author-pages/author-list-editor/visibility-description":
      "İlgilenenler için e-posta görünürlüğünü değiştirebilirsiniz.",
    "/pages/app/author-pages/author-list-editor/add": "Yeni Kullanıcı Ekle",
    "/pages/app/author-pages/author-list-editor/new-librarian":
      "Yeni Kütüphaneci",
    "/pages/app/author-pages/author-list-editor/librarian-description":
      "Kullanıcı açıklaması",
    "/pages/app/author-pages/author-list-editor/add-description":
      "Ekleyeceğiniz kullanıcıyı bizim için lütfen açıklayın.",
    "/pages/app/author-pages/author-list-editor/min-20-max-1500":
      "Açıklama alanı 20 ile 1500 karakter arası olmalıdır.",
    "/pages/app/author-pages/author-list-editor/librarian": "Kütüphaneci",
    "/pages/app/author-pages/author-list-editor/librarians": "Kütüphaneciler",
    "/pages/app/author-pages/author-list-editor/search-author":
      "Aramak istediğin kütüphaneciyi yaz.",

    // /pages/app/author-pages/profile-update.js
    "/pages/app/author-pages/profile-update/changes-saved":
      "Yaptığınız değişiklikler sisteme kaydedildi.",
    "/pages/app/author-pages/profile-update/save-changes":
      "Değişiklikleri Kaydet",
    "/pages/app/author-pages/profile-update/name": "İsim",
    "/pages/app/author-pages/profile-update/name-required":
      "İsim alanı boş geçilemez.",
    "/pages/app/author-pages/profile-update/surname": "Soyisim",
    "/pages/app/author-pages/profile-update/surname-required":
      "Soyisim alanı boş geçilemez.",
    "/pages/app/author-pages/profile-update/description": "Açıklama Alanı",
    "/pages/app/author-pages/profile-update/description-required":
      "Açıklama alanı en az 20 karakterden oluşmalıdır.",
    "/pages/app/author-pages/profile-update/email": "E - posta",
    "/pages/app/author-pages/profile-update/email-invalid":
      "Lütfen geçerli bir email adresi giriniz.",
    "/pages/app/author-pages/profile-update/email-visible":
      "E-Posta adresi görünür.",
    "/pages/app/author-pages/profile-update/email-not-visible":
      "E-Posta adresi görünmez",
    "/pages/app/author-pages/profile-update/email-can-be-sent":
      " Kullanıcılar e-posta gönderebilir.",

    // /pages/app/category-pages/category-list-editor.js
    "/pages/app/category-pages/category-list-editor/library-guides-and-databases":
      "Kütüphane Rehberleri ve Veritabanları Kategorileri",

    // /pages/app/category-pages/edit-categories.js
    "/pages/app/category-pages/edit-categories/category-deleted":
      "'Kategori Silindi",
    "/pages/app/category-pages/edit-categories/success": "İşlem Başarılı",
    "/pages/app/category-pages/edit-categories/yes": "Evet",
    "/pages/app/category-pages/edit-categories/no": "Hayır",
    "/pages/app/category-pages/edit-categories/confirm-delete":
      "Silmek istediğinizden emin misiniz?",
    "/pages/app/category-pages/edit-categories/category-added":
      "Kategori eklendi",
    "/pages/app/category-pages/edit-categories/add-subcategory":
      "Alt Kategori Ekle",
    "/pages/app/category-pages/edit-categories/category-name": "Kategori Adı",
    "/pages/app/category-pages/edit-categories/add": "ekle",
    "/pages/app/category-pages/edit-categories/edit-categories":
      "Kategorileri Düzenle",
    "/pages/app/category-pages/edit-categories/drag-drop-info":
      "Kategori fotoğrafını eklemek için görselini sürükle bırak ya da dosyalarından seç.",
    "/pages/app/category-pages/edit-categories/category-photo-format":
      "(PNG, SVG, JPEG Formatlarında 3:4 ölçüsünde (örn:212x282px) dosya formatı)",
    "/pages/app/category-pages/edit-categories/choose-a-file": "Dosya Seçin",
    "/pages/app/category-pages/edit-categories/add-a-new-category":
      "Yeni Kategori Ekle",
    "/pages/app/category-pages/edit-categories/create-a-special-category-tree":
      "Özel Kategori Ağaçları Oluşturun",
    "/pages/app/category-pages/edit-categories/organize-guides-databases":
      "Rehberlerinizi ve veritabanlarınızı büyük kategorilere ve alt kategorilere düzenleyin. Yeni kategori ekleyin ve alt kategoriler oluşturun.",

    // /pages/app/category-pages/edit-category.js
    "/pages/app/category-pages/edit-category/success": "İşlem Başarılı",
    "/pages/app/category-pages/edit-category/update-success":
      "Kategori Düzenlenmesi Başarılı!",
    "/pages/app/category-pages/edit-category/save-the-changes":
      "Değişiklikleri Kaydet",
    "/pages/app/category-pages/edit-category/category-photo":
      "Kategori Fotoğrafı",
    "/pages/app/category-pages/edit-category/organize-all-categories":
      "Tüm kategorilerinizi düzenleyin, alt kategoriler ekleyin veya silin.",
    "/pages/app/category-pages/edit-category/suggestions": "Öneriler",
    "/pages/app/category-pages/edit-category/sufficient-photo":
      "Kategorilere uygun fotoğraflar yükleyerek, diğer kullanıcıların da kolayca faydalanabilecekleri, anlaşılır ve estetik görünen bir ortam oluşturabilirsiniz.",
    "/pages/app/category-pages/edit-category/high-res-photo":
      "Fotoğrafın kalitesi yüksek ve net olmalı, açık ve parlak olması önemlidir.",
    "/pages/app/category-pages/edit-category/real-photo":
      "Fotoğrafın renkleri gerçekçi ve kaliteli olmalı, soluk veya çok parlak renkler uygun değildir.",
    "/pages/app/category-pages/edit-category/copyright-photo":
      "Yüklenen fotoğrafın lisansının ücretsiz ve kullanılabilir olması önemlidir, telif hakkı ihlali yapılmamalıdır. (Unsplash, pexels gibi ücretsiz stok fotoğraf sitelerinden faydalanabilrsin.)",
    "/pages/app/category-pages/edit-category/category-management":
      "Kategori Yönetimi",
    "/pages/app/category-pages/edit-category/empty-category": "Boş Kategori",
    "/pages/app/category-pages/edit-category/new-category":
      "Yeni Bir Kategori Ekle",
    "/pages/app/category-pages/edit-category/delete-the-category":
      "Kategoriyi Kaldır",

    // /pages/app/database-pages/add-database-page.js
    "/pages/app/database-pages/add-database-page/add-database":
      "Yeni Veritabanı Ekle",
    "/pages/app/database-pages/add-database-page/enter-database-info":
      "Eklemek istediğin veri tabanının bilgilerini gir.",
    "/pages/app/database-pages/add-database-page/save-the-changes":
      "Değişiklikleri Kaydet",

    // /pages/app/database-pages/database-list-editor.js
    "/pages/app/database-pages/database-list-editor/search-database":
      "Aramak istediğin veritabanını yaz.",
    "/pages/app/database-pages/database-list-editor/databases":
      "Veri Tabanları",
    "/pages/app/database-pages/database-list-editor/database": "Veri tabanı",
    "/pages/app/database-pages/database-list-editor/off-campus-access":
      "Kampüs dışından erişim nasıl sağlarım?",
    "/pages/app/database-pages/database-list-editor/import-database":
      "Veri Tabanı Aktar",
    "/pages/app/database-pages/database-list-editor/learn-more":
      "Açıklamayı Öğren",
    "/pages/app/database-pages/database-list-editor/add-database":
      "Yeni veri tabanı",

    // /pages/app/database-pages/update-database-page.js
    "/pages/app/database-pages/update-database-page/edit-databates":
      "Veritabanını Düzenle",
    "/pages/app/database-pages/update-database-page/databates-information":
      "Düzenlemek istediğin veri tabanının bilgilerini gir.",

    // /pages/app/bulletin-pages/bulletin-management.js
    "/pages/app/bulletin-pages/bulletin-management/bulletins": "Bültenler",
    "/pages/app/bulletin-pages/bulletin-management/users": "Kullanıcılar",

    // /pages/app/bulletin-pages/bulletin-management/bulletins
    "/pages/app/bulletin-pages/bulletin-management/bulletin-groups":
      "Bülten Grupları",
    "/pages/app/bulletin-pages/bulletin-management/new-bulletin-group":
      "Yeni Bülten Grubu",
    "/pages/app/bulletin-pages/bulletin-management/announcement-bulletins":
      "Duyuru Bültenleri",
    "/pages/app/bulletin-pages/bulletin-management/new-announcement-bulletin":
      "Yeni Duyuru",
    "/pages/app/bulletin-pages/bulletin-management/bulletin-detail":
      "Bülten Detayına Git",

    // /pages/app/bulletin-pages/bulletin-management/new-bulletin-group-modal

    "/pages/app/bulletin-pages/bulletin-management/create-a-new-bulletin-group":
      "Yeni Bülten Grubu Oluştur",
    "/pages/app/bulletin-pages/bulletin-management/add-a-type":
      "Kategorilendirme için yeni tür ekle.",
    "/pages/app/bulletin-pages/bulletin-management/choose-a-category":
      "Kategori veya Alt Kategori Seç",
    "/pages/app/bulletin-pages/bulletin-management/example":
      "Örn: Bibliyografi",
    "/pages/app/bulletin-pages/bulletin-management/add": "Ekle",

    // /pages/app/bulletin-pages/management-pages/detail-pages/users
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/previous":
      "Önceki",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/next":
      "Sonraki",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/loading":
      "Yükleniyor",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin":
      "Bültene bir kullanıcı ekleyin",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin-description":
      " Rehberlerinizi ve veritabanlarınızı büyük kategorilere vealt kategorilere düzenleyin. Yeni kategori ekleyin ve alt kategoriler oluşturun.",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user":
      "Kullanıcı Ekle",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/user":
      "Kullanıcı",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/email-address":
      "Eposta Adresi",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/date-that-added-to-system":
      "Sisteme Eklenme Tarihi",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/cancel":
      "Vazgeç",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/submit":
      "Onayla",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/are-you-sure":
      "Emin misiniz ?",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-mail-user-success":
      "Posta kullanıcısı eklendi!",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-mail-user-failure":
      "Posta kullanıcısı eklenemedi!",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-new-user":
      "Yeni bir kullanıcı ekle",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-current-user":
      "Var olan bir kullanıcıyı ekle",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/name":
      "İsim",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/surname":
      "Soyad",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/email":
      "Email",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add": "Ekle",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/import-via-file":
      "Dosya ile içe aktar",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-from-system":
      "Sistem içerisinden ekle",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-with-email":
      "E-posta ile ekle",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-for-email":
      "Yeni e-postalar için yeni kullanıcı ekleyin.",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/mail-user-deleted":
      "Mail kullanıcısı silindi.",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/cant-delete-mail-user":
      "Mail kullanıcısı silinemedi.",

    // /pages/public/auth-pages/email-sent.js
    "/pages/public/auth-pages/email-sent/check-email-address":
      "E-posta adresini kontrol et!",
    "/pages/public/auth-pages/email-sent/check-email-for-link":
      "adresine şifreni değiştirmen için bir link gönderdik, lütfen kontrol et. Spam kutusuna düşmüş olabilir.",
    "/pages/public/auth-pages/email-sent/return-to-login":
      "Giriş Ekranına Geri Dön",

    // /pages/public/auth-pages/forgot-password.js
    "/pages/public/auth-pages/forgot-password/did-you-forget-password":
      "Şifreni mi unuttun?",
    "/pages/public/auth-pages/forgot-password/dont-worry-link":
      "Hiç merak etmeyin, size şifrenizi sıfırlamanız için bir link göndereceğiz.",
    "/pages/public/auth-pages/forgot-password/email": "E-posta",
    "/pages/public/auth-pages/forgot-password/invalid-email":
      "E-posta geçerli değil",
    "/pages/public/auth-pages/forgot-password/reset-password":
      "Şifremi Sıfırla",
    "/pages/public/auth-pages/forgot-password/return-to-login":
      "Giriş Ekranına Geri Dön",

    // /pages/public/auth-pages/login.js
    "/pages/public/auth-pages/login/login": "Giriş Yap",
    "/pages/public/auth-pages/login/readers-waiting":
      "Okurların seni bekliyor !",
    "/pages/public/auth-pages/login/email": "E-posta",
    "/pages/public/auth-pages/login/invalid-email": "E-posta geçerli değil",
    "/pages/public/auth-pages/login/password": "Şifre",
    "/pages/public/auth-pages/login/password-length":
      "Şifre en az 6 karakterden oluşmalı",
    "/pages/public/auth-pages/login/password-rules":
      "+9 karakter, harfler ve rakamlar",
    "/pages/public/auth-pages/login/example-email": "example@.com",
    "/pages/public/auth-pages/login/forgot-password": "Şifremi Unuttum",
    "/pages/public/auth-pages/login/login-button": "Giriş Yap",
    "/pages/public/auth-pages/login/easy-content-creation":
      "İçerik yaratmak ve yönetmek GGuide ile çok kolay",

    // /pages/public/auth-pages/password-changed.js
    "/pages/public/auth-pages/password-changed/password-changed":
      "Şifren başarıyla değiştirildi.",
    "/pages/public/auth-pages/password-changed/login-with-new-password":
      "Yeni şifrenle giriş yapmak için giriş ekranına dön.",
    "/pages/public/auth-pages/password-changed/return-to-login":
      "Giriş ekranına dön",

    // /pages/public/author-pages/author-detail.js
    "/pages/public/author-pages/author-detail/guides": "Rehberler",
    "/pages/public/author-pages/author-detail/databases": "Veritabanları",
    "/pages/public/author-pages/author-detail/search-authors":
      "Yazarlar içinde ara !",

    //  /pages/public/author-pages/authors.js
    "/pages/public/author-pages/authors/discover-authors":
      "Rehberleri oluşturan yazarları keşfet, yeni rehbehleri ve veri tabanlarını kaçırma!",
    "/pages/public/author-pages/authors/author": "Yazar",
    "/pages/public/author-pages/authors/librarian": "Kütüphaneci",
    "/pages/public/author-pages/authors/librarians": "Kütüphaneciler",
    "/pages/public/author-pages/authors/search-author":
      "Aramak istediğin kütüphaneciyi yaz.",

    // /pages/public/category-pages/categories.jsx
    "/pages/public/category-pages/categories/categories-failed":
      "Kategoriler alınamadı",
    "/pages/public/category-pages/categories/category-list": "Kategori Listesi",

    // /pages/public/database-pages/databases.js
    "/pages/public/database-pages/databases/search-database":
      "Aramak istediğin veritabanını yaz.",
    "/pages/public/database-pages/databases/database-list": "Veri Tabanları",
    "/pages/public/database-pages/databases/database": "Veri Tabanı",
    "/pages/public/database-pages/databases/access-from-off-campus":
      "Kampüs dışından erişim nasıl sağlarım?",
    "/pages/public/database-pages/databases/learn-description":
      "Açıklamayı Öğren",

    // /pages/public/guide-pages/guide-detail.js
    "/pages/public/guide-pages/guide-detail/resource-use-limitation":
      "Bu kaynakların kullanımı sadece İzmir Yüksek Teknoloji Enstitüsü Fakülteleri, öğrencileri ve çalışanlarıyla sınırlı olup, sadece kişisel araştırma amaçları için kullanılabilir. Başka her türlü kullanımı yasaktır",
    "/pages/public/guide-pages/guide-detail/search-in-guide":
      "Guide içinde arama yap !",

    // /pages/public/guide-pages/guides.js
    "/pages/public/guide-pages/guides/guides-page": "Rehberler Sayfası",
    "/pages/public/guide-pages/guides/search-guide":
      "Aramak istediğin rehberi yaz.",
    "/pages/public/guide-pages/guides/guide": "Rehber",

    // /pages/app/guide-pages/create-guide.js
    "/pages/app/guide-pages/create-guide/success": "İşlem Başarılı",
    "/pages/app/guide-pages/create-guide/guide-created":
      "Rehber başarıyla oluşturuldu",
    "/pages/app/guide-pages/create-guide/page": "Sayfa",
    "/pages/app/guide-pages/create-guide/new-row-failed":
      "Yeni satır eklenemedi",
    "/pages/app/guide-pages/create-guide/select-page":
      "Lütfen satır eklemek istediğiniz sayfayı seçiniz",

    // /pages/app/mail-editor.js
    "/pages/app/mail-editor/save-changes": "Değişiklikleri Kaydet",
    "/pages/app/mail-editor/changes-done": "Editör Değişiklikleri Kaydedildi",

    // /pages/app/guide-pages/edit-guide.js
    "/pages/app/guide-pages/edit-guide/success": "İşlem Başarılı",
    "/pages/app/guide-pages/edit-guide/changes-saved":
      "Değişiklikler başarıyla kaydedildi",
    "/pages/app/guide-pages/edit-guide/page": "Sayfa",

    // /pages/app/guide-pages/guide-list-editor.js
    "/pages/app/guide-pages/guide-list-editor/guide-list-page":
      "Rehberler Sayfası",
    "/pages/app/guide-pages/guide-list-editor/search-guide":
      "Aramak istediğin rehberi yaz.",
    "/pages/app/guide-pages/guide-list-editor/guide": "Rehber",
    "/pages/app/guide-pages/guide-list-editor/new-guide": "Yeni Rehber",

    // /pages/app/types-page/edit-subjects-database-types.js
    "/pages/app/types-page/edit-subjects-database-types/edit-database-types":
      "Veritabanlarını Düzenle",
    "/pages/app/types-page/edit-subjects-database-types/types": "Türler",
    "/pages/app/types-page/edit-subjects-database-types/subjects": "Konular",
    "/pages/app/types-page/edit-subjects-database-types/add-remove-types":
      "Kaynaklarını yönetmek için yeni tür ekle ve çıkar.",
    "/pages/app/types-page/edit-subjects-database-types/add": "Ekle",
    "/pages/app/types-page/edit-subjects-database-types/add-new-type":
      "Yeni tür ekle",
    "/pages/app/types-page/edit-subjects-database-types/add-type-for-categorization":
      "Kategorilendirme için yeni tür ekle.",
    "/pages/app/types-page/edit-subjects-database-types/add-type-name":
      "Türün adı",
    "/pages/app/types-page/edit-subjects-database-types/example-art-design":
      "Örnek: Sanat ve Tasarım",
    "/pages/app/types-page/edit-subjects-database-types/edit-database-subjects":
      "Veritabanı konularını düzenle",
    "/pages/app/types-page/edit-subjects-database-types/manage-resources-subject":
      "Kullanıcaların konularına göre filtrelemeleri için seçenekleri çeşitlendir.",
    "/pages/app/types-page/edit-subjects-database-types/add-new-subject":
      "Yeni konu ekle",
    "/pages/app/types-page/edit-subjects-database-types/manage-resources-types":
      "Kullanıcaların türlerine göre filtrelemeleri için seçenekleri çeşitlendir.",
    "/pages/app/types-page/edit-subjects-database-types/subject-name":
      "Konunun adı",
    "/pages/app/types-page/edit-subjects-database-types/edit-guides":
      "Rehberleri Düzenle",
    "/pages/app/types-page/edit-subjects-database-types/edit-guide-types":
      "Rehber türlerini düzenle",
    "/pages/app/types-page/edit-subjects-database-types/add-new-guide-type":
      "Yeni rehber türü ekle",
    "/pages/app/types-page/edit-subjects-database-types/name-of-new-type":
      "Türün adı",
    "/pages/app/types-page/edit-subjects-database-types/edit-guide-subjects":
      "Rehber konularını düzenle",
    "/pages/app/types-page/edit-subjects-database-types/add-subject-for-categorization":
      "Kategorilendirme için yeni konu ekle.",
    "/pages/app/types-page/edit-subjects-database-types/new-subject-name":
      "Konunun adı",
    "/pages/app/types-page/edit-subjects-database-types/manage-providers":
      "Sağlayıcıları yönetmek için yeni sağlayıcı ekle ve çıkar.",
    "/pages/app/types-page/edit-subjects-database-types/edit-providers":
      "Sağlayıcıları düzenle",
    "/pages/app/types-page/edit-subjects-database-types/providers":
      "Sağlayıcılar",
    "/pages/app/types-page/edit-subjects-database-types/add-new-provider":
      "Yeni sağlayıcı ekle",
    "/pages/app/types-page/edit-subjects-database-types/add-database-provider":
      "Yeni veritabanı sağlayıcısı ekle.",
    "/pages/app/types-page/edit-subjects-database-types/new-provider-name":
      "Sağlayıcının adı",
    "/pages/app/types-page/edit-subjects-database-types/example": "ORN: ACNN",

    // /hooks/category/category.hooks.js
    "/hooks/category/category.hooks/delete-failed":
      "Kategori silme işlemi başarısız oldu.",

    // /hooks/database-subject/database-subject.hook
    "/hooks/database-subject/database-subject.hook/delete-failed":
      "Silme işlemi başarısız oldu.",

    // /hooks/database-type/database-type.hooks.js
    "/hooks/database-type/database-type.hooks/delete-failed":
      "Silme işlemi başarısız oldu.",

    //   /hooks/guide-subject/guide-subject.hook.js
    "/hooks/guide-subject/guide-subject.hook/delete-failed":
      "Silme işlemi başarısız oldu.",

    // /hooks/guide-type/guide-type.hook.js
    "/hooks/guide-type/guide-type.hook/delete-failed":
      "Silme işlemi başarısız oldu.",

    // /hooks/user/user.hooks.js
    "/hooks/user/user.hooks/add-new-user": "Yeni Kullanıcı ekle",
    "/hooks/user/user.hooks/user-added-successfully":
      "Yeni kullanıcı başarı ile eklendi.",
    "/hooks/user/user.hooks/unexpected-error-while-adding-user":
      "Yeni kullanıcı eklenirken beklenmeyen bir hata oluştu.",

    // /hooks/vendor/vendor-hooks.js
    "/hooks/vendor/vendor-hooks/delete-failed": "Silme işlemi başarısız oldu.",
  },

  "en-US": {
    lang: "english-us",
    // /common/components/buttons/share-buttons.js
    "/common/components/buttons/share-buttons/share-guide": "Share Guide",
    "/common/components/buttons/share-buttons/sharing-the-content":
      "Contribute to more people learning by sharing the content.",
    "/common/components/buttons/share-buttons/copy-the-link": "Copy the Link",

    // /common/components/cards/category-card.js
    "/common/components/cards/category-card/guides": "Guides",
    "/common/components/cards/category-card/databases": "Databases",

    // /common/components/cards/edit-category-card.js
    "/common/components/cards/edit-category-card/edit-category":
      "Edit Category",

    // /common/components/carousel/category-carousel.js
    "/common/components/carousel/category-carousel/gguides-presentation": "Library 4.0 that Offering Enhanced Learning Experiences with GGuides",

    // /common/components/filter/filter.js
    "/common/components/filter/filter/filters": "Filters",
    "/common/components/filter/filter/clear-filters": "Clear Filters",

    // /common/components/guide/guide-form.js
    "/common/components/guide/guide-form/deletion-successful":
      "Deletion successful.",
    "/common/components/guide/guide-form/deletion-success":
      "The guide you selected has been deleted successfully.",
    "/common/components/guide/guide-form/deletion-failed": "Deletion failed.",
    "/common/components/guide/guide-form/define-name":
      "Define the name of the guide.",
    "/common/components/guide/guide-form/open": "Open",
    "/common/components/guide/guide-form/close": "Close",
    "/common/components/guide/guide-form/access-granted":
      "Users can access the guide.",
    "/common/components/guide/guide-form/define-name-prompt":
      "Define the Name of the Guide",
    "/common/components/guide/guide-form/select-topics":
      "Select the topics of the guide",
    "/common/components/guide/guide-form/select-categories":
      "Select categories",
    "/common/components/guide/guide-form/select-types": "Select guide types",
    "/common/components/guide/guide-form/select-page-to-rename":
      "To change the page name, first select a page.",
    "/common/components/guide/guide-form/editing-not-possible":
      "Editing is not possible.",
    "/common/components/guide/guide-form/update-page": "Update the page",
    "/common/components/guide/guide-form/page-name-label": "Page Name",
    "/common/components/guide/guide-form/enter-new-page-name":
      "Enter the new page name.",
    "/common/components/guide/guide-form/save": "Save",
    "/common/components/guide/guide-form/title": "Title",
    "/common/components/guide/guide-form/content": "Content",
    "/common/components/guide/guide-form/new-guide": "New guide",
    "/common/components/guide/guide-form/manage-guides":
      "Create and manage your guides in one place.",
    "/common/components/guide/guide-form/delete-button": "Delete",
    "/common/components/guide/guide-form/save-button": "Save",
    "/common/components/guide/guide-form/visibility": "Visibility is",
    "/common/components/guide/guide-form/define-friendly-url": "A friendly URL",
    "/common/components/guide/guide-form/define-types":
      "Define types of the guide.",
    "/common/components/guide/guide-form/define-categories":
      "Define categories of the guide.",
    "/common/components/guide/guide-form/define-subjects":
      "Define subjects of the guide",
    "/common/components/guide/guide-form/define-friendly-url-prompt":
      "Create a new friendly URL to share",
    "/common/components/guide/guide-form/define-description":
      "Define a description for the guide",
    "/common/components/guide/guide-form/write-description":
      "Write someting attractive about your guide",
    "/common/components/guide/guide-form/are-u-sure-guide":
      "Are you sure you want to delete this guide ?",
    "/common/components/guide/guide-form/are-u-sure-content":
      "Are you sure you want to delete this content ?",
    "/common/components/guide/guide-form/no": "No",
    "/common/components/guide/guide-form/yes": "Yes",
    "/common/components/guide/guide-form/delete-the-page": "Delete the Page",
    "/common/components/guide/guide-form/what-is-friendly-url":
      "When creating a user-friendly URL, it must contain the keywords about the guides you create. For example, if you have created a guide that introduces elements in the field of chemistry, you should create a url such as 'iyte.gguides.com/chemistiry-table-of-elements'. Note: You don't need to write iyte.gguides.com/ at the beginning, we create it for you. All you have to do is find a unique set of keywords for the guide you created.",

    // /common/components/guide/guide-row-creator.js
    "/common/components/guide/guide-row-creator/1-column": "1 column",
    "/common/components/guide/guide-row-creator/card-size": "card size",
    "/common/components/guide/guide-row-creator/create-new-row":
      "Create new row",
    "/common/components/guide/guide-row-creator/define-grid-system":
      "Define the grid system and create content.",
    "/common/components/guide/guide-row-creator/create-new-chapter":
      "Create New Chapter",

    // /common/components/category/category-delete-modal.js
    "/common/components/category/category-delete-modal/are-you-sure":
      "Are you sure about deleting the category?",
    "/common/components/category/category-delete-modal/submit": "Submit",
    "/common/components/category/category-delete-modal/cancel": "Cancel",

    // /common/components/category/category-add-edit-modal.js
    "/common/components/category/category-add-edit-modal/update-successfull":
      "Update is successfull",
    "/common/components/category/category-add-edit-modal/create-a-new-category":
      "Create a New Category",
    "/common/components/category/category-add-edit-modal/update-category":
      "Edit the Category",
    "/common/components/category/category-add-edit-modal/add-a-type-for-categorization.":
      "Add a type for categorization.",
    "/common/components/category/category-add-edit-modal/update-for-categorization.":
      "Change for categorization.",

    // /common/components/modal/basic-modal.js
    "/common/components/modal/basic-modal/save": "Save",

    // /common/components/navbar/AppNavbar.js
    "/common/components/navbar/AppNavbar/home": "Home",
    "/common/components/navbar/AppNavbar/guides": "Guides",
    "/common/components/navbar/AppNavbar/databases": "Databases",
    "/common/components/navbar/AppNavbar/authors": "Authors",
    "/common/components/navbar/AppNavbar/edit-profile": "Edit Profile",
    "/common/components/navbar/AppNavbar/change-password": "Change Password",
    "/common/components/navbar/AppNavbar/log-out": "Log Out",
    "/common/components/navbar/AppNavbar/management-panel": "Management Panel",
    "/common/components/navbar/AppNavbar/institution-settings":
      "Institution Settings",
    "/common/components/navbar/AppNavbar/filters": "Filters",
    "/common/components/navbar/AppNavbar/guide-categories": "Guide Categories",
    "/common/components/navbar/AppNavbar/bulletin-management":
      "Bulletin Management",

    // /common/components/navbar/navbar.js
    "/common/components/navbar/navbar/login": "Login",
    "/common/components/navbar/navbar/home": "Home",
    "/common/components/navbar/navbar/guides": "Guides",
    "/common/components/navbar/navbar/databases": "Databases",
    "/common/components/navbar/navbar/authors": "Authors",

    // /common/components/searchBar/search-bar.js
    "/common/components/searchBar/search-bar/search-guide":
      "Search for the guide you want to write.",
    "/common/components/searchBar/search-bar/search": "Search",

    // /common/views/app/edit-types/edit-types.js
    "common/views/app/edit-types/edit-types/add": "Add",
    "common/views/app/edit-types/edit-types/confirm": "Confirm",
    "common/views/app/edit-types/edit-types/cancel": "Cancel",
    "common/views/app/edit-types/edit-types/are-u-sure-about-delete":
      "Are you sure about the delete ?",

    // /common/views/app/forms/database/database-form.js
    "/common/views/app/forms/database/database-form/delete-database":
      "Are you sure you want to delete the database?",
    "/common/views/app/forms/database/database-form/deletion-warn":
      "If you delete the database, users will not be able to access it again. If you prefer, you can only turn off its visibility.",
    "/common/views/app/forms/database/database-form/cancel": "Cancel",
    "/common/views/app/forms/database/database-form/confirm": "Yes, I'm sure.",
    "/common/views/app/forms/database/database-form/save": "Save",
    "/common/views/app/forms/database/database-form/delete": "Delete",
    "/common/views/app/forms/database/database-form/open": "Open",
    "/common/views/app/forms/database/database-form/close": "Close",
    "/common/views/app/forms/database/database-form/visibility":
      "Visibility is",
    "/common/views/app/forms/database/database-form/accessible":
      "Users can access the database.",
    "/common/views/app/forms/database/database-form/database-name":
      "Name of the Database",
    "/common/views/app/forms/database/database-form/title":
      "Set a title for your guide.",
    "/common/views/app/forms/database/database-form/description":
      "Description of the Database",
    "/common/views/app/forms/database/database-form/description-error":
      "The database description must be at least 20 characters long.",
    "/common/views/app/forms/database/database-form/guide-description":
      "Set a description for your guide.",
    "/common/views/app/forms/database/database-form/add-connection":
      "Add database connection (URL).",
    "/common/views/app/forms/database/database-form/select-database-document":
      "Select database document",
    "/common/views/app/forms/database/database-form/database-url":
      "Enter the Database URL",
    "/common/views/app/forms/database/database-form/database-status":
      "Select Database Status",
    "/common/views/app/forms/database/database-form/database-vendor":
      "Select the Database Vendor",
    "/common/views/app/forms/database/database-form/database-subjects":
      "Select Database Subjects",
    "/common/views/app/forms/database/database-form/database-types":
      "Select Database Types",
    "/common/views/app/forms/database/database-form/database-categories":
      "Select Database Categories",
    "/common/views/app/forms/database/database-form/delete": "Delete",
    /*Validations*/
    "/common/views/app/forms/database/database-form/enter-database":
      "You must enter name of the database",
    "/common/views/app/forms/database/database-form/double-space-error":
      "You can not enter double space between words",
    "/common/views/app/forms/database/database-form/select-vendor":
      "You must select a vendor of the database",
    "/common/views/app/forms/database/database-form/choose-category":
      "You must choose at least one category for the database",
    "/common/views/app/forms/database/database-form/choose-subject":
      "You must choose at least one subject for the database",
    "/common/views/app/forms/database/database-form/choose-type":
      "You must choose at least one type for the database",
    "/common/views/app/forms/database/database-form/choose-status":
      "You must select the status of the database",
    "/common/views/app/forms/database/database-form/database-url-error":
      "You must enter URL of the database",

    // /common/views/author/author-card.js
    "/common/views/author/author-card/send-mail": "Send mail",
    "/common/views/author/author-card/profile": "View Profile",

    // /common/views/author/author-detail-view.js
    "/common/views/author/author-detail-view/give-admin-permissions":
      "Admin permissions given to user.",
    "/common/views/author/author-detail-view/failed-permission-grant":
      "Failed to grant permissions to user.",
    "/common/views/author/author-detail-view/successful-delete":
      "Successfully deleted user.",
    "/common/views/author/author-detail-view/failed-delete":
      "Failed to delete user.",
    "/common/views/author/author-detail-view/give-admin-permissions-button":
      "Give admin permissions to user.",
    "/common/views/author/author-detail-view/delete-user": "Delete user.",
    "/common/views/author/author-detail-view/send-email-button": "Send Email",
    "/common/views/author/author-detail-view/librarian-label": "Librarian",

    //  /common/views/filter-view/filter-view.js
    "/common/views/filter-view/filter-view/types": "Types",
    "/common/views/filter-view/filter-view/topics": "Topics",
    "/common/views/filter-view/filter-view/authors": "Authors",
    "/common/views/filter-view/filter-view/visibility": "Visibility",
    "/common/views/filter-view/filter-view/visible-databases":
      "Visible databases",
    "/common/views/filter-view/filter-view/visible-guides": "Visible guides",

    // /common/views/info-box/database-box.js
    "/common/views/info-box/database-box/no-description":
      "No description found.",
    "/common/views/info-box/database-box/new": "New",
    "/common/views/info-box/database-box/share-database": "Share the Database",

    // /common/views/info-box/guide-box.js
    "/common/views/info-box/guide-box/new": "New",
    "/common/views/info-box/guide-box/share-guide": "Share the Guide",

    //  /common/views/info-box/information-box.js
    "/common/views/info-box/information-box/search": "SEARCH",

    // /pages/public/auth-pages/email-sent.js
    "/pages/public/auth-pages/email-sent/check-email-address":
      "Check your email address!",
    "/pages/public/auth-pages/email-sent/check-email-for-link":
      "We have sent a link to change your password to the address, please check. It may have ended up in the spam folder.",
    "/pages/public/auth-pages/email-sent/return-to-login":
      "Return to Login Screen",

    // /pages/public/auth-pages/forgot-password.js
    "/pages/public/auth-pages/forgot-password/did-you-forget-password":
      "Did you forget your password?",
    "/pages/public/auth-pages/forgot-password/dont-worry-link":
      "Don't worry, we will send you a link to reset your password.",
    "/pages/public/auth-pages/forgot-password/email": "Email",
    "/pages/public/auth-pages/forgot-password/invalid-email":
      "Email is not valid",
    "/pages/public/auth-pages/forgot-password/reset-password": "Reset Password",
    "/pages/public/auth-pages/forgot-password/return-to-login":
      "Return to Login Screen",

    // /pages/public/auth-pages/login.js
    "/pages/public/auth-pages/login/login": "Log In",
    "/pages/public/auth-pages/login/readers-waiting":
      "Your readers are waiting for you!",
    "/pages/public/auth-pages/login/email": "Email",
    "/pages/public/auth-pages/login/invalid-email": "Email is not valid",
    "/pages/public/auth-pages/login/password": "Password",
    "/pages/public/auth-pages/login/password-length":
      "Password must be at least 6 characters long",
    "/pages/public/auth-pages/login/password-rules":
      "+9 characters, letters ve number",
    "/pages/public/auth-pages/login/example-email": "example@.com",
    "/pages/public/auth-pages/login/forgot-password": "Forgot Password",
    "/pages/public/auth-pages/login/login-button": "Log In",
    "/pages/public/auth-pages/login/easy-content-creation":
      "Content creation and management is easy with GGuide",

    // /pages/public/auth-pages/password-changed.js
    "/pages/public/auth-pages/password-changed/password-changed":
      "Password has been successfully changed.",
    "/pages/public/auth-pages/password-changed/login-with-new-password":
      "Return to the login screen to log in with your new password.",
    "/pages/public/auth-pages/password-changed/return-to-login":
      "Return to login screen",

    // /pages/public/author-pages/author-detail.js
    "/pages/public/author-pages/author-detail/guides": "Guides",
    "/pages/public/author-pages/author-detail/databases": "Databases",
    "/pages/public/author-pages/author-detail/search-authors":
      "Search authors !",

    //  /pages/public/author-pages/authors.js
    "/pages/public/author-pages/authors/discover-authors":
      "Discover the authors who created the guides, don't miss the new guides and databases!",
    "/pages/public/author-pages/authors/author": "Author",
    "/pages/public/author-pages/authors/librarian": "Librarian",
    "/pages/public/author-pages/authors/search-author":
      "Type the author you want to search for.",

    // /pages/public/category-pages/categories.jsx
    "/pages/public/category-pages/categories/categories-failed":
      "Categories could not be retrieved",
    "/pages/public/category-pages/categories/category-list": "Category List",

    // /pages/public/database-pages/databases.js
    "/pages/public/database-pages/databases/search-database":
      "Type the database you want to search for.",
    "/pages/public/database-pages/databases/database-list": "Databases",
    "/pages/public/database-pages/databases/database": "Database",
    "/pages/public/database-pages/databases/access-from-off-campus":
      "How can I access from off-campus?",
    "/pages/public/database-pages/databases/learn-description":
      "Learn Description",

    // /pages/public/guide-pages/guide-detail.js
    "/pages/public/guide-pages/guide-detail/resource-use-limitation":
      "The use of these resources is limited to Izmir Institute of Technology's faculties, students, and staff, and can only be used for personal research purposes. Any other use is prohibited",
    "/pages/public/guide-pages/guide-detail/search-in-guide":
      "Search in guide !",

    // /pages/public/guide-pages/guides.js
    "/pages/public/guide-pages/guides/guides-page": "Guides Page",
    "/pages/public/guide-pages/guides/search-guide":
      "Write the guides you want to search.",
    "/pages/public/guide-pages/guides/guide": "Guide",

    // /pages/app/guide-pages/create-guide.js
    "/pages/app/guide-pages/create-guide/success": "Success",
    "/pages/app/guide-pages/create-guide/guide-created":
      "Guide successfully created",
    "/pages/app/guide-pages/create-guide/page": "Page",
    "/pages/app/guide-pages/create-guide/new-row-failed":
      "Failed to add new row",
    "/pages/app/guide-pages/create-guide/select-page":
      "Please select the page where you want to add the row",

    // /pages/app/mail-editor.js
    "/pages/app/mail-editor/save-changes": "Save Changes",
    "/pages/app/mail-editor/changes-done": "Editor Changes Done!",

    // /pages/app/guide-pages/edit-guide.js
    "/pages/app/guide-pages/edit-guide/success": "Success",
    "/pages/app/guide-pages/edit-guide/changes-saved":
      "Changes saved successfully",
    "/pages/app/guide-pages/edit-guide/page": "Page",

    // /pages/app/guide-pages/guide-list-editor.js
    "/pages/app/guide-pages/guide-list-editor/guide-list-page":
      "Guide List Page",
    "/pages/app/guide-pages/guide-list-editor/search-guide":
      "Type the guide you want to search for.",
    "/pages/app/guide-pages/guide-list-editor/guide": "Guide",
    "/pages/app/guide-pages/guide-list-editor/new-guide": "New Guide",

    // /pages/app/types-page/edit-subjects-database-types.js
    "/pages/app/types-page/edit-subjects-database-types/edit-database-types":
      "Edit Databases",
    "/pages/app/types-page/edit-subjects-database-types/types": "Types",
    "/pages/app/types-page/edit-subjects-database-types/subjects": "Subjects",
    "/pages/app/types-page/edit-subjects-database-types/add-remove-types":
      "Add and remove new types to manage resources.",
    "/pages/app/types-page/edit-subjects-database-types/add": "Add",
    "/pages/app/types-page/edit-subjects-database-types/add-new-type":
      "Add new type",
    "/pages/app/types-page/edit-subjects-database-types/add-for-categorization":
      "Add new type for categorization.",
    "/pages/app/types-page/edit-subjects-database-types/add-type-name":
      "Name of type",
    "/pages/app/types-page/edit-subjects-database-types/example-art-design":
      "EX: Art and Design",
    "/pages/app/types-page/edit-subjects-database-types/edit-database-subjects":
      "Edit database subjects",
    "/pages/app/types-page/edit-subjects-database-types/add-new-subject":
      "Add new subject",
    "/pages/app/types-page/edit-subjects-database-types/subject-name":
      "Name of subject",
    "/pages/app/types-page/edit-subjects-database-types/edit-guides":
      "Edit Guides",
    "/pages/app/types-page/edit-subjects-database-types/edit-guide-types":
      "Edit guide types",
    "/pages/app/types-page/edit-subjects-database-types/manage-resources-subject":
      "Diversify options for users to filter by topic.",
    "/pages/app/types-page/edit-subjects-database-types/manage-resources-types":
      "Diversify options for users to filter by types.",
    "/pages/app/types-page/edit-subjects-database-types/add-new-guide-type":
      "Add new guide type",
    "/pages/app/types-page/edit-subjects-database-types/add-type-for-categorization":
      "Add new type for categorization.",
    "/pages/app/types-page/edit-subjects-database-types/name-of-new-type":
      "Name of type",
    "/pages/app/types-page/edit-subjects-database-types/edit-guide-subjects":
      "Edit guide subjects",
    "/pages/app/types-page/edit-subjects-database-types/add-new-subject":
      "Add new subject",
    "/pages/app/types-page/edit-subjects-database-types/add-subject-for-categorization":
      "Add new subject for categorization.",
    "/pages/app/types-page/edit-subjects-database-types/new-subject-name":
      "Name of subject",
    "/pages/app/types-page/edit-subjects-database-types/manage-providers":
      "Add and remove new providers to manage providers.",
    "/pages/app/types-page/edit-subjects-database-types/edit-providers":
      "Edit Providers",
    "/pages/app/types-page/edit-subjects-database-types/providers": "Providers",
    "/pages/app/types-page/edit-subjects-database-types/add-new-provider":
      "Add new provider",
    "/pages/app/types-page/edit-subjects-database-types/add-database-provider":
      "Add new database provider.",
    "/pages/app/types-page/edit-subjects-database-types/new-provider-name":
      "Name of provider",
    "/pages/app/types-page/edit-subjects-database-types/example": "EX: ACNN",

    // /hooks/category/category.hooks.js
    "/hooks/category/category.hooks/delete-failed":
      "Category delete operation failed.",

    // /hooks/database-subject/database-subject.hook
    "/hooks/database-subject/database-subject.hook/delete-failed":
      "Delete operation failed.",

    // /hooks/database-type/database-type.hooks.js
    "/hooks/database-type/database-type.hooks/delete-failed":
      "Delete operation failed.",

    //   /hooks/guide-subject/guide-subject.hook.js
    "/hooks/guide-subject/guide-subject.hook/delete-failed":
      "Delete operation failed.",

    // /hooks/guide-type/guide-type.hook.js
    "/hooks/guide-type/guide-type.hook/delete-failed":
      "Delete operation failed.",

    // /hooks/user/user.hooks.js
    "/hooks/user/user.hooks/add-new-user": "Add New User",
    "/hooks/user/user.hooks/user-added-successfully":
      "New user added successfully.",
    "/hooks/user/user.hooks/unexpected-error-while-adding-user":
      "An unexpected error occurred while adding a new user.",

    // /hooks/vendor/vendor-hooks.js
    "/hooks/vendor/vendor-hooks/delete-failed": "Delete operation failed.",

    // /pages/app/admin-pages/tenant-settings.js
    "/pages/app/admin-pages/tenant-settings/change-saved":
      "Your changes have been saved to the system.",
    "/pages/app/admin-pages/tenant-settings/save": "Save",
    "/pages/app/admin-pages/tenant-settings/institution-visual-identity":
      "Institution Visual Identity",
    "/pages/app/admin-pages/tenant-settings/institution-identity-arrangement":
      "Edit GGuide based on your institution's visual identity. Special for you.",
    "/pages/app/admin-pages/tenant-settings/change-institution-logo":
      "Drag and drop your image to change the institutional logo or you can select from files.",
    "/pages/app/admin-pages/tenant-settings/or-select-file":
      "or select from files.",
    "/pages/app/admin-pages/tenant-settings/select-file": "Select file",
    "/pages/app/admin-pages/tenant-settings/file-format":
      "(PNG, SVG, JPEG formats in 1:1 size (eg: 800x800px) file format)",
    "/pages/app/admin-pages/tenant-settings/hex-code":
      "Institution Color Code(Hex Code)",
    "/pages/app/admin-pages/tenant-settings/enter-valid-hex-code":
      "Please enter a valid hex code!",
    "/pages/app/admin-pages/tenant-settings/secondary-hex-code":
      "Secondary institution Color Code (Hex Code)",
    "/pages/app/admin-pages/tenant-settings/institution-name":
      "Institution Name",
    "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank":
      "This field cannot be left blank!",
    "/pages/app/admin-pages/tenant-settings/at-least-20-char":
      "This field requires at least 20 character.",
    "/pages/app/admin-pages/tenant-settings/must-max-7-char":
      "This field must be filled with maximum 7 character",
    "/pages/app/admin-pages/tenant-settings/enter-valid-url":
      "Please enter a valid URL",
    "/pages/app/admin-pages/tenant-settings/institution-abbreviation":
      "Institution Abbreviation",
    "/pages/app/admin-pages/tenant-settings/institution-descriptions":
      "Institution Descriptions",
    "/pages/app/admin-pages/tenant-settings/guide-your-users":
      "Guide your users, describe your organization, and add a help link.",
    "/pages/app/admin-pages/tenant-settings/guides-description":
      "Guides Description",
    "/pages/app/admin-pages/tenant-settings/add-a-description-guide-page":
      "Add a description to the Guides page.",
    "/pages/app/admin-pages/tenant-settings/databases-description":
      "Databases Description",
    "/pages/app/admin-pages/tenant-settings/add-a-description-databases-page":
      "Add a description to the Databases page.",
    "/pages/app/admin-pages/tenant-settings/databases-off-campus-access-help-link":
      "Databases Off-Campus Access Help Link",
    "/pages/app/admin-pages/tenant-settings/max-7-char":
      "(maximum 7 character)",

    // /pages/app/auth-pages/reset-password.js
    "/pages/app/auth-pages/reset-password/set-your-new-password":
      "Set your new password",
    "/pages/app/auth-pages/reset-password/new-password-diffrent-you-used-before":
      "Your new password must be different from the passwords you used before.",
    "/pages/app/auth-pages/reset-password/+9-characters-letters-and-numbers":
      "+9 characters,letters and numbers",
    "/pages/app/auth-pages/reset-password/back-to-login-sceen":
      "Back to Login Sceen",
    "/pages/app/auth-pages/reset-password/reset-password": "Reset Password",
    "/pages/app/auth-pages/reset-password/password": "Password",
    "/pages/app/auth-pages/reset-password/password-again": "Password Again",

    // /pages/app/author-pages/author-detail-editor.js
    "/pages/app/author-pages/author-detail-editor/guides": "Guides",
    "/pages/app/author-pages/author-detail-editor/databates": "Databates",
    "/pages/app/author-pages/author-detail-editor/search-in-authors":
      "Search in Authors !",

    // /pages/app/author-pages/author-list-editor.js
    "/pages/app/author-pages/author-list-editor/discover-authors":
      "Discover the authors who created the guides, don't miss the new guides and databases!",
    "/pages/app/author-pages/author-list-editor/author": "Author",
    "/pages/app/author-pages/author-list-editor/add-new-user": "Add New User",
    "/pages/app/author-pages/author-list-editor/enter-info":
      "Enter the user's information and we'll send them an email.",
    "/pages/app/author-pages/author-list-editor/name": "User's Name",
    "/pages/app/author-pages/author-list-editor/enter-name":
      "Enter User's Name",
    "/pages/app/author-pages/author-list-editor/surname": "User's Surname",
    "/pages/app/author-pages/author-list-editor/enter-surname":
      "Enter User's Surname",
    "/pages/app/author-pages/author-list-editor/email": "Email",
    "/pages/app/author-pages/author-list-editor/enter-email":
      "Enter user's email address.",
    "/pages/app/author-pages/author-list-editor/add-as-admin":
      "Add as administrator",
    "/pages/app/author-pages/author-list-editor/email-visibility":
      "Change the e-mail visibility",
    "/pages/app/author-pages/author-list-editor/admin-description":
      "Can add and edit users and organization settings.",
    "/pages/app/author-pages/author-list-editor/visibility-description":
      "You can change the email visibility for those who might be interested",
    "/pages/app/author-pages/author-list-editor/add": "Add New User",
    "/pages/app/author-pages/author-list-editor/new-librarian": "New Librarian",
    "/pages/app/author-pages/author-list-editor/librarian-description":
      "User's Description",
    "/pages/app/author-pages/author-list-editor/add-description":
      "Please describe the user you are going to add for us",
    "/pages/app/author-pages/author-list-editor/min-20-max-1500":
      "Description field must be between 20 and 1500 character.",
    "/pages/app/author-pages/author-list-editor/librarian": "Librarian",
    "/pages/app/author-pages/author-list-editor/librarians": "Librarians",
    "/pages/app/author-pages/author-list-editor/search-author":
      "Type the author you want to search for",

    // /pages/app/author-pages/profile-update.js
    "/pages/app/author-pages/profile-update/changes-saved":
      "Your changes have been saved to the system.",
    "/pages/app/author-pages/profile-update/save-changes": "Save Changes",
    "/pages/app/author-pages/profile-update/name": "Name",
    "/pages/app/author-pages/profile-update/name-required":
      "Name field cannot be left empty.",
    "/pages/app/author-pages/profile-update/surname": "Surname",
    "/pages/app/author-pages/profile-update/surname-required":
      "Surname field cannot be left empty.",
    "/pages/app/author-pages/profile-update/description": "Description Field",
    "/pages/app/author-pages/profile-update/description-required":
      "Description field must be at least 20 characters long.",
    "/pages/app/author-pages/profile-update/email": "E - mail",
    "/pages/app/author-pages/profile-update/email-invalid":
      "Please enter a valid email address.",
    "/pages/app/author-pages/profile-update/email-visible":
      "Email address is visible.",
    "/pages/app/author-pages/profile-update/email-not-visible":
      "Email address is not visible.",
    "/pages/app/author-pages/profile-update/email-can-be-sent":
      "Users can send email.",

    // /pages/app/category-pages/category-list-editor.js
    "/pages/app/category-pages/category-list-editor/library-guides-and-databases":
      "Library Guides and Databases Categories",

    // /pages/app/category-pages/edit-categories.js
    "/pages/app/category-pages/edit-categories/category-deleted":
      "Category Deleted",
    "/pages/app/category-pages/edit-categories/success": "Success",
    "/pages/app/category-pages/edit-categories/yes": "Yes",
    "/pages/app/category-pages/edit-categories/no": "No",
    "/pages/app/category-pages/edit-categories/confirm-delete":
      "Are you sure you want to delete?",
    "/pages/app/category-pages/edit-categories/category-added":
      "Category added",
    "/pages/app/category-pages/edit-categories/add-subcategory":
      "Add Subcategory",
    "/pages/app/category-pages/edit-categories/category-name": "Category Name",
    "/pages/app/category-pages/edit-categories/add": "add",
    "/pages/app/category-pages/edit-categories/edit-categories":
      "Edit Categories",

    "/pages/app/category-pages/edit-categories/drag-drop-info":
      "To add the category photo, drag and drop your image or select it from your files.",
    "/pages/app/category-pages/edit-categories/category-photo-format":
      "(PNG, SVG, JPEG Formats in 3:4 size (eg: 212x282px) file format)",
    "/pages/app/category-pages/edit-categories/choose-a-file": "Choose a File",
    "/pages/app/category-pages/edit-categories/add-a-new-category":
      "Add a New Category",
    "/pages/app/category-pages/edit-categories/create-a-special-category-tree":
      "Create Custom Category Trees",
    "/pages/app/category-pages/edit-categories/organize-guides-databases":
      "Organize your guides and databases into major categories and subcategories. Add new category and create subcategories.",

    // /pages/app/category-pages/edit-category.js
    "/pages/app/category-pages/edit-category/success": "Success",
    "/pages/app/category-pages/edit-category/update-success":
      "Update of category is successfull",
    "/pages/app/category-pages/edit-category/save-the-changes": "Save Changes",
    "/pages/app/category-pages/edit-category/category-photo": "Category Photo",
    "/pages/app/category-pages/edit-category/organize-all-categories":
      "Edit all your categories, add or delete subcategories.",
    "/pages/app/category-pages/edit-category/suggestions": "Suggestions",
    "/pages/app/category-pages/edit-category/sufficient-photo":
      "By uploading photos according to the categories, you can create a clear and aesthetically pleasing environment that other users can easily benefit from.",
    "/pages/app/category-pages/edit-category/high-res-photo":
      "The quality of the photo should be high and clear, it is important that it is clear and bright.",
    "/pages/app/category-pages/edit-category/real-photo":
      "The colors of the photo should be realistic and of high quality, pale or very bright colors are not suitable.",
    "/pages/app/category-pages/edit-category/copyright-photo":
      "It is important that the license of the uploaded photo is free and usable, there should be no copyright infringement. (You can take advantage of free stock photo sites such as Unsplash, pexels.)",
    "/pages/app/category-pages/edit-category/category-management":
      "Category Management",
    "/pages/app/category-pages/edit-category/empty-category": "Empty Category",
    "/pages/app/category-pages/edit-category/new-category":
      "Add a New Category",
    "/pages/app/category-pages/edit-category/delete-the-category":
      "Delete the Category",

    // /pages/app/database-pages/add-database-page.js
    "/pages/app/database-pages/add-database-page/add-database":
      "Add New Database",
    "/pages/app/database-pages/add-database-page/enter-database-info":
      "Enter the information for the database you want to add.",

    // /pages/app/database-pages/database-list-editor.js
    "/pages/app/database-pages/database-list-editor/search-database":
      "Type the database you want to search for.",
    "/pages/app/database-pages/database-list-editor/databases": "Databases",
    "/pages/app/database-pages/database-list-editor/database": "Database",
    "/pages/app/database-pages/database-list-editor/off-campus-access":
      "How do I access from off-campus?",
    "/pages/app/database-pages/database-list-editor/learn-more": "Learn More",
    "/pages/app/database-pages/database-list-editor/import-database":
      "Import Database",
    "/pages/app/database-pages/database-list-editor/add-database":
      "Add New Database",

    // /pages/app/database-pages/update-database-page.js
    "/pages/app/database-pages/update-database-page/edit-databates":
      "Edit Database",
    "/pages/app/database-pages/update-database-page/databates-information":
      "Enter the information for the database you want to edit.",

    // /pages/app/bulletin-pages/bulletin-management.js
    "/pages/app/bulletin-pages/bulletin-management/bulletins": "Bulletins",
    "/pages/app/bulletin-pages/bulletin-management/users": "Users",

    // /pages/app/bulletin-pages/bulletin-management/bulletins.js
    "/pages/app/bulletin-pages/bulletin-management/bulletin-groups":
      "Bulletin Groups",
    "/pages/app/bulletin-pages/bulletin-management/new-bulletin-group":
      "New Bulletin Group",
    "/pages/app/bulletin-pages/bulletin-management/announcement-bulletins":
      "Announcement Bulletins",
    "/pages/app/bulletin-pages/bulletin-management/new-announcement-bulletin":
      "New Announcement",
    "/pages/app/bulletin-pages/bulletin-management/bulletin-detail":
      "Go To Bulletin Detail",

    // /pages/app/bulletin-pages/bulletin-management/new-bulletin-group-modal

    "/pages/app/bulletin-pages/bulletin-management/create-a-new-bulletin-group":
      "Create a New Bulletin Group",
    "/pages/app/bulletin-pages/bulletin-management/add-a-type":
      "Add a type for categorization",
    "/pages/app/bulletin-pages/bulletin-management/choose-a-category":
      "Choose a category or subcategory",
    "/pages/app/bulletin-pages/bulletin-management/example": "Ex: Bibliography",
    "/pages/app/bulletin-pages/bulletin-management/add": "Add",

    // /pages/app/bulletin-pages/management-pages/detail-pages/users
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/previous":
      "Previous",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/next":
      "Next",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/loading":
      "Loading",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin":
      "Add User to Bulletin",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin-description":
      "Organize your contacts and databases into large categories and subcategories. Add new categories and create subcategories.",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user":
      "Add User",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/user":
      "User",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/email-address":
      "Email Address",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/date-that-added-to-system":
      "Date Added to System",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/cancel":
      "Cancel",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/submit":
      "Submit",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/are-you-sure":
      "Are you sure?",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-mail-user-success":
      "Mail user added successfully!",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-mail-user-failure":
      "Failed to add mail user!",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-new-user":
      "Add a new user",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-current-user":
      "Add an existing user",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/name":
      "Name",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/surname":
      "Surname",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/email":
      "Email",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add": "Add",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/import-via-file":
      "Import via file",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-from-system":
      "Add from system",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-with-email":
      "Add with email",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-for-email":
      "Add new users for new emails.",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/mail-user-deleted":
      "Mail User Deleted.",
    "/pages/app/bulletin-pages/management-pages/detail-pages/users/cant-delete-mail-user":
      "The Mail User Cannot be Deleted.",
  },
};

export const localization = (key) => {
  const lang = window.localStorage.getItem("languagePreference");
  return map[map[lang] ? lang : "tr-TR"][key] ?? "";
};
