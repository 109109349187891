import { useMutation, useQuery } from "react-query";
import {
  deleteMailUser,
  getMailUser,
  getMailUsers,
  postMailUser,
} from "../../api/mailUser/mailUser.api";

export const usePostMailUser = (onSuccess, onError) => {
  return useMutation(
    "post-new-mail-user",
    (body) => {
      return postMailUser(body);
    },
    { onSuccess, onError }
  );
};

export const useGetMailUsers = () => {
  return useQuery("get-mail-users", () => {
    return getMailUsers();
  });
};

export const useGetMailUser = (id) => {
  return useQuery(
    "get-mail-user",
    () => {
      return getMailUser(id);
    },
    {
      enabled: id,
    }
  );
};

export const useDeleteMailUser = (onSuccess, onError) => {
  return useMutation(
    "delete-mail-user",
    (id) => {
      return deleteMailUser(id);
    },
    { onSuccess: onSuccess, onError: onError }
  );
};
