import * as api from "../api";

export const postGuideType = (body) => {
  return api.post(`/guide-type`, body);
};

export const deleteGuideType = (id) => {
  return api.del(`/guide-type/${id}`);
};

export const getGuideTypes = (page, size) => {
  return api.get(`/guide-type?page=${page}&size=${size}`);
};
