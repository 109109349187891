import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Upload,
  notification,
} from "antd";
import React, { useState } from "react";
import {
  UserAddOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { EditIcon } from "../../../../../icons/common-icon";
import { useParams } from "react-router-dom";
import {
  useDeleteMailUserFromMailGroup,
  useGetMailGroup,
  usePostMailUserToMailGroup,
} from "../../../../../hooks/mailGroup/mailGroup.hook";
import { usePostMailUser } from "../../../../../hooks/mailUser/mailUser.hook";
import { localization } from "../../../../../localization/localization";
const { Dragger } = Upload;

const DetailsUsers = () => {
  const { id: categoryId } = useParams();
  const {
    data: mailGroupData,
    isLoading: isMailGroupLoading,
    refetch: refetchMailGroupData,
  } = useGetMailGroup(categoryId);

  const [isDeleteMailUserModalOpen, setDeleteMailUserModalOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { mutate: mutateDeleteMailUserFromMailGroup } =
    useDeleteMailUserFromMailGroup(
      () => {
        notification.success({ description: localization("/pages/app/bulletin-pages/management-pages/detail-pages/users/mail-user-deleted") });
        refetchMailGroupData(categoryId);
        setDeleteMailUserModalOpen(false);
      },
      () => notification.error({ description: localization("/pages/app/bulletin-pages/management-pages/detail-pages/users/cant-delete-mail-user") })
    );
  const onMailUserDelete = () => {
    mutateDeleteMailUserFromMailGroup({
      userId: selectedUserId,
      mailGroupId: mailGroupData?.id,
    });
  };
  const onMailUserDeleteCancel = () => {
    setDeleteMailUserModalOpen(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const pagination = {
    defaultCurrent: 1,
    current: currentPage,
    pageSize: 10, // Define the number of items per page
    total: mailGroupData?.mailUserList.length, // Define the total number of items
    onChange: handlePageChange, // Define the function to handle page changes
    itemRender: (current, type, originalElement) => {
      if (type === "prev") {
        return (
          <>
            {" "}
            <ArrowLeftOutlined />
            {localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/previous"
            )}
          </>
        );
      }
      if (type === "next") {
        return (
          <>
            {localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/next"
            )}{" "}
            <ArrowRightOutlined />{" "}
          </>
        );
      }
      if (type === "page") {
        return <div className="rounded border-0">{current}</div>;
      }
      return originalElement;
    },
    position: ["bottomCenter"],
  };

  return (
    <>
      {isMailGroupLoading ? (
        <div>
          {localization(
            "/pages/app/bulletin-pages/management-pages/detail-pages/users/loading"
          )}
          ...
        </div>
      ) : (
        <>
          <Row className="p-6 bg-White mt-6 rounded-md">
            <Col span={21}>
              <Row>
                <Col
                  span={1}
                  className="flex justify-center items-center mr-3 flex-wrap"
                >
                  <UserAddOutlined className="text-2xl pt-1 pb-3 px-3 rounded-full bg-Primary-50 text-Primary-500" />
                </Col>
                <Col span={22} className="flex flex-col justify-between">
                  <div className="font-medium text-base">
                    {localization(
                      "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin"
                    )}
                  </div>
                  <div className="font-normal text-Grey-600">
                    {localization(
                      "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin-description"
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={3} className="flex justify-end items-center flex-wrap ">
              <Button
                size="large"
                className="rounded-lg bg-Primary-500 text-White hover:border-Primary-500 hover:text-Primary-500"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                {localization(
                  "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user"
                )}
              </Button>
              <AddUserModal
                isShow={isModalOpen}
                setShow={setModalOpen}
                mailGroupId={mailGroupData?.id}
                refetch={refetchMailGroupData}
              />
            </Col>
          </Row>
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0
                ? " text-Black text-sm font-medium bg-White "
                : "text-black text-sm font-medium bg-Grey-50 border"
            }
            className="flex-1 mt-6"
            columns={[
              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    {localization(
                      "/pages/app/bulletin-pages/management-pages/detail-pages/users/user"
                    )}
                  </div>
                ),
                dataIndex: "userName",
                key: "userName",
                width: "490px",
                render: (_, mailUser) => {
                  return (
                    <div>{`${mailUser?.firstName} ${mailUser?.lastName}`}</div>
                  );
                },
              },
              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    {localization(
                      "/pages/app/bulletin-pages/management-pages/detail-pages/users/email-address"
                    )}
                  </div>
                ),
                dataIndex: "email",
                key: "email",
                render: (_, mailUser) => (
                  <div className="font-normal  text-sm text-Grey-500">
                    {mailUser?.emailAddress}
                  </div>
                ),
                width: "490px",
              },

              {
                title: () => (
                  <div className="text-Grey-500 font-medium text-xs">
                    {localization(
                      "/pages/app/bulletin-pages/management-pages/detail-pages/users/date-that-added-to-system"
                    )}
                  </div>
                ),
                dataIndex: "created",
                key: "created",
                render: (text) => (
                  <div className="font-normal  text-sm text-Grey-500">
                    {text}
                  </div>
                ),
                width: "225px",
              },
              {
                dataIndex: "",
                key: "x",
                width: "136px",
                render: (_, mailUser) => (
                  <div className="flex gap-4 ">
                    <Button
                      className="bg-Error-200 border-Error-500 hover:border-Error-200 focus:border-Error-200 rounded-md "
                      icon={
                        <DeleteOutlined
                          className="text-Error-500"
                          onClick={() => {
                            setDeleteMailUserModalOpen(true);
                            setSelectedUserId(mailUser?.id);
                          }}
                        />
                      }
                    />
                    <Button
                      className="bg-White border-Primary-500 hover:border-Primary-200 focus:border-Primary-200  flex justify-center items-center rounded-md"
                      icon={
                        <div className=" ml-0.5">
                          <EditIcon className="stroke-Primary-500 w-4 h-4" />
                        </div>
                      }
                    />
                  </div>
                ),
              },
            ]}
            pagination={false}
            footer={() => (
              <Row className="flex items-center justify-center">
                <Pagination
                  className="flex-1 text-center"
                  {...pagination}
                  showSizeChanger={false}
                />
              </Row>
            )}
            dataSource={mailGroupData?.mailUserList.slice(
              (currentPage - 1) * pagination.pageSize,
              currentPage * pagination.pageSize
            )}
          />
        </>
      )}
      <DeleteMailUserModal
        isOpen={isDeleteMailUserModalOpen}
        onCancel={onMailUserDeleteCancel}
        onSubmit={onMailUserDelete}
      />
    </>
  );
};

export default DetailsUsers;

const AddUserModal = ({ isShow, setShow, mailGroupId, refetch }) => {
  const [form] = Form.useForm();
  const [navigation, setNavigation] = useState({
    email: true,
    system: false,
    file: false,
  });

  const { mutate: mutateAddMailUserToMailGroup } = usePostMailUserToMailGroup(
    () => {
      notification.success({
        description: localization(
          "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-mail-user-success"
        ),
      });
      refetch();
    },
    () => {
      notification.error({
        description: localization(
          "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-mail-user-failure"
        ),
      });
    }
  );
  const { mutate: addMailUser } = usePostMailUser();

  const onAddUserToMailGroup = (values) => {
    return addMailUser(
      {
        emailAddress: values.email,
        firstName: values.name,
        lastName: values.surname,
        canSendMail: true,
      },
      {
        onSuccess: (data) => addMailUserToMailGroup(data.id),
        onError: (error) =>
          error.status === 422 && addMailUserToMailGroup(values.email),
      }
    );
  };

  const addMailUserToMailGroup = (data) => {
    mutateAddMailUserToMailGroup(
      {
        mailUser: {
          id: data,
        },
        mailGroupId: mailGroupId,
      },
      {
        onSuccess: () => setShow(false),
      }
    );
  };

  const onFinish = (values) => {
    if (navigation.email) {
      onAddUserToMailGroup(values);
    } else if (navigation.system) {
      notification.error({ description: "This feature is not active" });
    } else {
      notification.error({ description: "This feature is not active" });
    }
  };
  const draggerProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    accept: "image/png, image/jpeg",
    maxCount: 1,

    beforeUpload: (file) => {
      return false;
    },
  };

  const AddWithEmail = () => {
    const [switchButtonVar, setSwitchButtonVar] = useState(0);

    const SwitchButtonComponent = ({ text, switchValue }) => (
      <div
        className={`flex flex-1 justify-center p-2 transition-all delay-75 hover:bg-Grey-100 cursor-pointer ${
          (switchValue === 0 ? !switchButtonVar : switchButtonVar) &&
          "underline bg-Grey-100"
        }`}
        onClick={() => setSwitchButtonVar(switchValue)}
      >
        {text}
      </div>
    );

    const AddWithEmailFormItem = ({ field, label }) => (
      <div className="flex-1">
        <span className="text-Black font-medium mb-1.5">{label}</span>
        <Form.Item name={field} required>
          <Input className="py-2.5 text-Black border-Grey-200 rounded-md" />
        </Form.Item>
      </div>
    );

    return (
      <>
        <Row
          justify="space-between"
          className="border rounded-md border-Grey-100 divide-x divide-Grey-100 mb-4"
        >
          <SwitchButtonComponent
            text={localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-new-user"
            )}
            switchValue={0}
          />
          <SwitchButtonComponent
            text={localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-current-user"
            )}
            switchValue={1}
          />
        </Row>

        {!switchButtonVar ? (
          <>
            <div className="flex flex-col">
              <div className="flex gap-x-4">
                <AddWithEmailFormItem
                  label={localization(
                    "/pages/app/bulletin-pages/management-pages/detail-pages/users/name"
                  )}
                  field={"name"}
                />
                <AddWithEmailFormItem
                  label={localization(
                    "/pages/app/bulletin-pages/management-pages/detail-pages/users/surname"
                  )}
                  field={"surname"}
                />
              </div>
              <div className="">
                <AddWithEmailFormItem
                  label={localization(
                    "/pages/app/bulletin-pages/management-pages/detail-pages/users/email"
                  )}
                  field={"email"}
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            <AddWithEmailFormItem
              label={localization(
                "/pages/app/bulletin-pages/management-pages/detail-pages/users/email"
              )}
              field={"email"}
            />
          </div>
        )}
      </>
    );
  };

  const AddWithSystem = () => {
    return (
      <div className="mb-6">
        <div className="flex items-center w-4/5 p-1 bg-Grey-50 rounded-full text-Grey-700">
          <InfoCircleOutlined className="text-base ml-2 mr-3" />
          Seçtiğiniz bültende ki tüm kullanıcılar bu bültene de eklenir.
        </div>
        <div className="mt-4">
          <div className="text-Black font-medium">Bülten Gruplarından Seç</div>
          <Form.Item
            className="mt-1"
            rules={[
              {
                required: true,
                message: "Group is required",
              },
            ]}
            name={"bulletinId"}
          >
            <Select
              size="large"
              showSearch
              showArrow
              style={{
                width: "100%",
              }}
              placeholder={"Choose a Group"}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const AddWithFile = () => {
    return (
      <Col className="">
        <div className="h-full w-full  border rounded-lg  border-Grey-100 mb-8">
          <Dragger
            {...draggerProps}
            style={{
              padding: "0px",
              width: "100%",
              backgroundColor: "white",
              borderRadius: "8px",
              border: "1px",
            }}
            className="font-Inter"
          >
            <span className="flex flex-col gap-y-2">
              <p className="text-center mx-28 text-Grey-500 ">
                Kurum logosunu değiştirmek için görselini sürükle bırak. ya da
                dosyalarından seç
              </p>
              <div className="flex">
                <h className="mx-auto text-Primary-500 border border-Grey-200 font-Inter rounded-lg text-center py-2 px-4 inline-block shadow-sm">
                  dosya seçin
                </h>
              </div>
              <p className="text-center mx-28 px-9 text-Grey-500">
                Sadece Json ve Excel dosyalarını ekleyebilirsin.
              </p>
            </span>
          </Dragger>
        </div>
      </Col>
    );
  };

  return (
    <Modal
      width={600}
      centered
      open={isShow}
      footer={null}
      onCancel={() => setShow(false)}
    >
      <Row className="flex justify-start items-start">
        <Col className="flex justify-center items-center mr-4 flex-wrap">
          <UserAddOutlined className="text-2xl pt-1 pb-3 px-3 rounded-full bg-Primary-50 text-Primary-500" />
        </Col>
        <Col className="flex flex-col justify-between">
          <div className="font-medium text-base">
            {localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-user-to-bulletin"
            )}
          </div>
          <div className="font-normal text-Grey-600">
            {localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-for-email"
            )}
          </div>
        </Col>
      </Row>
      <Row className="flex justify-center mt-6 gap-2 border border-Grey-100 rounded-md py-3">
        <Button
          size="large"
          className={
            navigation.email
              ? "bg-Primary-50 rounded-md focus:bg-Primary-50 focus:text-Primary-500 focus:border-Primary-500"
              : "rounded-md "
          }
          onClick={() =>
            setNavigation({ email: true, system: false, file: false })
          }
        >
          {localization(
            "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-with-email"
          )}
        </Button>
        <Button
          disabled
          size="large"
          className={
            navigation.system
              ? "bg-Primary-50 rounded-md focus:bg-Primary-50 focus:text-Primary-500 focus:border-Primary-500"
              : "rounded-md "
          }
          onClick={() =>
            setNavigation({ email: false, system: true, file: false })
          }
        >
          {localization(
            "/pages/app/bulletin-pages/management-pages/detail-pages/users/add-from-system"
          )}
        </Button>
        <Button
          disabled
          size="large"
          className={
            navigation.file
              ? "bg-Primary-50 rounded-md focus:bg-Primary-50 focus:text-Primary-500 focus:border-Primary-500"
              : "rounded-md "
          }
          onClick={() =>
            setNavigation({ email: false, system: false, file: true })
          }
        >
          {localization(
            "/pages/app/bulletin-pages/management-pages/detail-pages/users/import-via-file"
          )}
        </Button>
      </Row>
      <Row className="mt-6">
        <Form form={form} className="flex-1" onFinish={onFinish}>
          {navigation.email && <AddWithEmail />}
          {navigation.system && <AddWithSystem />}
          {navigation.file && <AddWithFile />}
          <div className="flex flex-1 justify-end">
            <Form.Item className="m-0">
              <Button
                className="bg-Primary-500 rounded-md"
                size="large"
                type="primary"
                htmlType="submit"
              >
                {localization(
                  "/pages/app/bulletin-pages/management-pages/detail-pages/users/add"
                )}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Row>
    </Modal>
  );
};

const DeleteMailUserModal = ({ isOpen, setOpen, onCancel, onSubmit }) => {
  return (
    <Modal
      centered
      onCancel={() => {
        setOpen(false);
      }}
      open={isOpen}
      setOpen={setOpen}
      footer={null}
      closable={false}
    >
      <div className="flex flex-col">
        <div className="flex items-center mb-6">
          <div className="ml-3 font-Inter flex-1">
            <div className="font-medium text-Black text-base text-center">
              {localization(
                "/pages/app/bulletin-pages/management-pages/detail-pages/users/are-you-sure"
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between ml-3">
          <Button
            onClick={onSubmit}
            className="rounded-lg bg-Primary-500 text-Primary-50 border border-Primary-500 hover:bg-Primary-50 hover:border hover:border-Primary-500 hover:text-Primary-500"
          >
            {localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/submit"
            )}
          </Button>
          <Button
            onClick={onCancel}
            className="rounded-lg bg-Secondary-50 text-Secondary-500 border border-Primary-50 hover:bg-Secondary-500 hover:border hover:border-Secondary-500 hover:text-Primary-50"
          >
            {localization(
              "/pages/app/bulletin-pages/management-pages/detail-pages/users/cancel"
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
