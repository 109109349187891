import React from "react";
import { GGuidesFancyIcon, GGuidesFullIcon } from "../../../icons/gguides-icon";
import { Lock } from "../../../icons/common-icon";
import { Button, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { localization } from "../../../localization/localization";
import { useForm } from "antd/lib/form/Form";
import { useResetPasswordUser } from "../../../hooks/user/user.hooks";

const ResetPasswordPage = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const onResetPasswordSuccess = () => {
    notification.success({
      message: "Success!",
      description: "Password has been successfully changed",
    });
    navigate("/");
  };
  const onResetPasswordError = (e) => {
    notification.error({
      message: "Error!",
      description: e.message,
    });
  };

  const { mutate: mutateResetPassword } = useResetPasswordUser(
    onResetPasswordSuccess,
    onResetPasswordError
  );

  const onSubmit = (formValues) => {
    mutateResetPassword({
      oldPassword: formValues.old_password,
      newPassword: formValues.password_repeat,
    });
  };

  return (
    <>
      <div className="flex h-screen bg-White">
        <div className="m-auto bg-White w-88">
          <div className="flex flex-col">
            <div className="mx-auto mb-6">
              <Lock />
            </div>
            <div className="mx-auto mb-2">
              <span className="text-2xl font-Inter font-bold">
                {localization(
                  "/pages/app/auth-pages/reset-password/set-your-new-password"
                )}
              </span>
            </div>
            <p className="mx-auto mb-6 text-lg font-Inter text-center text-Grey-400">
              {localization(
                "/pages/app/auth-pages/reset-password/new-password-diffrent-you-used-before"
              )}
            </p>
            <Form onFinish={onSubmit} form={form} requiredMark={false}>
              <p className="mb-1 font-bold font-Inter">{"Old Password"}</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter a password",
                  },
                  {
                    required: true,
                    message: "Please enter at least six characters",
                    min: 6,
                  },
                ]}
                name="old_password"
              >
                <Input.Password
                  placeholder={localization(
                    "/pages/app/auth-pages/reset-password/+9-characters-letters-and-numbers"
                  )}
                  className="rounded-md"
                  size="large"
                />
              </Form.Item>
              <p className="mb-1 font-bold font-Inter">
                {localization("/pages/app/auth-pages/reset-password/password")}
              </p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter a password",
                  },
                  {
                    required: true,
                    message: "Please enter at least six characters",
                    min: 6,
                  },
                ]}
                name="password"
              >
                <Input.Password
                  placeholder={localization(
                    "/pages/app/auth-pages/reset-password/+9-characters-letters-and-numbers"
                  )}
                  className="rounded-md"
                  size="large"
                />
              </Form.Item>
              <p className="mb-1 font-bold font-Inter">
                {localization(
                  "/pages/app/auth-pages/reset-password/password-again"
                )}
              </p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter a password",
                  },
                  {
                    required: true,
                    message: "Please enter at least six characters",
                    min: 6,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
                name="password_repeat"
              >
                <Input.Password
                  placeholder={localization(
                    "/pages/app/auth-pages/reset-password/+9-characters-letters-and-numbers"
                  )}
                  className="rounded-md"
                  size="large"
                />
              </Form.Item>
              <div className="my-12 flex flex-col">
                <div className="rounded-md">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="large"
                      className="w-full bg-Primary-500 text-White rounded-md font-Inter"
                    >
                      {localization(
                        "/pages/app/auth-pages/reset-password/reset-password"
                      )}
                    </Button>
                  </Form.Item>
                </div>
                <div className="rounded-md ">
                  <Link to="/login">
                    <Button
                      size="large"
                      className="w-full bg-White text-Primary-500 rounded-md font-Inter"
                    >
                      {localization(
                        "/pages/app/auth-pages/reset-password/back-to-login-sceen"
                      )}
                    </Button>
                  </Link>
                </div>
              </div>
            </Form>

            <div className="mx-auto">
              <GGuidesFullIcon />
            </div>
          </div>
        </div>
      </div>
      <GGuidesFancyIcon />
    </>
  );
};

export default ResetPasswordPage;
