import DatabaseForm from "../../../common/views/app/forms/database/database-form";
import { useAddDatabase } from "../../../hooks/database/database.hooks";
import { SaveOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import {localization} from "../../../localization/localization";

const AddDatabasePage = () => {
  const navigate = useNavigate();
  const openDatabaseCreatedNotification = () => {
    notification.open({
      message: "Database Created",
      description: "Database successfully created !",
      icon: <SaveOutlined />,
    });
  };

  const onSuccess = () => {
    navigate("/app/databases");
    openDatabaseCreatedNotification();
  };

  const onError = (err) => {
    notification.error({
      message:'Error has occured',
      description:err.message
    })
  }

  const { mutate: mutateAddDatabase } = useAddDatabase(onSuccess, onError);
  return (
    <>
      <DatabaseForm
        onSubmitHandler={(body) => {
          mutateAddDatabase(body);
        }}
        formTitle={localization( "/pages/app/database-pages/add-database-page/add-database")}
        formStatement={localization( "/pages/app/database-pages/add-database-page/enter-database-info")}
      />
    </>
  );
};

export default AddDatabasePage;
