import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteGuideSubject,
  getGuideSubjects,
  postGuideSubject,
} from "../../api/guideSubject/guideSubject.api";
import { localization } from "../../localization/localization";

export const useCreateGuideSubject = (onSuccess, onError) => {
  return useMutation(
    "post-guide-subject",
    (body) => {
      return postGuideSubject(body);
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useDeleteGuideSubject = (onSuccess) => {
  return useMutation(
    "delete-guide-subject",
    (id) => {
      return deleteGuideSubject(id);
    },
    {
      onSuccess,
      onError: (error) => {
        notification.error({
          description: localization("/hooks/guide-subject/guide-subject.hook/delete-failed"),
          message: error.message,
        });
      },
    }
  );
};

export const useGetGuideSubjects = (page, size) => {
  return useQuery("get-guide-subjects", () => {
    return getGuideSubjects(page, size);
  });
};
