import GuideInformationBox from "../info-box/guide-box";

const GuideListView = ({ guidesData, isLoading }) => {
  var currentdate = new Date();
  return (
    <>
      {isLoading ? (
        <div>Loading ..</div>
      ) : (
        guidesData.content.map((guide, index) => {
          var createdDate = guide.created.split("T")[0].split("-");
          return (
            <GuideInformationBox
              key={`guide-information-box-${index}`}
              id={guide.id}
              title={guide.name}
              text={
                guide.description
              }
              isNew={
                currentdate.getFullYear() === parseInt(createdDate[0]) &&
                currentdate.getMonth() + 1 === parseInt(createdDate[1])
              }
              authorName={guide.owner.name + " " + guide.owner.surname}
              photoId={
                guide.owner.photo?.id
              }
              tagList={guide.subjects}
            />
          );
        })
      )}
    </>
  );
};

export default GuideListView;
