import * as api from "../api";

export const postDatabaseSubject = (body) => {
  return api.post(`/database-subject`, body);
};

export const deleteDatabaseSubject = (id) => {
  return api.del(`/database-subject/${id}`);
};

export const getDatabaseSubjects = (page, size) => {
  return api.get(`/database-subject?page=${page}&size=${size}`);
};
