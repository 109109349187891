import clsx from "clsx";
import { useState } from "react";

const az = [
  "all",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "#",
];
const AZList = ({ setFirstElement }) => {
  const [selectedChar, setSelectedChar] = useState();

  const onCharClick = (event) => {
    setSelectedChar((prevState) => {
      var selection = "";
      if (prevState !== event.target.outerText) {
        selection = event.target.outerText;
      }
      setFirstElement(selection === "ALL" ? "": selection);
      return selection;
    });
  };
  return (
    <div className="flex flex-row justify-between mb-6">
      {az.map((char) => {
        return (
          <div
            className={clsx(
              selectedChar == char.toUpperCase()
                ? "text-Primary-500 font-Inter font-bold text-lg bg-Secondary-50 cursor-pointer"
                : " font-Inter font-medium text-Grey-500 cursor-pointer",
              "my-auto"
            )}
            onClick={onCharClick}
          >
            {char.toUpperCase()}
          </div>
        );
      })}
    </div>
  );
};

export default AZList;
