import { Spin } from "antd";
import { BadgeSuccess } from "../../../common/components/buttons/badge";
import MainLayout from "../../../common/layouts/main-layout";
import AuthorCard from "../../../common/views/author/author-card";
import InformationBox from "../../../common/views/info-box/information-box";
import { useGetAuthors } from "../../../hooks/user/user.hooks";
import { localization } from "../../../localization/localization";

const pageData = {
  pageName: localization("/pages/public/author-pages/authors/librarians"),
  contentName: localization("/pages/public/author-pages/authors/librarian"),
  placeHolderName: localization(
    "/pages/public/author-pages/authors/search-author"
  ),
  informationText: localization(
    "/pages/public/author-pages/authors/discover-authors"
  ),
};

const AuthorsPage = () => {
  const { data, isLoading } = useGetAuthors(0, 40,"");

  const getSelections = (selectedFilters) => {
    // Not implemented yet.
  };

  return (
    <MainLayout>
      <div className="rounded-md">
        <InformationBox
          searchBarPlaceholder={pageData.placeHolderName}
          title={pageData.pageName}
          text={pageData.informationText}
        >
          <Spin spinning={isLoading}>
            <BadgeSuccess
              leftChildren={data?.totalElements}
              rightChildren={pageData.contentName}
            />
          </Spin>
        </InformationBox>
      </div>

      <div className="mt-4 grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <div className="grid grid-cols-2 gap-6">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              data.content.map((author, index) => {
                return (
                  <AuthorCard
                    key={`author-${index}`}
                    authorName={author.name + " " + author.surname}
                    title={localization(
                      "/pages/public/author-pages/authors/librarian"
                    )}
                    pageLink={`/author-detail/${author.id}`}
                    photoId={author.photo?.id ?? null}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AuthorsPage;
