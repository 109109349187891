import clsx from "clsx";

export const Lock = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className="stroke-Primary-500 fill-Primary-50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" stroke="none" />
      <path
        d="M39.7778 27.3332C39.7778 26.5371 39.474 25.7409 38.8666 25.1334C38.2591 24.526 37.4629 24.2222 36.6667 24.2222M36.6667 36.6667C41.8213 36.6667 46 32.488 46 27.3333C46 22.1787 41.8213 18 36.6667 18C31.512 18 27.3333 22.1787 27.3333 27.3333C27.3333 27.7591 27.3618 28.1781 27.417 28.5887C27.5078 29.2641 27.5532 29.6017 27.5227 29.8154C27.4908 30.0379 27.4503 30.1578 27.3406 30.3541C27.2353 30.5424 27.0498 30.728 26.6787 31.0991L18.729 39.0488C18.4599 39.3178 18.3254 39.4524 18.2292 39.6093C18.1439 39.7485 18.0811 39.9002 18.043 40.059C18 40.238 18 40.4282 18 40.8087V43.5111C18 44.3823 18 44.8179 18.1695 45.1507C18.3187 45.4433 18.5567 45.6813 18.8493 45.8305C19.1821 46 19.6177 46 20.4889 46H24.2222V42.8889H27.3333V39.7778H30.4444L32.9009 37.3213C33.272 36.9502 33.4576 36.7647 33.6459 36.6594C33.8422 36.5497 33.9621 36.5092 34.1846 36.4773C34.3983 36.4468 34.7359 36.4922 35.4113 36.583C35.8219 36.6382 36.2409 36.6667 36.6667 36.6667Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg
      width="34"
      height="28"
      viewBox="0 0 34 28"
      className="stroke-Primary-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.44466 6.22233L14.1456 15.113C15.1741 15.833 15.6884 16.1929 16.2477 16.3324C16.7418 16.4555 17.2586 16.4555 17.7527 16.3324C18.312 16.1929 18.8263 15.833 19.8548 15.113L32.5558 6.22233M8.91132 26.4446H25.0891C27.7027 26.4446 29.0095 26.4446 30.0077 25.9359C30.8858 25.4885 31.5997 24.7746 32.0471 23.8965C32.5558 22.8983 32.5558 21.5915 32.5558 18.9779V9.02233C32.5558 6.40875 32.5558 5.10196 32.0471 4.10371C31.5997 3.22562 30.8858 2.51171 30.0077 2.0643C29.0095 1.55566 27.7027 1.55566 25.0891 1.55566H8.91132C6.29774 1.55566 4.99096 1.55566 3.9927 2.0643C3.11461 2.51171 2.4007 3.22562 1.95329 4.10371C1.44466 5.10196 1.44466 6.40875 1.44466 9.02233V18.9779C1.44466 21.5915 1.44466 22.8983 1.95329 23.8965C2.4007 24.7746 3.11461 25.4885 3.9927 25.9359C4.99096 26.4446 6.29774 26.4446 8.91132 26.4446Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const TickIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className="stroke-Success-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#F2FAF3" />
      <path
        d="M44.4443 22.667L27.3332 39.7781L19.5554 32.0003"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FilterIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7H16M1 1H19M7 13H13"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ExternalAccessIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className="stroke-Warning-700 fill-Warning-100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" stroke="none" />
      <path
        d="M19.8751 19.8754L15.7503 15.7506M15.7503 32.2498L19.8751 28.125M28.1247 28.1249L32.2495 32.2497M32.2495 15.7506L28.1247 19.8754M35.6666 24.0002C35.6666 30.4435 30.4432 35.6668 23.9999 35.6668C17.5566 35.6668 12.3333 30.4435 12.3333 24.0002C12.3333 17.5568 17.5566 12.3335 23.9999 12.3335C30.4432 12.3335 35.6666 17.5568 35.6666 24.0002ZM29.8333 24.0002C29.8333 27.2218 27.2216 29.8335 23.9999 29.8335C20.7783 29.8335 18.1666 27.2218 18.1666 24.0002C18.1666 20.7785 20.7783 18.1668 23.9999 18.1668C27.2216 18.1668 29.8333 20.7785 29.8333 24.0002Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const DatabaseIcon = ({ iconColor, backgroundColor, strokeColor }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className={clsx("fill-Primary-500", iconColor)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="48"
        height="48"
        rx="24"
        className={clsx("fill-Primary-500", backgroundColor)}
      />
      <path
        d="M34.5 15.8335C34.5 17.7665 29.799 19.3335 24 19.3335C18.201 19.3335 13.5 17.7665 13.5 15.8335M34.5 15.8335C34.5 13.9005 29.799 12.3335 24 12.3335C18.201 12.3335 13.5 13.9005 13.5 15.8335M34.5 15.8335V32.1668C34.5 34.1035 29.8333 35.6668 24 35.6668C18.1667 35.6668 13.5 34.1035 13.5 32.1668V15.8335M34.5 24.0002C34.5 25.9368 29.8333 27.5002 24 27.5002C18.1667 27.5002 13.5 25.9368 13.5 24.0002"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={clsx("stroke-Primary-500", strokeColor)}
      />
    </svg>
  );
};

export const ExternalAccessButtonIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="stroke-Primary-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.50001L16.5 1.50001M16.5 1.50001H11.5M16.5 1.50001L9 9M7.33333 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V10.6667"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LoginPageIcon = () => {
  return (
    <svg
      width="420"
      height="448"
      viewBox="0 0 420 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M355.267 135.964C377.859 149.016 396.179 134.208 396.237 102.895C396.237 71.5828 377.918 35.5878 355.326 22.536C332.734 9.48412 314.415 24.2918 314.356 55.6045C314.356 62.5109 315.234 69.5928 316.873 76.6162L233.587 107.695C226.212 93.4724 216.087 81.4741 204.966 75.0359C182.375 61.9841 164.055 76.7918 163.997 108.104C163.997 139.417 182.316 175.412 204.908 188.464C215.619 194.668 225.393 194.551 232.709 189.459L314.356 314.183C314.356 314.944 314.298 315.646 314.298 316.407C314.298 347.72 332.617 383.715 355.209 396.767C377.801 409.818 396.12 395.011 396.179 363.698C396.179 332.385 377.859 296.39 355.267 283.339C340.401 274.793 327.408 278.247 320.209 290.479L243.478 173.247C245.058 168.155 245.878 162.185 245.878 155.395C245.878 149.191 245.175 142.87 243.829 136.549L327.701 105.237C334.958 118.64 344.674 129.819 355.267 135.964Z"
        fill="url(#paint0_linear_296_23061)"
      />
      <path
        opacity="0.07"
        d="M419.414 85.103C419.414 116.416 401.095 131.223 378.445 118.172C355.853 105.12 337.533 69.1833 337.533 37.812C337.533 6.49933 355.853 -8.30836 378.503 4.74348C401.095 17.7953 419.414 53.7903 419.414 85.103Z"
        fill="url(#paint1_linear_296_23061)"
      />
      <path
        opacity="0.07"
        d="M268.996 136.608C268.996 167.921 250.677 182.728 228.027 169.676C205.435 156.625 187.115 120.63 187.115 89.3169C187.115 58.0042 205.435 43.1965 228.085 56.2484C250.677 69.3002 268.996 105.295 268.996 136.608Z"
        fill="url(#paint2_linear_296_23061)"
      />
      <path
        opacity="0.07"
        d="M420 346.14C420 377.453 401.681 392.26 379.03 379.209C356.438 366.157 338.119 330.162 338.119 298.849C338.119 267.536 356.438 252.729 379.089 265.781C401.681 278.832 420 314.827 420 346.14Z"
        fill="url(#paint3_linear_296_23061)"
      />
      <path
        opacity="0.2"
        d="M73.5703 249.334L189.808 316.466L189.866 419.944L73.6288 352.812L73.5703 249.334Z"
        fill="url(#paint4_linear_296_23061)"
      />
      <path
        opacity="0.15"
        d="M73.5703 249.334L131.748 344.969L189.866 316.408L73.5703 249.334Z"
        fill="url(#paint5_linear_296_23061)"
      />
      <path
        d="M141.756 347.194C141.756 354.51 137.367 357.846 131.865 354.685C126.422 351.525 121.974 343.038 121.974 335.781C121.974 328.464 126.363 325.128 131.865 328.289C137.367 331.449 141.756 339.936 141.756 347.194Z"
        fill="#64BEF9"
      />
      <path
        opacity="0.3"
        d="M192.676 198.823L18.5537 98.3887L18.6122 186.942L177.4 278.539L192.793 300.956L192.676 198.823Z"
        fill="url(#paint6_linear_296_23061)"
      />
      <path
        d="M0 132.687V108.632L65.7274 146.558V170.613L0 132.687Z"
        fill="#64BEF9"
      />
      <path
        opacity="0.15"
        d="M147.901 194.552L109.741 172.545V180.329L147.901 202.336V194.552Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M147.96 210.12L74.3901 167.687V175.471L147.96 217.904V210.12Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M147.96 225.689L58.8213 174.301V182.085L147.96 233.473V225.689Z"
        fill="white"
      />
      <path
        d="M157.324 208.071C157.324 215.387 161.772 223.815 167.216 226.976C172.659 230.136 177.107 226.741 177.107 219.484C177.107 212.168 172.659 203.74 167.216 200.579C161.714 197.419 157.324 200.813 157.324 208.071Z"
        fill="#64BEF9"
      />
      <path
        opacity="0.3"
        d="M208.479 258.055L382.601 358.489L382.659 447.043L223.871 355.446L208.537 360.128L208.479 258.055Z"
        fill="url(#paint7_linear_296_23061)"
      />
      <path
        d="M331.973 373.414V349.358L397.701 387.285V411.34L331.973 373.414Z"
        fill="#B2DAFB"
      />
      <path
        opacity="0.15"
        d="M253.252 305.404L291.413 327.411V335.195L253.252 313.189V305.404Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M253.252 320.973L322.14 360.713V368.498L253.252 328.815V320.973Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M253.252 336.6L342.391 387.988V395.772L253.252 344.384V336.6Z"
        fill="white"
      />
      <path
        d="M243.888 308.096C243.888 315.412 239.498 318.748 233.997 315.588C228.554 312.427 224.105 303.94 224.105 296.683C224.105 289.367 228.495 286.031 233.997 289.191C239.44 292.352 243.83 300.838 243.888 308.096Z"
        fill="#B2DAFB"
      />
      <path
        opacity="0.2"
        d="M114.072 79.9525L89.022 65.496V57.0679L114.072 71.5244C116.121 72.695 119.106 75.0946 121.622 78.7819C124.373 82.7033 126.305 87.6782 126.305 93.0628C126.305 99.0327 124.783 102.427 121.915 103.364C119.223 104.242 116.004 102.72 114.072 101.608L103.771 95.6381L103.713 95.5796C102.835 95.0528 101.43 94.5261 100.318 94.8772C99.3815 95.1699 98.3865 96.0478 98.3865 99.3839C98.3865 102.72 99.44 104.71 100.259 105.939C101.313 107.461 102.718 108.514 103.596 108.983L103.713 109.041L135.201 127.243V135.672L103.888 117.586C101.723 116.474 98.6206 114.075 95.9283 110.329C93.0019 106.173 91.0119 100.964 91.0119 95.1699C91.0119 89.3756 93.0019 86.4491 95.8698 85.6297C98.5621 84.8689 101.664 85.9809 103.83 87.2685L114.014 93.1799C115.477 94.0578 116.589 94.409 117.35 94.1749C117.935 93.9993 118.989 93.1799 118.989 88.9073C118.989 86.098 118.111 84.2836 117.233 83.113C116.238 81.5327 114.95 80.4207 114.072 79.9525Z"
        fill="white"
      />
      <path
        d="M93.6454 63.6818C93.6454 71.232 89.1972 74.8022 83.7541 71.7002C78.3109 68.5397 73.8628 59.8775 73.8628 52.2688C73.8628 44.7186 78.3109 41.1484 83.7541 44.2504C89.1972 47.4109 93.6454 56.1317 93.6454 63.6818Z"
        fill="#64BEF9"
      />
      <path
        d="M148.077 138.598C148.077 146.148 143.629 149.719 138.186 146.617C132.743 143.456 128.294 134.794 128.294 127.185C128.294 119.635 132.743 116.065 138.186 119.167C143.629 122.327 148.077 131.048 148.077 138.598Z"
        fill="#B2DAFB"
      />
      <path
        opacity="0.2"
        d="M197.709 423.631C166.747 383.773 189.164 369.434 197.709 392.728C206.254 379.267 228.67 419.534 197.709 423.631Z"
        fill="url(#paint8_linear_296_23061)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_296_23061"
          x1="415.75"
          y1="97.534"
          x2="250.905"
          y2="265.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_296_23061"
          x1="398.966"
          y1="40.5547"
          x2="234.119"
          y2="208.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_296_23061"
          x1="248.548"
          y1="92.0597"
          x2="83.7015"
          y2="260.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_296_23061"
          x1="399.551"
          y1="301.592"
          x2="234.705"
          y2="469.769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_296_23061"
          x1="140.676"
          y1="261.451"
          x2="128.172"
          y2="363.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_296_23061"
          x1="130.39"
          y1="253.971"
          x2="135.37"
          y2="363.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_296_23061"
          x1="153.671"
          y1="161.539"
          x2="65.6635"
          y2="231.411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_296_23061"
          x1="285.151"
          y1="261.715"
          x2="304.246"
          y2="440.585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_296_23061"
          x1="204.256"
          y1="394.24"
          x2="181.347"
          y2="414.031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3F9FE" />
          <stop offset="1" stop-color="#F3F9FE" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const TrashIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="14" fill="#FCF2F2" />
      <path
        d="M17.3333 9.00033V8.33366C17.3333 7.40024 17.3333 6.93353 17.1517 6.57701C16.9919 6.2634 16.7369 6.00844 16.4233 5.84865C16.0668 5.66699 15.6001 5.66699 14.6667 5.66699H13.3333C12.3999 5.66699 11.9332 5.66699 11.5767 5.84865C11.2631 6.00844 11.0081 6.2634 10.8483 6.57701C10.6667 6.93353 10.6667 7.40024 10.6667 8.33366V9.00033M6.5 9.00033H21.5M19.8333 9.00033V18.3337C19.8333 19.7338 19.8333 20.4339 19.5608 20.9686C19.3212 21.439 18.9387 21.8215 18.4683 22.0612C17.9335 22.3337 17.2335 22.3337 15.8333 22.3337H12.1667C10.7665 22.3337 10.0665 22.3337 9.53169 22.0612C9.06129 21.8215 8.67883 21.439 8.43915 20.9686C8.16667 20.4339 8.16667 19.7338 8.16667 18.3337V9.00033"
        stroke="#CD192B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const VisibilityIcon = () => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      className="stroke-Primary-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82348 10.8317C1.66459 10.5801 1.58515 10.4543 1.54068 10.2603C1.50727 10.1146 1.50727 9.88476 1.54068 9.73902C1.58515 9.545 1.66459 9.41921 1.82348 9.16763C3.13645 7.08866 7.04463 1.83301 13.0005 1.83301C18.9563 1.83301 22.8645 7.08865 24.1775 9.16763C24.3364 9.41921 24.4158 9.545 24.4603 9.73902C24.4937 9.88476 24.4937 10.1146 24.4603 10.2603C24.4158 10.4543 24.3364 10.5801 24.1775 10.8317C22.8645 12.9107 18.9563 18.1663 13.0005 18.1663C7.04463 18.1663 3.13645 12.9107 1.82348 10.8317Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0005 13.4997C14.9335 13.4997 16.5005 11.9327 16.5005 9.99967C16.5005 8.06668 14.9335 6.49967 13.0005 6.49967C11.0675 6.49967 9.50047 8.06668 9.50047 9.99967C9.50047 11.9327 11.0675 13.4997 13.0005 13.4997Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const HeartIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      className="stroke-Primary-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7963 1.5C21.9056 1.5 24.6667 5.41125 24.6667 9.06C24.6667 16.4494 13.2074 22.5 13 22.5C12.7926 22.5 1.33337 16.4494 1.33337 9.06C1.33337 5.41125 4.09449 1.5 8.20374 1.5C10.563 1.5 12.1056 2.69437 13 3.74437C13.8945 2.69437 15.4371 1.5 17.7963 1.5Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AddGuideIcon = ({ className }) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      className={`${className ? className : "stroke-Primary-50"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6668 8.74984V5.6665C14.6668 4.26637 14.6668 3.56631 14.3943 3.03153C14.1547 2.56112 13.7722 2.17867 13.3018 1.93899C12.767 1.6665 12.067 1.6665 10.6668 1.6665H5.3335C3.93336 1.6665 3.2333 1.6665 2.69852 1.93899C2.22811 2.17867 1.84566 2.56112 1.60598 3.03153C1.3335 3.56631 1.3335 4.26637 1.3335 5.6665V14.3332C1.3335 15.7333 1.3335 16.4334 1.60598 16.9681C1.84566 17.4386 2.22811 17.821 2.69852 18.0607C3.2333 18.3332 3.93336 18.3332 5.3335 18.3332H8.00016M9.66683 9.1665H4.66683M6.3335 12.4998H4.66683M11.3335 5.83317H4.66683M13.0002 17.4998V12.4998M10.5002 14.9998H15.5002"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AddDatabaseIcon = ({ width, height, className }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 20"
        className={`${className ? className : "stroke-Primary-50"}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 4.1665C16.5 5.54722 13.1421 6.6665 9 6.6665C4.85786 6.6665 1.5 5.54722 1.5 4.1665M16.5 4.1665C16.5 2.78579 13.1421 1.6665 9 1.6665C4.85786 1.6665 1.5 2.78579 1.5 4.1665M16.5 4.1665V15.8332C16.5 17.2165 13.1667 18.3332 9 18.3332C4.83333 18.3332 1.5 17.2165 1.5 15.8332V4.1665M16.5 9.99984C16.5 11.3832 13.1667 12.4998 9 12.4998C4.83333 12.4998 1.5 11.3832 1.5 9.99984"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export const AddLibrarianIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      className={`${className ? className : "stroke-Primary-50"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8337 16.5L18.3337 14M18.3337 14L15.8337 11.5M18.3337 14H13.3337M10.0003 11.9167H6.25033C5.08736 11.9167 4.50587 11.9167 4.03271 12.0602C2.96737 12.3834 2.13369 13.217 1.81053 14.2824C1.66699 14.7555 1.66699 15.337 1.66699 16.5M12.0837 5.25C12.0837 7.32107 10.4047 9 8.33366 9C6.26259 9 4.58366 7.32107 4.58366 5.25C4.58366 3.17893 6.26259 1.5 8.33366 1.5C10.4047 1.5 12.0837 3.17893 12.0837 5.25Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const EditIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 23 23"
      className={className ? className : "stroke-Grey-600"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.00023H5.8C4.11984 4.00023 3.27976 4.00023 2.63803 4.32721C2.07354 4.61483 1.6146 5.07377 1.32698 5.63826C1 6.27999 1 7.12007 1 8.80023V17.2002C1 18.8804 1 19.7205 1.32698 20.3622C1.6146 20.9267 2.07354 21.3856 2.63803 21.6732C3.27976 22.0002 4.11984 22.0002 5.8 22.0002H14.2C15.8802 22.0002 16.7202 22.0002 17.362 21.6732C17.9265 21.3856 18.3854 20.9267 18.673 20.3622C19 19.7205 19 18.8804 19 17.2002V13.0002M6.99997 16.0002H8.67452C9.1637 16.0002 9.40829 16.0002 9.63846 15.945C9.84254 15.896 10.0376 15.8152 10.2166 15.7055C10.4184 15.5818 10.5914 15.4089 10.9373 15.063L20.5 5.50023C21.3284 4.6718 21.3284 3.32865 20.5 2.50023C19.6716 1.6718 18.3284 1.6718 17.5 2.50022L7.93723 12.063C7.59133 12.4089 7.41838 12.5818 7.29469 12.7837C7.18504 12.9626 7.10423 13.1577 7.05523 13.3618C6.99997 13.5919 6.99997 13.8365 6.99997 14.3257V16.0002Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LockIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      className="stroke-Grey-600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8V6C14 3.23858 11.7614 1 9 1C6.23858 1 4 3.23858 4 6V8M9 12.5V14.5M5.8 19H12.2C13.8802 19 14.7202 19 15.362 18.673C15.9265 18.3854 16.3854 17.9265 16.673 17.362C17 16.7202 17 15.8802 17 14.2V12.8C17 11.1198 17 10.2798 16.673 9.63803C16.3854 9.07354 15.9265 8.6146 15.362 8.32698C14.7202 8 13.8802 8 12.2 8H5.8C4.11984 8 3.27976 8 2.63803 8.32698C2.07354 8.6146 1.6146 9.07354 1.32698 9.63803C1 10.2798 1 11.1198 1 12.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LogOutIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="stroke-Grey-600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 15L19 10M19 10L14 5M19 10H7M7 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19H7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const HashtagIcon = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      className="stroke-Primary-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66663 7.33333H20.3333M1.66663 16.6667H20.3333M6.33329 1.5V22.5M15.6666 1.5V22.5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      className="stroke-Grey-500 cursor-pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1L1 7M1 1L7 7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PlusSign = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99996 1.16699V12.8337M1.16663 7.00033H12.8333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SaveIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="stroke-Primary-50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83333 1.5V4.33333C4.83333 4.80004 4.83333 5.0334 4.92416 5.21166C5.00406 5.36846 5.13154 5.49594 5.28834 5.57584C5.4666 5.66667 5.69996 5.66667 6.16667 5.66667H11.8333C12.3 5.66667 12.5334 5.66667 12.7117 5.57584C12.8685 5.49594 12.9959 5.36846 13.0758 5.21166C13.1667 5.0334 13.1667 4.80004 13.1667 4.33333V2.33333M13.1667 16.5V11.1667C13.1667 10.7 13.1667 10.4666 13.0758 10.2883C12.9959 10.1315 12.8685 10.0041 12.7117 9.92416C12.5334 9.83333 12.3 9.83333 11.8333 9.83333H6.16667C5.69996 9.83333 5.4666 9.83333 5.28834 9.92416C5.13154 10.0041 5.00406 10.1315 4.92416 10.2883C4.83333 10.4666 4.83333 10.7 4.83333 11.1667V16.5M16.5 6.77124V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V5.5C1.5 4.09987 1.5 3.3998 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.3998 1.5 4.09987 1.5 5.5 1.5H11.2288C11.6364 1.5 11.8402 1.5 12.0321 1.54605C12.2021 1.58688 12.3647 1.65422 12.5138 1.7456C12.682 1.84867 12.8261 1.9928 13.1144 2.28105L15.719 4.88562C16.0072 5.17387 16.1513 5.318 16.2544 5.48619C16.3458 5.63531 16.4131 5.79789 16.4539 5.96795C16.5 6.15976 16.5 6.36358 16.5 6.77124Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UpgradeUserIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      className="stroke-Grey-600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 16L18 13M18 13L21 16M18 13V19M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5C17 6.67869 15.9659 8.11585 14.5 8.70924M11 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const NewRowIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className="stroke-Primary-500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#F1F2F3" />
      <path
        d="M30.7667 21.6667C32.0735 21.6667 32.7268 21.6667 33.226 21.4123C33.665 21.1886 34.022 20.8317 34.2457 20.3926C34.5 19.8935 34.5 19.2401 34.5 17.9333V17.2333C34.5 15.9265 34.5 15.2732 34.2457 14.774C34.022 14.335 33.665 13.978 33.226 13.7543C32.7269 13.5 32.0735 13.5 30.7667 13.5L17.2333 13.5C15.9265 13.5 15.2731 13.5 14.774 13.7543C14.335 13.978 13.978 14.335 13.7543 14.774C13.5 15.2731 13.5 15.9265 13.5 17.2333L13.5 17.9333C13.5 19.2401 13.5 19.8935 13.7543 20.3926C13.978 20.8317 14.335 21.1886 14.774 21.4123C15.2731 21.6667 15.9265 21.6667 17.2333 21.6667L30.7667 21.6667Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.7667 34.5C32.0735 34.5 32.7268 34.5 33.226 34.2457C33.665 34.022 34.022 33.665 34.2457 33.226C34.5 32.7269 34.5 32.0735 34.5 30.7667V30.0667C34.5 28.7599 34.5 28.1065 34.2457 27.6074C34.022 27.1683 33.665 26.8114 33.226 26.5877C32.7269 26.3333 32.0735 26.3333 30.7667 26.3333L17.2333 26.3333C15.9265 26.3333 15.2731 26.3333 14.774 26.5877C14.335 26.8114 13.978 27.1683 13.7543 27.6074C13.5 28.1065 13.5 28.7599 13.5 30.0667L13.5 30.7667C13.5 32.0735 13.5 32.7268 13.7543 33.226C13.978 33.665 14.335 34.022 14.774 34.2457C15.2731 34.5 15.9265 34.5 17.2333 34.5H30.7667Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const VerticalDotsIcon = () => {
  return (
    <div className="flex justify-center">
      <svg
        width="4"
        height="18"
        viewBox="0 0 4 18"
        className="stroke-Primary-500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export const DotIcon = () => {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      className="fill-Success-600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="3" />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      class="bi bi-info"
      className="fill-Secondary-700"
      viewBox="0 0 16 16"
    >
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
    </svg>
  );
};
