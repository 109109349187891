import React, { Component } from "react";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css";
import grapesjs from "grapesjs";
import "grapesjs-preset-newsletter";
import axios from "axios";
import { Button, Row } from "antd";
import { localization } from "../../localization/localization";
import en from 'grapesjs/locale/en';
import tr from 'grapesjs/locale/tr';

class MailEditor extends Component {
  constructor({ props }) {
    super();

    this.state = {
      token: window.localStorage.getItem("token"),
      props: props,
    };
    this.editorId = String(Math.floor(Math.random() * 1000));
  }

  fetchFromRemote() {
    return axios
      .get(
        `${this.state.props.url}`,
        this.state.token
          ? {
              headers: { Authorization: `Bearer ${this.state.token}` },
            }
          : null
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error.response ? error.response.data : error;
      });
  }

  sendToRemote(body) {
    axios
      .put(
        `${this.state.props.url}`,
        body,
        this.state.token
          ? {
              headers: { Authorization: `Bearer ${this.state.token}` },
            }
          : null
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error.response ? error.response.data : error;
      });
  }
  sendToRemoteImage(body) {
    return axios
      .post(
        `${window._env_.API_URL}/file`,
        body,
        this.state.token
          ? {
              headers: { Authorization: `Bearer ${this.state.token}` },
            }
          : null
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error.response ? error.response.data : error;
      });
  }

  componentDidMount() {
    var nlPlugin = require('grapesjs-preset-newsletter'); 
    this.state.editor = grapesjs.init({
      container: `#editor-${this.editorId}`,
      plugins: ["gjs-preset-newsletter", nlPlugin.default],
      noticeOnUnload: 0,

      i18n: {
        messages: { en, tr },
      },
      storageManager: {
        autoload: false,
        autosave: false,
        recovery: false,
        options: {
          local: false,
        },
        onStore: () => {
          this.sendToRemote({
            html: this.state.editor.getHtml(),
            design: JSON.stringify(this.state.editor.getProjectData()),
          });
        },
        onLoad: () => {
          console.log("Editor Selection", this.editorId);
          console.log("Editor props", this.props);
          console.log("Editor data", this.props.projectData);
          this.props.projectData
            ? this.state.editor.loadProjectData(
                JSON.parse(this.props.projectData)
              )
            : this.fetchFromRemote().then((response) => {
                this.state.editor.loadProjectData(JSON.parse(response.design));
              });
        },
      },
      assetManager: {
        upload: `${window._env_API_URL}/file`,
        multiUpload: false,
        headers: { Authorization: `Bearer ${this.state.token}` },
        uploadFile: (e) => {
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          var formData = new FormData();
          formData.append("file", files[0]);
          this.sendToRemoteImage(formData).then((response) => {
            this.state.editor.AssetManager.add(`${response.url}`);
          });
        },
      },
    });
    this.state.editor.load();
    this.state.editor.on("asset:add", (file) => {});

    this.state.editor.BlockManager.add("my-first-block", {
      label: "Simple block",
      content: `<div class="my-block margin-only" 
            style="box-sizing: border-box; height: 100px; margin: 10vh 0 0 0; text-align: center;">
            This is a simple block 2</div>`,
    });
    this.setState({ ...this.state });
  }

  render() {
    return (
      <>
        <div id={`editor-${this.editorId}`}></div>
        <Row className="mt-4">
          <Button
            onClick={() => {
              this.props.getEditor
                ? this.props.getEditor(this.state.editor)
                : this.state.editor.store();
            }}
            className="text-right bg-Primary1-500 text-White rounded-md-400 mb-12 border-none cursor-default inline border"
          >
            {localization("/pages/app/mail-editor/save-changes")}
          </Button>
        </Row>
      </>
    );
  }
}

export default MailEditor;
