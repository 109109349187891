import { useGetMailGroup } from "../../../../hooks/mailGroup/mailGroup.hook";
import MailEditor from "../../mail-editor";

const BulletinMailCreator = ({ id, type }) => {
  const {
    data: mailGroupInformation,
    isLoading: isMailGroupInformationLoding,
  } = useGetMailGroup(id);
  var templateId = mailGroupInformation ? mailGroupInformation[type]?.id : "";
  var template = mailGroupInformation ? mailGroupInformation[type] : null;
  return (
    <>
      {isMailGroupInformationLoding ? (
        <div></div>
      ) : (
        <>
          <MailEditor
            props={{
              url: `${window._env_.API_URL}/mail-template/${templateId}`,
            }}
            projectData={mailGroupInformation[type] ? template.design : null}
          />
        </>
      )}
    </>
  );
};

export default BulletinMailCreator;
