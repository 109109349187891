import AppNavbar from "../components/navbar/AppNavbar";

const AppLayout = ({ children }) => {
  return (
    <>
      <AppNavbar />
      <div>
        <div className="relative px-11 py-8">{children}</div>
      </div>
    </>
  );
};

export default AppLayout;
