import { Row } from "antd";
import React from "react";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";
import Bulletins from "./managemenet-pages/bulletins";
import Users from "./managemenet-pages/users";
import { localization } from "../../../localization/localization";

const BulletinManagement = () => {
  return (
    <div>
      <Row className="px-4 py-3 bg-White gap-2 rounded-md">
        <NavLink
          to={"bulletins"}
          className={({ isActive }) =>
            isActive
              ? "bg-Primary-50 rounded-md text-Primary-500 hover:text-Primary-600"
              : " text-Primary-500 hover:text-Primary-600"
          }
        >
          <div className="py-2 px-3 hover:bg-Primary-50 rounded-md">
            {localization(
              "/pages/app/bulletin-pages/bulletin-management/bulletins"
            )}
          </div>
        </NavLink>
        <NavLink
          to={"users"}
          className={({ isActive }) =>
            isActive
              ? "bg-Primary-50 rounded-md text-Primary-500 hover:text-Primary-600"
              : " text-Primary-500 hover:text-Primary-600"
          }
        >
          <div className="py-2 px-3 hover:bg-Primary-50 rounded-md">
            {localization(
              "/pages/app/bulletin-pages/bulletin-management/users"
            )}
          </div>
        </NavLink>
      </Row>

      <Routes>
        <Route
          exact
          path="bulletins"
          element={<Bulletins />}
        />
        <Route path="users" element={<Users />} />
        <Route
          path="*"
          element={<Navigate to={"/app/bulletin-management/bulletins"} />}
        />
      </Routes>
    </div>
  );
};

export default BulletinManagement;
