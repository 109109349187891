import { Col, Row } from 'antd'

import { BadgeSuccess } from '../../../common/components/buttons/badge'
import { GuideFilterView } from '../../../common/views/filter-view/filter-view'
import GuideListView from '../../../common/views/guide/guide-list-view'
import InformationBox from '../../../common/views/info-box/information-box'
import MainLayout from '../../../common/layouts/main-layout'
import { useGetFilteredPublicGuides } from '../../../hooks/guide/guide.hooks'
import { useGetTenantDetail } from '../../../hooks/tenant/tenant.hooks'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { localization } from '../../../localization/localization'
const GuidesPage = () => {
	const [filterList, setFilterList] = useState([])
	const [searchKey, setSearchKey] = useState('')
	const getSelections = (selectedFilters) => {
		setFilterList(selectedFilters)
	}

	const { id: categoryIds } = useParams()

	const { data: guidesData, isLoading } = useGetFilteredPublicGuides(
		0,
		40,
		searchKey,
		filterList,
		categoryIds
	)
	const { data: tenantDetailSettings, isLoading: isTenantDetailLoading } =
		useGetTenantDetail()

	return (
		<MainLayout>
			<div className='rounded-md mb-6'>
				{!(isTenantDetailLoading || isLoading) && (
					<InformationBox
						setSearchKey={setSearchKey}
						title={localization("/pages/public/guide-pages/guides/guides-page")}
						text={tenantDetailSettings.guideDescription}
						searchBarPlaceholder={localization("/pages/public/guide-pages/guides/search-guide")}
					>
						<BadgeSuccess
							leftChildren={guidesData.totalElements}
							rightChildren={localization("/pages/public/guide-pages/guides/guide")}
						/>
					</InformationBox>
				)}
			</div>

			<Row gutter={24}>
				<Col span={18}>
					<GuideListView
						guidesData={guidesData}
						isLoading={isLoading}
					/>
				</Col>
				<Col span={6}>
					<GuideFilterView getSelections={getSelections} />
				</Col>
			</Row>
		</MainLayout>
	)
}

export default GuidesPage
