import clsx from "clsx";

const PageButton = ({ info, selected, onClick }) => {
  return (
    <div
      className={clsx(
        "h-10 font-Inter text-Primary-500  inline-block rounded-lg",
        selected && "bg-Primary-50 text-Primary-500"
      )}
    >
      <button onClick={onClick} className="px-4 py-2 ">
        {info}
      </button>
    </div>
  );
};

export default PageButton;
