import React, { useEffect, useState } from "react";
import { Button,Spin } from "antd";
import noImage from "../../../assets/categories/no-image.jpg"
import { Link } from "react-router-dom";
import { localization } from "../../../localization/localization";
import { useDownloadFile } from "../../../hooks/file/file.hooks";

const EditCategoryCard = ({ category, firstLevelCategories }) => {
  const [image, setImage] = useState(null);
  const { mutate: downloadImage, isLoading: isDownloading } = useDownloadFile();

  useEffect(() => {
    if (category) {
      downloadImage(category.photo.id, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setImage(bufferObject);
        },
        onError: () => {
          setImage(null);
        },
      });
    }
  }, [category]);

  return (
    <div className="p-4 rounded-md bg-White sm:flex items-start gap-4 font-Inter">
      <Spin spinning={isDownloading}>
        <div className="h-44 w-44">
          <img
            className="rounded-md object-fill h-full w-full"
            src={image || noImage}
            alt="category_photo"
          />
        </div>
      </Spin>

      <div className="sm:flex sm:flex-col  justify-between h-full gap-8 flex-1">
        <div>
          <p className="font-semibold text-base text-Black mt-1.5 sm:my-3 sm:mt-0">
            {category.name}
          </p>
          <div className="flex flex-wrap gap-2 sm:mb-0 mb-2">
            {firstLevelCategories.map((category) => {
              return (
                <div className="py-0.5 px-2 rounded-2xl bg-Grey-50">
                  <p className="text-xs font-medium text-Grey-700">
                    {category?.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <Link to={`${category.id}`}>
          <Button
            block
            size="large"
            className="border-1  rounded-lg text-Primary-500 hover:text-Primary-50 hover:bg-Primary-500 border-Grey-200 hover:border-Grey-200 
                                      shadow-sm shadow-Primary-500/5 "
          >
            {localization(
              "/common/components/cards/edit-category-card/edit-category"
            )}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default EditCategoryCard;
