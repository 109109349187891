import React from "react";
import { GGuidesFancyIcon, GGuidesFullIcon } from "../../../icons/gguides-icon";
import { TickIcon } from "../../../icons/common-icon";
import { localization } from "../../../localization/localization";

const PasswordChangedPage = () => {
  return (
    <>
      <div className="flex h-screen bg-White">
        <div className="mx-auto mt-24 bg-White w-88">
          <div className="flex flex-col">
            <div className="mx-auto mb-6">
              <TickIcon />
            </div>
            <div className="mx-auto mb-3">
              <span className="text-lg font-Inter">
                {localization("/pages/public/auth-pages/password-changed/password-changed")}
              </span>
            </div>
            <div className="mx-auto mb-6 text-lg font-Inter text-center text-Grey-400">
              {localization("/pages/public/auth-pages/password-changed/login-with-new-password")}
            </div>
            <div className="bg-Success-600 rounded-md mb-6">
              <button className="w-full h-12 text-Success-50">
                {localization("/pages/public/auth-pages/password-changed/return-to-login")}
              </button>
            </div>
            <div className="mx-auto">
              <GGuidesFullIcon />
            </div>
          </div>
        </div>
      </div>

      <GGuidesFancyIcon />
    </>
  );
};

export default PasswordChangedPage;
