import React, { useState } from "react";

import { Button, Col, Modal, Row, Select, Form, notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { localization } from "../../../../localization/localization";
import { useGetCategories } from "../../../../hooks/category/category.hooks";
import { useNavigate } from "react-router-dom";
import {
  useGetMailGroups,
  usePostMailGroup,
} from "../../../../hooks/mailGroup/mailGroup.hook";

const Bulletins = () => {
  const {
    data: mailGroups,
    refetch: refetchMailGroups,
    isLoading: isMailGroupsLoading,
  } = useGetMailGroups();

  return (
    <div className="mt-6">
      <Row>
        <Col span={24} flex>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <NewBulletinContainer refetchMailGroups={refetchMailGroups} />
            </Col>
          </Row>
          <Row className="mt-8" gutter={[24, 24]}>
            <BulletinsView
              mailGroups={mailGroups}
              isMailGroupsLoading={isMailGroupsLoading}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Bulletins;

const BulletinsView = ({ mailGroups, isMailGroupsLoading }) => {
  const navigate = useNavigate();
  const badgeList = {
    content: mailGroups?.map((mailGroup) => {
      var mailUserList = mailGroup?.mailUserList;
      return mailUserList.map((mailUser) => {
        return mailUser?.firstName + " " + mailUser?.lastName;
      });
    }),
  };

  const bulletinGroupNames = mailGroups?.map((mailGroup) => {
    return mailGroup?.category?.name;
  });
  const onBulletinDetailClick = (id) => {
    navigate(`${id}`);
  };
  return (
    <>
      {!isMailGroupsLoading &&
        bulletinGroupNames.map((groupName, idx) => (
          <Col span={12}>
            <BulletinCard
              title={groupName}
              badgeList={badgeList.content[idx]}
              buttonTitle={localization(
                "/pages/app/bulletin-pages/bulletin-management/bulletin-detail"
              )}
              buttonOnClick={() => onBulletinDetailClick(mailGroups[idx].id)}
            />
          </Col>
        ))}
    </>
  );
};

const NewBulletinContainer = ({ refetchMailGroups }) => {
  const [isNewBulletinGroupModalOpen, setNewBulletinGroupModalOpen] =
    useState(false);

  const { mutate: mutatePostMailGroup } = usePostMailGroup(
    () => {
      notification.success({
        description: "Mail Group Successfully Added!",
      });
      refetchMailGroups();
      setNewBulletinGroupModalOpen(false);
    },
    (err) => {
      notification.error({
        message: "Failed !",
        description: err.message,
      });
      setNewBulletinGroupModalOpen(false);
    }
  );

  const handleNewBulletinGroup = (values) => {
    mutatePostMailGroup({
      categoryId: values.categoryId,
      databaseMailTemplateId: null,
      guideMailTemplateId: null,
      name: "Test Name",
    });
  };

  const handleNewBulletinGroupClose = () => {
    setNewBulletinGroupModalOpen(false);
  };

  const {
    data: categoriesData,
    isLoading: isCategoriesLoading,
  } = useGetCategories();

  return (
    <>
      <BulletinCard
        title={localization(
          "/pages/app/bulletin-pages/bulletin-management/bulletin-groups"
        )}
        icon={
          <MailOutlined className=" pb-2 pt-1 px-3 rounded-full bg-Primary-50 text-2xl text-Primary-500 font-bold" />
        }
        description={
          "Lorem ipsum dolor sit amet consectetur. A lectus nulla imperdiet egestas massa malesuada sollicitudin suspendisse. Vulputate rhoncus eget lobortis "
        }
        buttonTitle={localization(
          "/pages/app/bulletin-pages/bulletin-management/new-bulletin-group"
        )}
        buttonOnClick={() => setNewBulletinGroupModalOpen(true)}
      />
      <NewBulletinGroupModal
        isOpen={isNewBulletinGroupModalOpen}
        handleClose={handleNewBulletinGroupClose}
        handleOk={handleNewBulletinGroup}
        categoriesData={categoriesData}
      />
    </>
  );
};

const NewBulletinGroupModal = ({
  isOpen,
  handleOk,
  handleClose,
  categoriesData,
}) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      footer={null}
      centered
    >
      <div className="flex">
        <div>
          <MailOutlined className=" pb-2 pt-1 px-3 rounded-full bg-Secondary-100 text-2xl text-Secondary-500 font-bold" />
        </div>
        <div className="flex flex-col ml-2">
          <h6 className="font-medium">
            {localization(
              "/pages/app/bulletin-pages/bulletin-management/create-a-new-bulletin-group"
            )}
          </h6>
          <div className="text-Grey-600 font-normal">
            {localization(
              "/pages/app/bulletin-pages/bulletin-management/add-a-type"
            )}
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <h6 className="font-medium mb-1">
          {localization(
            "/pages/app/bulletin-pages/bulletin-management/choose-a-category"
          )}
        </h6>
        <Form onFinish={handleOk}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Group is required",
              },
            ]}
            name={["categoryId"]}
          >
            <Select
              showSearch
              showArrow
              style={{
                width: "100%",
              }}
              placeholder={localization(
                "/pages/app/bulletin-pages/bulletin-management/example"
              )}
              onChange={handleChange}
            >
              {categoriesData?.map((category, idx) => (
                <Select.Option value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="flex flex-col mb-0 items-end">
            <Button
              className=" bg-Primary-500 text-White rounded-lg"
              size="large"
              htmlType="submit"
            >
              {localization(
                "/pages/app/bulletin-pages/bulletin-management/add"
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

const BulletinCard = ({
  icon,
  title,
  badgeList,
  description,
  buttonOnClick,
  buttonTitle,
}) => {
  return (
    <div className="p-6 flex  rounded-md bg-White">
      <div>{icon}</div>

      <div className="ml-3 flex flex-col flex-1 ">
        <h6 className="font-medium text-Black">{title}</h6>
        <div className="mt-2 text-Grey-600 font-normal text-sm">
          {description}
          <div className="flex gap-4 mt-3 flex-wrap">
            {badgeList?.map((groupName, idx) => (
              <div className="py-0.5 px-2 bg-Grey-50 rounded-2xl">
                {groupName}
              </div>
            ))}
          </div>
        </div>
        <Button
          size="large"
          className="flex-1 rounded-md mt-6  text-Primary-500"
          onClick={buttonOnClick}
        >
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};
