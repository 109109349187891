import clsx from "clsx";
import { useState } from "react";
import PageButton from "../../components/buttons/page-button";

const PageSelector = ({ buttonInformation, setSelectedPage, className }) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const handleOnCliked = (index) => {
    setSelectedPageIndex(index);
    setSelectedPage(index);
  };
  return (
    <div className={clsx(className && className, "flex flex-row flex-wrap")}>
      {buttonInformation.map((page, index) => {
        return (
          <div
            key={`div-page-selector${index}`}
            className="my-auto"
            onClick={() => handleOnCliked(index)}
          >
            <PageButton
              key={`page-button-${index}`}
              info={page.name}
              selected={selectedPageIndex == index}
            />
          </div>
        );
      })}
    </div>
  );
};
export default PageSelector;
