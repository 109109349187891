import { useQuery, useMutation } from "react-query";
import {
  getFilteredDatabases,
  getFilteredPublicDatabases,
  postDatabase,
  getDatabase,
  updateDatabase,
  deleteDatabase,
} from "../../api/database/database.api";

export const useGetFilteredDatabases = (
  page,
  size,
  searchKey,
  filters,
  firstChar,
  categoryIds
) => {
  const params = new URLSearchParams({
    searchKey: searchKey,
    page: page,
    size: size,
    startsWith: firstChar,
    categories: [categoryIds],
  });

  for (let key in filters) {
    var filter = filters[key];
    if (filter !== "undefined" && filter.length > 0)
      filters[key]?.forEach((element) => {
        params.append(key, element);
      });
  }
  return useQuery(["get-filtered-databases", params.toString()], () => {
    return getFilteredDatabases(`/database?${params.toString()}`);
  });
};

export const useGetFilteredPublicDatabases = (
  page,
  size,
  searchKey,
  filters,
  firstChar,
  categoryIds
) => {
  const params = new URLSearchParams({
    searchKey: searchKey,
    page: page,
    size: size,
    startsWith: firstChar,
    categories: [categoryIds],
  });

  for (let key in filters) {
    var filter = filters[key];
    if (filter !== "undefined" && filter.length > 0)
      filters[key]?.forEach((element) => {
        params.append(key, element);
      });
  }
  return useQuery(["get-public-databases", params.toString()], () => {
    return getFilteredPublicDatabases(`/database?${params.toString()}`);
  });
};

export const useAddDatabase = (onSuccess, onError) => {
  const id = window.localStorage.getItem("id");
  return useMutation(
    "add-new-database",
    (body) => {
      return postDatabase({ ...body, owner: id });
    },
    { onSuccess, onError }
  );
};

export const useGetDatabase = (id) => {
  return useQuery([`get-database`, id], () => {
    return getDatabase(id);
  });
};

export const useUpdateDatabase = (id, ownerId, onSuccess) => {
  return useMutation(
    [`update-database`, ownerId],
    (body) => {
      return updateDatabase(id, { ...body, owner: ownerId });
    },
    { onSuccess }
  );
};

export const useDeleteDatabase = (id, onSuccess) => {
  return useMutation(
    `delete-database`,
    () => {
      return deleteDatabase(id);
    },
    { onSuccess }
  );
};
