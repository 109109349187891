import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  getUser,
  updateUser,
  getAuthor,
  getAuthors,
  postUser,
  deleteUser,
  postResetPasswordUser,
} from "../../api/user/user.api";
import { localization } from "../../localization/localization";
export const useGetUserMe = () => {
  const userId = window.localStorage.getItem("id");
  return useQuery("get-user-me", () => {
    return getUser(userId);
  });
};

export const useUpdateUserMe = () => {
  const userId = window.localStorage.getItem("id");
  return useMutation("update-user-me", (body) => {
    return updateUser(userId, body);
  });
};

export const useUpdateUser = (id, onSuccess, onError) => {
  return useMutation(
    "update-user",
    (body) => {
      return updateUser(id, body);
    },
    { onSuccess, onError: (response) => onError(response) }
  );
};

export const useGetAuthors = (page, size, searchKey) => {
  return useQuery(["get-authors", searchKey], () => {
    return getAuthors(page, size, searchKey);
  });
};

export const useGetAuthor = (id) => {
  return useQuery("get-author", () => {
    return getAuthor(id);
  });
};

export const useCreateUser = (onSuccess) => {
  return useMutation(
    `create-user`,
    (body) => {
      return postUser(body);
    },
    {
      onSuccess: onSuccess,
      onError: () => {
        notification.error({
          message: localization("/hooks/user/user.hooks/add-new-user"),
          description: localization(
            "/hooks/user/user.hooks/unexpected-error-while-adding-user"
          ),
        });
      },
    }
  );
};

export const useDeleteUser = (onSuccess, onError) => {
  return useMutation(
    "delete-user",
    (id) => {
      return deleteUser(id);
    },
    { onSuccess, onError }
  );
};

export const useResetPasswordUser = (onSuccess, onError) => {
  return useMutation(
    "reset-password-user",
    (body) => {
      return postResetPasswordUser(body);
    },
    { onSuccess, onError }
  );
};
