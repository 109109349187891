import React,{ useState,useEffect } from 'react'

import { localization } from '../../../localization/localization'
import { Form, Modal, notification } from 'antd'

import { PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useUploadFile } from '../../../hooks/file/file.hooks'
import { useCreateCategory, useGetCategories, useUpdateCategory } from '../../../hooks/category/category.hooks'
import CategoryForm from './category-form'


const CategoryModal = ({
  edit,
  categoryName,
  initialCategoryPhoto,
  setIsModalVisible,
  ancestorCategoryId,
  onCancel,
  isModalVisible,
}) => {
  const [form] = Form.useForm()

  const [ initialBody, setInitialBody ] =  useState(null)

  const [categoryPhoto, setCategoryPhoto] = useState(null)

  const { mutate: mutateFile, isSuccess: isUploaded,} = useUploadFile()
  const { refetch:refetchAllCategories,isFetching } = useGetCategories()
  
  const { mutate: mutateCreateCategory } = useCreateCategory(() => {
    notification.success({
      description: localization(
        '/pages/app/category-pages/edit-categories/category-added'
      ),
      message: localization(
        '/pages/app/category-pages/edit-categories/success'
      ),
    })
    refetchAllCategories()
    setIsModalVisible(false)
  })

  const { mutate: mutateUpdateCategory, isLoading } = useUpdateCategory( ancestorCategoryId, ()=>{
    notification.success({
      message: localization(
        '/pages/app/category-pages/edit-categories/success'
      ),
      description:'Update is successfull'
    })
    refetchAllCategories()
    setIsModalVisible(false)
  })


  useEffect(()=>{
    if(edit) {
      setInitialBody({
        name:categoryName,
        photoId:initialCategoryPhoto.id
      })
    }
  },[isFetching])


  const handleOk = (values) => {
    const body = {
      ...values,
    }
    if (categoryPhoto) {
      const formData = new FormData()
      formData.append('file', categoryPhoto)
      mutateFile(formData, {
        onSuccess: (data) => {
          body.photoId = data.id
          !edit && ( body.ancestorCategoryId = ancestorCategoryId )
          !edit ?  mutateCreateCategory(body) : mutateUpdateCategory(body)
        },
      })
 
    }
    else{
      !edit ?  mutateCreateCategory(body) : mutateUpdateCategory(body)
    }
 
  }


  return (
    <Modal footer={null} onCancel={()=>setIsModalVisible(false)} open={isModalVisible}>
      <div className='flex flex-col'>
        <div className='flex items-center mb-8'>
          <div className='bg-Secondary-100 p-4 rounded-full flex items-center text-base text-Secondary-500 '>
            {!edit ? <PlusOutlined /> : <FormOutlined />}
          </div>
          <div className='ml-3 font-Inter flex-1'>
            <div className='font-medium text-Black text-base mb-2'>
              {!edit
                ? localization(
                    '/common/components/category/category-add-edit-modal/create-a-new-category'
                  )
                : localization(
                    '/common/components/category/category-add-edit-modal/update-category'
                  )}
            </div>
            <div className='font-normal text-Grey-600 text-sm'>
              {!edit
                ? localization("/common/components/category/category-add-edit-modal/add-a-type-for-categorization.")
                : localization("/common/components/category/category-add-edit-modal/update-for-categorization.")}
            </div>
          </div>
        </div>
        <CategoryForm
          form={form}
          initialValues={edit ? initialBody : null}
          handleOk={handleOk}
          disabled={!edit ? !categoryPhoto : false}
          setCategoryPhoto={setCategoryPhoto}
        />
      </div>
    </Modal>
  )
}
export default CategoryModal
