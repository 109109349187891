import * as api from "../api";

export const getMailGroups = () => {
  return api.get("/mail-group");
};

export const getMailGroup = (id) => {
  return api.get(`/mail-group/${id}`);
};

export const addMailUserToMailGroup = async (userId, mailGroupId, body) => {
  return api.post(`/mail-group/${mailGroupId}/mail-user/${userId}`, body);
};

export const postMailGroup = (body) => {
  return api.post("/mail-group", body);
};

export const deleteMailUserFromMailGroup = (userId, mailGroupId) => {
  return api.del(`/mail-group/${mailGroupId}/mail-user/${userId}`);
};
