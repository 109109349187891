import { Empty, Spin } from 'antd'

import CategoryCarousel from '../../../common/components/carousel/category-carousel'
import CategoryListView from '../../../common/views/category-list/category-list-view'
import MainLayout from '../../../common/layouts/main-layout'
import React from 'react'
import { useGetCategories } from '../../../hooks/category/category.hooks'
import { useEffect } from 'react'
import { notification } from 'antd'
import { localization } from '../../../localization/localization'

const CategoriesPage = () => {
	const { data: categoriesData, isError, isLoading } = useGetCategories()
	useEffect(() => {
		if (isError) {
			notification.error({
				message: localization(
					'/pages/public/category-pages/categories/categories-failed'
				),
			})
		}
	}, [isError])
	return (
		<MainLayout>
			<div className='absolute left-0 right-0 top-20'>
				<CategoryCarousel />
				<div className='px-11'>
					<h3 className='text-Black text-xl font-Inter mt-6 mb-2'>
						{localization(
							'/pages/public/category-pages/categories/category-list'
						)}
					</h3>
					<Spin spinning={!isError && isLoading}>
						{categoriesData && (
							<div>
								<CategoryListView
									logInfo={''}
									categoriesData={categoriesData ? categoriesData : []}
								/>
							</div>
						)}
					</Spin>
				</div>
				{isError && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
			</div>
		</MainLayout>
	)
}

export default CategoriesPage
