import { useMutation, useQuery } from "react-query";
import {
  addMailUserToMailGroup,
  deleteMailUserFromMailGroup,
  getMailGroup,
  getMailGroups,
  postMailGroup,
} from "../../api/mailGroup/mailGroup.api";

export const useGetMailGroups = () => {
  return useQuery("get-mail-groups", () => {
    return getMailGroups();
  });
};

export const useGetMailGroup = (id) => {
  return useQuery("get-mail-group", () => {
    return getMailGroup(id);
  });
};

export const usePostMailGroup = (onSuccess, onError) => {
  return useMutation(
    "post-new-mail-groups",
    (body) => {
      return postMailGroup(body);
    },
    {
      onSuccess,
      onError,
    }
  );
};

export const usePostMailUserToMailGroup = (onSuccess, onError) => {
  return useMutation(
    `post-mail-user-to-mail-group`,
    (body) => {
      return addMailUserToMailGroup(
        body.mailUser?.id || body.mailUser?.email,
        body.mailGroupId
      );
    },
    { onSuccess, onError }
  );
};

export const useDeleteMailUserFromMailGroup = (onSuccess, onError) => {
  return useMutation(
    "delete-mail-user-from-mail-group",
    (body) => {
      return deleteMailUserFromMailGroup(body.userId, body.mailGroupId);
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
};
