import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Upload,
  notification,
  Spin,
} from "antd";
import UpdatePhoto from "../../../common/components/photo/update-photo";
import {
  useGetTenantDetail,
  useUpdateTenantDetail,
} from "../../../hooks/tenant/tenant.hooks";
import defaultInstitutionPhoto from "../../../assets/institution_logo.png";
import { HeartIcon, VisibilityIcon } from "../../../icons/common-icon";
import { HexColorPicker } from "react-colorful";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useBlob, useUploadFile } from "../../../hooks/file/file.hooks";
import { localization } from "../../../localization/localization";

const { Dragger } = Upload;

const TenantSettings = () => {
  const {
    data: tenantData,
    isLoading: isTenantDataLoading,
    refetch,
  } = useGetTenantDetail();
  const { mutate: updateTenantDetailMutate } = useUpdateTenantDetail(() => {
    window.localStorage.setItem(
      "primaryTenantHexCode",
      primaryRef.current.style.background
    );
    window.localStorage.setItem(
      "secondaryTenantHexCode",
      secondaryRef.current.style.background
    );
  });
  const { mutate: uploadFileMutate } = useUploadFile();
  const [form] = Form.useForm();
  const [isPrimarySelectionOpen, setIsPrimarySelectionOpen] = useState(false);
  const [isSecondarySelectionOpen, setIsSecondarySelectionOpen] =
    useState(false);
  const [showUploadList, setShowUploadList] = useState(true);

  const [tenantPhoto, setTenantPhoto] = useState(null);
  const [tenantPhotoBlob, setTenantPhotoBlob, isBlobDownloading] = useBlob(
    tenantData && tenantData.logo?.id
  );

  const primaryRef = useRef();
  const secondaryRef = useRef();

  const onSubmit = (formData) => {
    const body = {
      ...formData,
    };
    if (tenantPhoto) {
      const formData = new FormData();
      formData.append("file", tenantPhoto);
      uploadFileMutate(formData, {
        onSuccess: (data) => {
          body.logoId = data.id;
          updateTenantDetailMutate(body, {
            onSuccess: () => {
              notification.success({
                description: localization(
                  "/pages/app/admin-pages/tenant-settings/change-saved"
                ),
              });
              setShowUploadList(false);
              refetch();
            },
          });
          setShowUploadList(false);
        },
      });
    } else {
      body.logoId = tenantPhoto === false ? null : tenantData?.logo?.id;
      updateTenantDetailMutate(body, {
        onSuccess: () => {
          notification.success({
            description: localization(
              "/pages/app/admin-pages/tenant-settings/change-saved"
            ),
          });
          setShowUploadList(false);
          refetch();
        },
      });
    }
  };

  const onPrimaryColorChange = (selectedColor) => {
    primaryRef.current.style.background = selectedColor;
    form.setFieldValue("primaryColorHexCode", selectedColor);
  };

  const handlePrimaryColorSelectorClicked = () => {
    if (isSecondarySelectionOpen) {
      setIsSecondarySelectionOpen(false);
    }
    setIsPrimarySelectionOpen(!isPrimarySelectionOpen);
  };

  const onSecondaryColorChange = (selectedColor) => {
    secondaryRef.current.style.background = selectedColor;
    form.setFieldValue("secondaryColorHexCode", selectedColor);
  };

  const handleSecondaryColorSelectorClicked = () => {
    if (isPrimarySelectionOpen) {
      setIsPrimarySelectionOpen(false);
    }
    setIsSecondarySelectionOpen(!isSecondarySelectionOpen);
  };

  useEffect(() => {
    if (tenantData) {
      form.setFieldsValue(tenantData);
      primaryRef.current.style.background = tenantData.primaryColorHexCode;
      secondaryRef.current.style.background = tenantData.secondaryColorHexCode;
    }
  }, [tenantData]);

  const draggerProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    accept: "image/png, image/jpeg",
    maxCount: 1,
    showUploadList: showUploadList,
    beforeUpload: (file) => {
      setShowUploadList(true);
      setTenantPhoto(file);
      setTenantPhotoBlob(URL.createObjectURL(file));
      return false;
    },
  };

  return (
    <>
      <Button
        form="tenant-setting"
        htmlType="submit"
        size="large"
        className="bg-Primary-500 rounded-lg mb-8 px-4 text-White font-Inter text-base"
      >
        {localization("/pages/app/admin-pages/tenant-settings/save")}
      </Button>
      <Form
        form={form}
        name="tenant-setting"
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
      >
        <div className="bg-White mb-4 pb-6">
          <div className="flex flex-col gap-y-6">
            <div
              id="tenant-identity"
              className="flex flex-row gap-3 items-center m-6"
            >
              <div className="my-auto bg-Primary-50 rounded-full py-4 px-3">
                <VisibilityIcon />
              </div>

              <div className="flex flex-col gap-2">
                <p className="font-Inter text-Black">
                  {localization(
                    "/pages/app/admin-pages/tenant-settings/institution-visual-identity"
                  )}
                </p>
                <p className="font-Inter text-Grey-600 text-sm">
                  {localization(
                    "/pages/app/admin-pages/tenant-settings/institution-identity-arrangement"
                  )}
                </p>
              </div>
            </div>
            <Row gutter={12}>
              <Col span={2}>
                <div className="flex my-auto ml-6">
                  <Spin spinning={isBlobDownloading}>
                    <UpdatePhoto
                      setPhotoId={setTenantPhoto}
                      blobPhoto={tenantPhotoBlob}
                      setBlobPhoto={setTenantPhotoBlob}
                      defaultPhoto={defaultInstitutionPhoto}
                    />
                  </Spin>
                </div>
              </Col>
              <Col className="ml-6" span={10}>
                <div className="h-full w-full  border rounded-lg  border-Grey-100">
                  <Dragger
                    {...draggerProps}
                    showUploadList={showUploadList}
                    style={{
                      padding: "0px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "1px",
                    }}
                    disabled={isBlobDownloading}
                    className="font-Inter"
                  >
                    <span className="flex flex-col gap-y-2">
                        <Spin spinning={isBlobDownloading}>
                          <p className="text-center mx-28 py-3 text-Grey-500 ">
                            {localization(
                              "/pages/app/admin-pages/tenant-settings/change-institution-logo"
                            )}
                          </p>
                          <div className="flex">
                            <h className="mx-auto text-Primary-500 border border-Grey-200 font-Inter rounded-lg text-center py-2 px-4 inline-block shadow-sm">
                              {localization(
                                "/pages/app/admin-pages/tenant-settings/select-file"
                              )}
                            </h>
                          </div>
                          <p className="text-center mx-28 py-3 px-9 text-Grey-500">
                            {localization(
                              "/pages/app/admin-pages/tenant-settings/file-format"
                            )}
                          </p>
                        </Spin>
                    </span>
                  </Dragger>
                </div>
              </Col>
              <Col span={11} className="mx-auto">
                <Form.Item
                  name="primaryColorHexCode"
                  label={localization(
                    "/pages/app/admin-pages/tenant-settings/hex-code"
                  )}
                  className="font-Inter text-Black text-base mb-0"
                  rules={[
                    {
                      required: true,
                      pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/enter-valid-hex-code"
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded"
                    placeholder="#FFFFFF"
                  />
                </Form.Item>
                <div className="flex flex-row justify-start gap-x-6 ">
                  <div
                    ref={primaryRef}
                    onClick={handlePrimaryColorSelectorClicked}
                    className="h-12 w-12 rounded-lg border mt-2 cursor-pointer"
                  ></div>
                  <div
                    className={clsx(
                      isPrimarySelectionOpen ? "visible" : "hidden"
                    )}
                  >
                    <HexColorPicker onChange={onPrimaryColorChange} />
                  </div>
                </div>
                <Form.Item
                  name="secondaryColorHexCode"
                  label={localization(
                    "/pages/app/admin-pages/tenant-settings/secondary-hex-code"
                  )}
                  className="font-Inter text-Black text-base mb-0 mt-4"
                  rules={[
                    {
                      required: true,
                      pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$",
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/enter-valid-hex-code"
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    className="rounded"
                    placeholder="#FFFFFF"
                  />
                </Form.Item>
                <div className="flex flex-row justify-start gap-x-6">
                  <div
                    ref={secondaryRef}
                    onClick={handleSecondaryColorSelectorClicked}
                    className="h-12 w-12 rounded-lg border mt-2 cursor-pointer"
                  ></div>
                  <div
                    className={clsx(
                      isSecondarySelectionOpen ? "visible" : "hidden"
                    )}
                  >
                    <HexColorPicker onChange={onSecondaryColorChange} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={localization(
                    "/pages/app/admin-pages/tenant-settings/institution-name"
                  )}
                  className="font-Inter text-Black mx-6 my-2"
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank"
                      ),
                    },
                  ]}
                >
                  <Input size="large" className="rounded-md" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="abbreviation"
                  label={
                    <>
                      {localization(
                        "/pages/app/admin-pages/tenant-settings/institution-abbreviation"
                      )}
                      <span className="text-Grey-500 font-Inter  font-normal text-sm ml-2">
                        {localization(
                          "/pages/app/admin-pages/tenant-settings/max-7-char"
                        )}
                      </span>
                    </>
                  }
                  className="font-Inter text-Black mx-6"
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank"
                      ),
                    },
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/must-max-7-char"
                      ),
                      max: 7,
                    },
                  ]}
                >
                  <Input size="large" className="rounded-md" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <div className="bg-White p-6">
          <div className="flex flex-row gap-x-3 items-center">
            <div className="my-auto bg-Primary-50 px-3 py-3.5 rounded-full">
              <HeartIcon />
            </div>
            <div>
              <p className="font-Inter text-Black text-base mb-2">
                {localization(
                  "/pages/app/admin-pages/tenant-settings/institution-descriptions"
                )}
              </p>
              <p className="font-Inter text-Grey-600 text-sm">
                {localization(
                  "/pages/app/admin-pages/tenant-settings/guide-your-users"
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-x-6 mt-6">
            <Row gutter={24} className="w-full">
              <Col span={12}>
                <Form.Item
                  name="guideDescription"
                  label={localization(
                    "/pages/app/admin-pages/tenant-settings/guides-description"
                  )}
                  className="font-Inter text-Black text-sm "
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank"
                      ),
                    },
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/at-least-20-char"
                      ),
                      min: 20,
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder={localization(
                      "/pages/app/admin-pages/tenant-settings/add-a-description-guide-page"
                    )}
                    size="large"
                    className="rounded-md w-full resize-none"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="databaseDescription"
                  className="font-Inter text-Black text-sm "
                  label={localization(
                    "/pages/app/admin-pages/tenant-settings/databases-description"
                  )}
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank"
                      ),
                    },
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/at-least-20-char"
                      ),
                      min: 20,
                    },
                  ]}
                >
                  <Input.TextArea
                    aria-expanded={false}
                    placeholder={localization(
                      "/pages/app/admin-pages/tenant-settings/add-a-description-databases-page"
                    )}
                    size="large"
                    className="rounded-md w-full resize-none"
                  />
                </Form.Item>
                <Form.Item
                  name="databaseExternalLink"
                  className="font-Inter text-Black text-sm "
                  label={localization(
                    "/pages/app/admin-pages/tenant-settings/databases-off-campus-access-help-link"
                  )}
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/cannot-be-left-blank"
                      ),
                    },
                    {
                      type: "url",
                      message: localization(
                        "/pages/app/admin-pages/tenant-settings/enter-valid-url"
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={localization(
                      "/pages/app/admin-pages/tenant-settings/add-a-description-databases-page"
                    )}
                    size="large"
                    className="rounded-md w-full"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TenantSettings;
