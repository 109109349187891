import React from "react";
import AZList from "../../components/filter/a-to-z-list";

const AZFilterView = ({firstChar,setFirstChar}) => {
  return (
    <div>
      <AZList setFirstElement={setFirstChar} />
      <div className="d-flex align-items-center mb-3">
        <p className="py-1 font-Inter font-medium text-5xl mt-6 text-Grey-500 border-b border-Grey-200">
          {firstChar === "" ? "ALL" : firstChar}
        </p>
      </div>
    </div>
  );
};

export default AZFilterView;
