import { Spin } from 'antd'
import { BookOutlined } from '@ant-design/icons'
import React from 'react'
import { useGetCategories } from '../../../hooks/category/category.hooks'

import EditCategoryCard from '../../../common/components/cards/edit-category-card'

import AddCategoryButton from '../../../common/components/category/category-button'
import { localization } from '../../../localization/localization';

function findFirstLevelChildren(categories, parentId) {
  let firstLevelChildren = [];
  for (let category of categories) {
    if (category.ancestorCategory && category.ancestorCategory.id === parentId) {
      firstLevelChildren.push(category);
    }
  }
  return firstLevelChildren;
}

const EditCategoriesPage = () => {
  const { data: categories, isLoading, isError } = useGetCategories()

  return (
    <Spin spinning={isLoading}>
      <div>
        <div className='mx-12 my-8 p-6 rounded-md sm:flex items-center text-center sm:text-left bg-White'>
          <div className='bg-Primary-50 sm:p-3 pb-1.5 rounded-full sm:flex items-center text-Primary-500 '>
            <BookOutlined />
          </div>

          <div className='mx-3 font-Inter flex-1 mt-2 sm:mt-0'>
            <div className='font-medium text-Black text-base'>
              {localization("/pages/app/category-pages/edit-categories/create-a-special-category-tree")}
            </div>
            <div className='font-normal text-Grey-600 text-sm'>
              {localization("/pages/app/category-pages/edit-categories/organize-guides-databases")}
            </div>
          </div>
          <AddCategoryButton
            text={localization("/pages/app/category-pages/edit-categories/add-a-new-category")}
            buttonClass='bg-Primary-500 text-Primary-50 border-1 border-Primary-500 shadow-sm shadow-Primary-500/5
              hover:bg-Primary-500 hover:text-Primary-100 hover:border-Primary-500
              rounded-lg text-base font-medium sm:mt-0 mt-2'
            size='large'
          />
        </div>

        <div className='mt-8 mx-12 grid grid-cols-1 lg:grid-cols-2 gap-6 '>
          {categories &&
            categories.map((category) => (
              <EditCategoryCard category={category} firstLevelCategories={findFirstLevelChildren(categories, category?.id)} />
            ))}
        </div>
      </div>
    </Spin>
  )
}

export default EditCategoriesPage
