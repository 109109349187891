import {
	deleteGuide,
	getFilteredGuides,
	getFilteredPublicGuides,
	getPublicGuide,
	postGuide,
	updateGuide,
} from '../../api/guide/guide.api'
import { useMutation, useQuery } from 'react-query'
import { notification } from 'antd'

export const useGetPublicGuide = (id) => {
	return useQuery(['get-public-guide', id], () => {
		return getPublicGuide(id)
	})
}

export const useGetFilteredGuides = (
	page,
	size,
	searchKey,
	filters,
	categoryIds
) => {
	const params = new URLSearchParams({
		page: page,
		size: size,
		searchKey: searchKey,
		categories: [categoryIds],
	})

	for (let key in filters) {
		var filter = filters[key]
		if (filter !== 'undefined' && filter.length > 0)
			filters[key]?.forEach((element) => {
				params.append(key, element)
			})
	}
	return useQuery({
		queryKey: ['useFiltered-guide', params.toString()],
		queryFn: () => getFilteredGuides(`/guide?${params.toString()}`),
	})
}

export const useGetFilteredPublicGuides = (
	page,
	size,
	searchKey,
	filters,
	categoryIds
) => {
	const params = new URLSearchParams({
		page: page,
		size: size,
		searchKey: searchKey,
		categories: [categoryIds],
	})

	for (let key in filters) {
		var filter = filters[key]
		if (filter !== 'undefined' && filter.length > 0)
			filters[key]?.forEach((element) => {
				params.append(key, element)
			})
	}
	return useQuery({
		queryKey: [`get-public-guides`, params.toString()],
		queryFn: () => getFilteredPublicGuides(`/guide?${params.toString()}`),
	})
}

export const useUpdateGuide = (id, onSuccess) => {
	return useMutation(
		'update-guide',
		(body) => {
			return updateGuide(body, id)
		},
		{
			onSuccess,
			onError: (err) =>
				notification.error({
					description: err.message,
					message: 'Sorry, error has occured',
				}),
		}
	)
}

export const useCreateGuide = (onSuccess) => {
	return useMutation(
		'create-guide',
		(body) => {
			return postGuide(body)
		},
		{
			onSuccess,
			onError: (err) =>
				notification.error({
					description: err.message,
					message: 'Sorry, error has occured',
				}),
		}
	)
}

export const useDeleteGuide = (onSuccess) => {
	return useMutation(
		'delete-guide',
		(id) => {
			return deleteGuide(id)
		},
		{
			onSuccess,
			onError: (err) =>
				notification.error({
					description: err.message,
					message: 'Sorry, error has occured',
				}),
		}
	)
}
