import React from "react";
import { GGuidesFancyIcon, GGuidesFullIcon } from "../../../icons/gguides-icon";
import { Lock } from "../../../icons/common-icon";
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useRecoverPassword } from "../../../hooks/auth/auth-hooks";
import { localization } from "../../../localization/localization";

const ForgotPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/email-sent", {
      state: { email: form.getFieldValue("email") },
    });
  };

  const { mutate } = useRecoverPassword(onSuccess);

  const onSubmit = (formData) => {
    mutate(formData);
  };

  return (
    <>
      <div className="flex h-screen bg-White">
        <div className="m-auto bg-White w-88">
          <div className="flex flex-col">
            <div className="mx-auto mb-6">
              <Lock />
            </div>
            <div className="mx-auto mb-3">
              <p className="text-lg font-Inter">
                {localization(
                  "/pages/public/auth-pages/forgot-password/did-you-forget-password"
                )}
              </p>
            </div>
            <p className="mx-auto mb-6 text-lg font-Inter text-center text-Grey-400">
              {localization(
                "/pages/public/auth-pages/forgot-password/dont-worry-link"
              )}
            </p>
            <div>
              <Form
                form={form}
                name="forgot-password"
                layout="vertical"
                onFinish={onSubmit}
              >
                <Form.Item
                  name="email"
                  label={localization(
                    "/pages/public/auth-pages/forgot-password/email"
                  )}
                  className="text-black font-Inter"
                  style={{ fontSize: "16px" }}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: localization(
                        "/pages/public/auth-pages/forgot-password/invalid-email"
                      ),
                    },
                  ]}
                >
                  <Input placeholder="example@domain.com" />
                </Form.Item>

                <Button
                  size="large"
                  htmlType="submit"
                  className="w-full bg-Primary-500 text-White font-Inter rounded-md"
                >
                  {localization(
                    "/pages/public/auth-pages/forgot-password/reset-password"
                  )}
                </Button>
                <div className="rounded-md mb-12 mt-6">
                  <Link to="/login">
                    <Button
                      size="large"
                      className="w-full bg-White text-Primary-500 rounded-md font-Inter"
                    >
                      {localization(
                        "/pages/public/auth-pages/forgot-password/return-to-login"
                      )}
                    </Button>
                  </Link>
                </div>
              </Form>
            </div>
            <div className="mx-auto mb-40">
              <GGuidesFullIcon />
            </div>
          </div>
        </div>
      </div>
      <GGuidesFancyIcon />
    </>
  );
};

export default ForgotPasswordPage;
