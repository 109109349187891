import { Dropdown, notification, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDeleteUser, useUpdateUser } from "../../../hooks/user/user.hooks";
import { UpgradeUserIcon, VerticalDotsIcon } from "../../../icons/common-icon";
import defaultPhoto from "../../../assets/avatar_image.png"
import { DeleteOutlined } from '@ant-design/icons';
import getUserRole from "../../../util/get-user-role";
import { localization } from "../../../localization/localization";

const AuthorDetailView = ({ image, authorData }) => {
  const navigate = useNavigate();

  const emailButtonClickHandler = () => {
    window.location = "mailto:" + authorData.email;
  };

  const onPromoteUserSuccess = () => {
    notification.success({ message:localization("/common/views/author/author-detail-view/give-admin-permissions")});
    navigate(-1)
  };

  const onPromoteUserError = (error) => {
    notification.error({
      message: localization("/common/views/author/author-detail-view/failed-permission-grant"),
      description: error.message,
    });
    navigate(-1)
  };

  const onDeleteUserSuccess = () => {
    notification.success({ message: localization("/common/views/author/author-detail-view/successful-delete") });
    navigate(-1)
  };

  const onDeleteUserError = (error) => {
    notification.error({
      message: localization("/common/views/author/author-detail-view/failed-delete"),
      description: error.message,
    });
    navigate(-1)
  };

  const { mutate: mutateUpdateUser } = useUpdateUser(
    authorData.id,
    onPromoteUserSuccess,
    onPromoteUserError
  );
  const { mutate: mutateDeleteUser, error } = useDeleteUser(
    onDeleteUserSuccess,
    onDeleteUserError
  );

  const onChangeUserRoleButtonClicked = () => {
    authorData.userRole = "ADMIN";
    mutateUpdateUser({ ...authorData });
  };

  const onDeleteUserClicked = () => {
    mutateDeleteUser(authorData.id);
  };
  const items = [
    {
      key: "1",
      label: (
        <Row gutter={12} onClick={onChangeUserRoleButtonClicked}>
          <div className="my-auto px-2 mr-2.5">
            <UpgradeUserIcon />
          </div>
          <p className="text-Grey-600 font-medium font-Inter py-2">
            {localization("/common/views/author/author-detail-view/give-admin-permissions-button")}
          </p>
        </Row>
      ),
    },
    {
      key: "2",
      label: (
        <Row
          gutter={12}
          className="hover:bg-Error-50 group text-Grey-600 rounded-md hover:text-Error-600 "
          onClick={onDeleteUserClicked}
        >
          <div className="text-xl px-2 mr-3">
            <DeleteOutlined />
          </div>
          <p className="font-medium font-Inter py-2">
            {localization("/common/views/author/author-detail-view/delete-user")}
          </p>
        </Row>
      ),
    }
  ]
  return (
    <div className="flex flex-row gap-x-6">
      <div className="basis-1/12">
        <div className="flex flex-col gap-y-6">
          <div className="mx-auto w-32 h-32">
            <Spin spinning={!defaultPhoto && !image}>
              <img
                src={image || defaultPhoto}
                className="object-fill inline-block rounded-full h-32 w-32 "
              />
            </Spin>
          </div>
          <div className="w-full">
            <div className="inline-block font-Inter text-Primary-500 text-center border rounded-lg border-Grey-200 w-full">
              <button
                className="px-3 py-2 w-full "
                onClick={emailButtonClickHandler}
              >
                {localization(
                  "/common/views/author/author-detail-view/send-email-button"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="basis-10/12 flex justify-between">
        <div className="flex flex-col">
          <div className="font-Inter text-Black text-3xl">
            {authorData.name + " " + authorData.surname}
          </div>
          <div className="text-Grey-600 font-Inter text-xl">
            {localization(
              "/common/views/author/author-detail-view/librarian-label"
            )}
          </div>
          <div className="mt-3">
            <div className="flex flex-col gap-y-6">
              <p className="text-base font-Inter text-Grey-600">
                {authorData.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      {getUserRole() === "ADMIN" && (
        <div className="basis-1/12">
          <Dropdown.Button
            menu={{ items }}
            icon={<VerticalDotsIcon />}
          ></Dropdown.Button>
        </div>
      )}
    </div>
  );
};

export default AuthorDetailView;
