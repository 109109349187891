import { useNavigate, useParams } from "react-router-dom";
import DatabaseForm from "../../../common/views/app/forms/database/database-form";
import {
  useDeleteDatabase,
  useGetDatabase,
  useUpdateDatabase,
} from "../../../hooks/database/database.hooks";
import { CheckCircleOutlined, DeleteColumnOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { localization } from "../../../localization/localization";

const UpdateDatabasePage = () => {
  const navigate = useNavigate();
  const { databaseId } = useParams();
  const { data, isLoading, refetch } = useGetDatabase(databaseId);
  const ownerId = data?.owner.id;
  const formData = {};

  const openUpdatedNotification = () => {
    notification.success({
      message: "Saved",
      description: "Your changes on database successfully saved !",
      icon: <CheckCircleOutlined className="text-Success-500" />,
    });
  };

  const openDeleteNotification = () => {
    notification.success({
      message: "Delete",
      description: "Database successfully deleted !",
      icon: <DeleteColumnOutlined className="text-Success-500" />,
    });
  };

  const onUpdateSuccess = () => {
    navigate("/app/databases");
    openUpdatedNotification();
    refetch();
  };

  const onDeleteSuccess = () => {
    navigate("/app/databases");
    openDeleteNotification();
  };

  const { mutate: mutateUpdateDatabase } = useUpdateDatabase(
    databaseId,
    ownerId,
    onUpdateSuccess
  );
  const { mutate: mutateDeleteDatabase } = useDeleteDatabase(
    databaseId,
    onDeleteSuccess
  );

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : !data ? (
        <div>Error Resource missing</div>
      ) : (
        formData && (
          <>
            <DatabaseForm
              data={data}
              onSubmitHandler={(body) => {
                mutateUpdateDatabase(body);
              }}
              formTitle={localization(
                "/pages/app/database-pages/update-database-page/edit-databates"
              )}
              formStatement={localization(
                "/pages/app/database-pages/update-database-page/databates-information"
              )}
              isUpdateForm={true}
              onDeleteHandler={() => {
                mutateDeleteDatabase();
              }}
            />
          </>
        )
      )}
    </>
  );
};

export default UpdateDatabasePage;
