import CategoryCard from '../../components/cards/category-card'
import React from 'react'

const CategoryListView = ({ categoriesData, logInfo }) => {
	const rootAndChildCategories = giveRootAndChildCategories(categoriesData)
	return (
		<div className='flex flex-wrap'>
			{rootAndChildCategories.map((category) => (
				<CategoryCard
					categoryId={category.id}
					photoId={category.photo.id}
					title={category.name}
					logInfo={logInfo}
				/>
			))}
		</div>
	)
}

export default CategoryListView

const giveRootAndChildCategories = (categoriesData) => {
	const rootCategories = categoriesData.filter((category) => {
		return !category.ancestorCategory
	})

	const childCategories = categoriesData.filter((category) => {
		return (
			category.ancestorCategory &&
			rootCategories.some((rootCategory) => {
				return rootCategory.id === category.ancestorCategory.id
			})
		)
	})

	return [...rootCategories, ...childCategories]
}
