import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteGuideType,
  getGuideTypes,
  postGuideType,
} from "../../api/guideType/guideType.api";
import { localization } from "../../localization/localization";

export const useCreateGuideType = (onSuccess, onError) => {
  return useMutation(
    "post-new-guide-type",
    (body) => {
      return postGuideType(body);
    },
    { onSuccess, onError }
  );
};

export const useDeleteGuideType = (onSuccess) => {
  return useMutation(
    "delete-guide-type",
    (id) => {
      return deleteGuideType(id);
    },
    {
      onSuccess,
      onError: (error) => {
        notification.error({
          description: localization("/hooks/guide-type/guide-type.hook/delete-failed"),
          message: error.message,
        });
      },
    }
  );
};

export const useGetGuideTypes = (page, size) => {
  return useQuery("get-guide-types", () => {
    return getGuideTypes(page, size);
  });
};
