import { Button } from 'antd'
import React from 'react'
import {
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import CategoryButton from './category-button'
import { useState } from 'react';
import CategoryDeleteModal from './category-delete-modal';

const CategoryButtonGroup = ({ child }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const handleClick = () => {
    setModalOpen(true)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        className='text-base flex flex-col items-center justify-center w-9 h-9 rounded-lg text-Error-500 bg-Error-200 border-Error-500 
        hover:bg-Error-500 hover:text-Error-200 hover:border-Error-500'
      >
        <DeleteOutlined />
      </Button>
      <CategoryDeleteModal
        childId={child.id}
        isOpen={isModalOpen}
        setOpen={setModalOpen}
      />
      <CategoryButton
        edit
        child={child}
        buttonClass='text-base flex flex-col items-center justify-center w-9 h-9 rounded-lg'
        text={<FormOutlined />}
      />
      <CategoryButton
        child={child}
        buttonClass='text-base flex flex-col items-center justify-center w-9 h-9 rounded-lg'
        text={<PlusOutlined />}
      />
    </>
  )
}

export default CategoryButtonGroup