import { Col, Row } from 'antd'
import {  useNavigate, useParams } from 'react-router-dom'

import { AddGuideIcon } from '../../../icons/common-icon'
import { AdminGuideFilterView } from '../../../common/views/filter-view/filter-view'
import { BadgeInfo } from '../../../common/components/buttons/badge'
import GuideListView from '../../../common/views/guide/guide-list-view'
import InformationBox from '../../../common/views/info-box/information-box'
import { useGetFilteredGuides } from '../../../hooks/guide/guide.hooks'
import { useGetTenantDetail } from '../../../hooks/tenant/tenant.hooks'
import { useState } from 'react'
import { localization } from '../../../localization/localization'
import AddButton from '../../../common/components/buttons/add-button'

const GuideListEditorPage = () => {
	const navigate = useNavigate()
	const [filterList, setFilterList] = useState(null)
	const [searchKey, setSearchKey] = useState('')
	const getSelections = (selectedFilters) => {
		setFilterList(selectedFilters)
	}

	const { id: categoryIds } = useParams()

	const { data: guidesData, isLoading } = useGetFilteredGuides(
		0,
		40,
		searchKey,
		filterList,
		categoryIds
	)
	const addNewGuideClicked = () => {
		navigate('/app/create-guide')
	}

	const { data: tenantDetailSettings, isLoading: isTenantDetailLoading } =
		useGetTenantDetail()
	return (
    <>
      <div className="rounded-md">
        {!(isTenantDetailLoading || isLoading) && (
          <InformationBox
            setSearchKey={setSearchKey}
            title={localization(
              "/pages/app/guide-pages/guide-list-editor/guide-list-page"
            )}
            text={tenantDetailSettings.guideDescription}
            searchBarPlaceholder={localization(
              "/pages/app/guide-pages/guide-list-editor/search-guide"
            )}
          >
            <BadgeInfo
              leftChildren={guidesData.totalElements}
              rightChildren={localization(
                "/pages/app/guide-pages/guide-list-editor/guide"
              )}
            />
          </InformationBox>
        )}
      </div>
      <AddButton
        onClick={addNewGuideClicked}
        text={localization(
          "/pages/app/guide-pages/guide-list-editor/new-guide"
        )}
        icon={<AddGuideIcon />}
      />
      <Row gutter={24}>
        <Col span={18}>
          <GuideListView guidesData={guidesData} isLoading={isLoading} />
        </Col>
        <Col span={6}>
          <AdminGuideFilterView getSelections={getSelections} />
        </Col>
      </Row>
    </>
  );
}

export default GuideListEditorPage
