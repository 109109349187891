import { Button } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EmailIcon } from "../../../icons/common-icon";
import { GGuidesFancyIcon, GGuidesFullIcon } from "../../../icons/gguides-icon";
import { localization } from "../../../localization/localization";

const EmailSentPage = () => {
  const location = useLocation();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  return (
    <>
      <div className="flex h-screen bg-White">
        <div className="mx-auto mt-24 bg-White w-88">
          <div className="flex flex-col">
            <div className="mx-auto mb-6">
              <EmailIcon />
            </div>
            <div className="mx-auto mb-3">
              <h className="text-lg font-Inter">{localization("/pages/public/auth-pages/email-sent/check-email-address")}</h>
            </div>
            <p className="mx-auto mb-6 text-lg font-Inter text-center text-Grey-400">
              {email ? email : "example@com"}
              {" " +
                localization("/pages/public/auth-pages/email-sent/check-email-for-link")}
            </p>
            <div className="rounded-md mb-12 mt-6">
              <Link to="/login">
                <Button
                  size="large"
                  className="w-full bg-White text-Primary-500 rounded-md font-Inter"
                >
                  {localization("/pages/public/auth-pages/email-sent/return-to-login")}
                </Button>
              </Link>
            </div>
            <div className="mx-auto">
              <GGuidesFullIcon />
            </div>
          </div>
        </div>
      </div>

      <GGuidesFancyIcon />
    </>
  );
};

export default EmailSentPage;
