import * as api from "../api";

export const postFile = (multipartFile) => {
  return api.post(`/file`, multipartFile);
};

export const downloadFile = (id) => {
  return api.download(`/file/${id}/download`);
};

export const downloadFileEditor = (id) => {
  return api.download(`/file/${id}/download`).then((response) => {
    console.log("RESPONSE FILE ", response);
    var blobFile = new Blob([response.data], { type: "image/jpeg" });
    var urlGenerator = window.URL;
    var url = urlGenerator.createObjectURL(blobFile);
    console.log(url);
    return url;
  });
};
