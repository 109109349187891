import Color from "color";
import { getColorsList, hexToNumber } from "./utils";

// utils/index.js
const generateCssForColor = (color, type) => {
  return `--color-${type}: ${color};`;
};

export const generateColorPalatte = (color, type) => {
  const palette = [];
  const darkColorsArray = getColorsList(
    4,
    64,
    "black",
    8,
    0,
    hexToNumber(color)
  ).reverse();
  const lightColorsArray = getColorsList(
    5,
    90,
    "white",
    8,
    0,
    hexToNumber(color)
  ).reverse();

  const colorsArray = [...lightColorsArray, Color(color), ...darkColorsArray];

  palette.push(generateCssForColor(colorsArray[0], type + "-50"));
  palette.push(generateCssForColor(colorsArray[1], type + "-100"));
  palette.push(generateCssForColor(colorsArray[2], type + "-200"));
  palette.push(generateCssForColor(colorsArray[3], type + "-300"));
  palette.push(generateCssForColor(colorsArray[4], type + "-400"));
  palette.push(generateCssForColor(colorsArray[5], type + "-500"));
  palette.push(generateCssForColor(colorsArray[6], type + "-600"));
  palette.push(generateCssForColor(colorsArray[7], type + "-700"));
  palette.push(generateCssForColor(colorsArray[8], type + "-800"));
  palette.push(generateCssForColor(colorsArray[9], type + "-900"));
  return palette;
};
