import { Navigate, Route, Routes } from "react-router-dom";

import AddDatabasePage from "./database-pages/add-database-page";
import AppLayout from "../../common/layouts/app-layout";
import AuthorDetailEditorPage from "./author-pages/author-detail-editor";
import AuthorListEditorPage from "./author-pages/author-list-editor";
import CreateGuidePage from "./guide-pages/create-guide";
import DatabaseListEditorPage from "./database-pages/database-list-editor";
import EditCategoriesPage from "./category-pages/edit-categories";
import EditGuidePage from "./guide-pages/edit-guide";
import EditTypesPage from "./types-page/edit-subjects-database-types";
import GuideListEditorPage from "./guide-pages/guide-list-editor";
import ProfileUpdatePage from "./author-pages/profile-update";
import ResetPasswordPage from "./auth-pages/reset-password";
import TenantSettings from "./admin-pages/tenant-settings";
import UpdateDatabasePage from "./database-pages/update-database-page";
import CategoryListEditorPage from "./category-pages/category-list-editor";
import EditCategoryPage from "./category-pages/edit-category";
import BulletinManagement from "./bulletin-pages/bulletin-management-layout";
import BulletinDetail from "./bulletin-pages/managemenet-pages/bulletin-detail";

const AppRoutes = () => {
  return (
    <>
      <AppLayout>
        <Routes>
          <Route
            exact
            path="/app/profile-update"
            element={<ProfileUpdatePage />}
          />
          <Route
            exact
            path="/app/update-tenant-detail"
            element={<TenantSettings />}
          />
          <Route
            exact
            path="/app/categories"
            element={<CategoryListEditorPage />}
          />
          <Route exact path="/app/guides" element={<GuideListEditorPage />} />
          <Route
            exact
            path="/app/databases"
            element={<DatabaseListEditorPage />}
          />
          <Route exact path="/app/add-database" element={<AddDatabasePage />} />
          <Route
            exact
            path="/app/update-database/:databaseId"
            element={<UpdateDatabasePage />}
          />
          <Route exact path="/app/authors" element={<AuthorListEditorPage />} />
          <Route
            exact
            path="/app/author-detail/:id"
            element={<AuthorDetailEditorPage />}
          />
          <Route exact path="/app/edit-types" element={<EditTypesPage />} />
          <Route
            exact
            path="/app/edit-categories"
            element={<EditCategoriesPage />}
          />
          <Route
            exact
            path="/app/edit-categories/:id"
            element={<EditCategoryPage />}
          />
          <Route
            exact
            path="/app/bulletin-management/*"
            element={<BulletinManagement />}
          />
          <Route
            path="/app/bulletin-management/bulletins/:id/*"
            element={<BulletinDetail />}
          />

          <Route exact path="/app/create-guide" element={<CreateGuidePage />} />
          <Route
            exact
            path="/app/update-guide/:id"
            element={<EditGuidePage />}
          />
          <Route
            exact
            path="/app/reset-password"
            element={<ResetPasswordPage />}
          />

          <Route
            exact
            path="/app/guide-category/:id"
            element={<GuideListEditorPage />}
          />

          <Route
            exact
            path="/app/database-category/:id"
            element={<DatabaseListEditorPage />}
          />
          <Route path="*" element={<Navigate to="/app/categories" />} />
        </Routes>
      </AppLayout>
    </>
  );
};

export default AppRoutes;
