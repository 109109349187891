import { TrashIcon } from "../../../icons/common-icon";
import { Spin, Upload } from "antd";

const UpdatePhoto = ({ defaultPhoto, blobPhoto, setPhotoId, setBlobPhoto }) => {
  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setBlobPhoto(reader.result);
    };
    setPhotoId(file);
    reader.readAsDataURL(file);
  };

  const handleRemovePhoto = () => {
    setPhotoId(false);
    setBlobPhoto(null);
  };


  const renderPhoto = () => {
    if (!blobPhoto && !defaultPhoto) {
      return <Spin />;
    }

    return (
      <img
        alt="avatar"
        src={blobPhoto || defaultPhoto}
        className="object-fill rounded-full inline -z-10 h-24 w-24"
      />
    );
  };

  return (
    <>
      <input type="file" style={{ display: "none" }} />
      <div className="relative h-24 w-24 cursor-pointer">
        <Upload
          name="file" 
          multiple={false}
          maxCount={1}
          showUploadList={false}
          beforeUpload={handleUpload}
        >
          {renderPhoto()}
        </Upload>
        <div onClick={handleRemovePhoto} className="absolute right-0 bottom-0">
          <TrashIcon />
        </div>
      </div>
    </>
  );
};

export default UpdatePhoto;
