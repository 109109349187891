import React from 'react'
import { Button } from 'antd'

const SaveButton = ({ handleClick, children, disabled }) => {
  return (
    <Button
      htmlType='submit'
      size='large'
      disabled={ disabled }
      onClick={ handleClick }
      className='border-1 rounded-lg text-Primary-50 hover:text-Primary-500 hover:bg-Primary-50 bg-Primary-500 border-Primary-500 hover:border-Primary-500 
                              shadow-sm shadow-Primary-500/5'
    >
      {children}
    </Button>
  )
}

export default SaveButton
