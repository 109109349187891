import { Button, Modal } from "antd";
import { useState } from "react";

const CustomModal = ({
  title,
  isOpen,
  closeModal,
  children,
  buttonName,
  onAddButtonClicked,
  formData,
  setModalOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onAddButtonClickedHandler = () => {
    if (!formData || formData === "") {
      window.alert("Field can not be null");
    }
    onAddButtonClicked({ name: formData });
    setModalOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        open={isOpen}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        centered
        footer={null}
      >
        {children}
        <div className='flex justify-end mt-12'>
          <div
            className='bg-Primary-500 rounded-lg inline-block text-Primary-50 px-4 py-2 font-Inter text-base cursor-pointer border border-Primary-500'
            onClick={onAddButtonClickedHandler}
          >
            {buttonName}
          </div>
        </div>
      </Modal>
    </>
  )
};

export default CustomModal;
