import NavbarButton from "../buttons/navbar-button";
import { useGetTenantDetail } from "../../../hooks/tenant/tenant.hooks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { localization } from "../../../localization/localization.js";
import { GGuidesFullIcon } from "../../../icons/gguides-icon";
import setLanguagePreference from "../../../util/set_language_preference";
import LanguageChangeButton from "../buttons/languate-change-button";

const LoginButton = () => {
  const navigate = useNavigate();
  const redirectLoginPage = () => {
    navigate("/login");
  };
  return (
    <div className="flex bg-Primary-500 font-Inter text-White rounded-md w-28 h-10 mx-auto my-auto">
      <button className="w-full" onClick={redirectLoginPage}>
        {localization("/common/components/navbar/navbar/login")}
      </button>
    </div>
  );
};

const Navbar = ({ children }) => {
  const navigate = useNavigate();

  const buttons = [
    {
      name: localization("/common/components/navbar/navbar/home"),
      linkTo: "/categories",
    },
    {
      name: localization("/common/components/navbar/navbar/guides"),
      linkTo: "/guides",
    },
    {
      name: localization("/common/components/navbar/navbar/databases"),
      linkTo: "/databases",
    },
    {
      name: localization("/common/components/navbar/navbar/authors"),
      linkTo: "/authors",
    },
  ];

  const [selected, setSelected] = useState(window.location.pathname);

  const handleOnClick = (linkTo) => {
    navigate(linkTo);
    setSelected(linkTo);
  };
  const { data: tenantInformations, isLoading: isTenantInformaationsLoading } =
    useGetTenantDetail();

  const handleSetLanguage = () => {
    if (window.localStorage.getItem("languagePreference") === "tr-TR") {
      setLanguagePreference("en-US");
    } else {
      setLanguagePreference("tr-TR");
    }
    window.location.reload();
  };
  return (
    <div className="flex flex-row h-20 py-5 px-12 bg-White">
      <div className="flex flex-row">
        {!isTenantInformaationsLoading && (
          <div className="rounded-full w-20  mr-20">
            <GGuidesFullIcon />
          </div>
        )}

        {buttons.map((button, index) => {
          return (
            <NavbarButton
              key={index}
              buttonName={button.name}
              selected={button.linkTo === selected}
              onClick={() => handleOnClick(button.linkTo)}
            />
          );
        })}
      </div>
      <div className="w-full">
        <div className="float-right flex gap-x-4">
          <LanguageChangeButton handleSetLanguage={handleSetLanguage} />
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
