import { Empty, Spin } from 'antd'

import CategoryCarousel from '../../../common/components/carousel/category-carousel'
import CategoryListView from '../../../common/views/category-list/category-list-view'
import React from 'react'
import { useGetCategories } from '../../../hooks/category/category.hooks'
import { localization } from '../../../localization/localization'

const CategoryListEditorPage = () => {
  const { data: categoriesData, isError, isLoading } = useGetCategories()

  return (
    <div className='absolute left-0 right-0 top-0'>
      <CategoryCarousel />
      <div className='px-11'>
        <h3 className='text-Black text-xl font-Inter mt-6 mb-2'>
          {localization(
            '/pages/app/category-pages/category-list-editor/library-guides-and-databases'
          )}
        </h3>
        <Spin spinning={!isError && isLoading}>
          {categoriesData && (
            <CategoryListView
              logInfo={'/app'}
              categoriesData={categoriesData ? categoriesData : []}
            />
          )}
        </Spin>
        {isError && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
    </div>
  )
}

export default CategoryListEditorPage
