export const WhatsappIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1386 14.382C17.8416 14.233 16.3806 13.515 16.1086 13.415C15.8356 13.316 15.6376 13.267 15.4386 13.565C15.2416 13.862 14.6716 14.531 14.4986 14.729C14.3256 14.928 14.1516 14.952 13.8546 14.804C13.5576 14.654 12.5996 14.341 11.4646 13.329C10.5816 12.541 9.98463 11.568 9.81163 11.27C9.63863 10.973 9.79363 10.812 9.94163 10.664C10.0756 10.531 10.2396 10.317 10.3876 10.144C10.5366 9.97004 10.5856 9.84604 10.6856 9.64704C10.7846 9.44904 10.7356 9.27604 10.6606 9.12704C10.5856 8.97804 9.99163 7.51504 9.74463 6.92004C9.50263 6.34104 9.25763 6.42004 9.07563 6.41004C8.88573 6.40248 8.69568 6.39914 8.50563 6.40004C8.30763 6.40004 7.98563 6.47404 7.71363 6.77204C7.44163 7.06904 6.67363 7.78804 6.67363 9.25104C6.67363 10.713 7.73863 12.126 7.88663 12.325C8.03563 12.523 9.98263 15.525 12.9636 16.812C13.6726 17.118 14.2256 17.301 14.6576 17.437C15.3696 17.664 16.0176 17.632 16.5286 17.555C17.0996 17.47 18.2866 16.836 18.5346 16.142C18.7826 15.448 18.7826 14.853 18.7076 14.729C18.6336 14.605 18.4356 14.531 18.1376 14.382M12.7166 21.785H12.7126C10.9424 21.7852 9.20471 21.3092 7.68163 20.407L7.32063 20.193L3.57963 21.175L4.57763 17.527L4.34263 17.153C3.35307 15.5773 2.82957 13.7537 2.83263 11.893C2.83363 6.44304 7.26863 2.00904 12.7206 2.00904C15.3606 2.00904 17.8426 3.03904 19.7086 4.90704C20.6293 5.82366 21.3591 6.91377 21.8556 8.11428C22.3522 9.3148 22.6058 10.6019 22.6016 11.901C22.5986 17.351 18.1646 21.785 12.7166 21.785ZM21.1296 3.48804C20.0278 2.37896 18.7169 1.49958 17.2727 0.900841C15.8286 0.302105 14.28 -0.00407625 12.7166 4.09775e-05C6.16163 4.09775e-05 0.826633 5.33504 0.823633 11.892C0.823633 13.988 1.37063 16.034 2.41163 17.837L0.723633 24L7.02863 22.346C8.77223 23.296 10.7261 23.7938 12.7116 23.794H12.7166C19.2706 23.794 24.6066 18.459 24.6096 11.901C24.6145 10.3383 24.3094 8.79014 23.7121 7.34607C23.1147 5.90201 22.237 4.59071 21.1296 3.48804Z"
        fill="#515A67"
      />
    </svg>
  );
};

export const TelegramIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.944 3.26667e-05C8.77112 0.0148396 5.73324 1.28566 3.4949 3.53449C1.25656 5.78332 -3.4549e-05 8.82711 7.12435e-10 12C7.12441e-10 15.1826 1.26428 18.2349 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2349 24 15.1826 24 12C24 8.81743 22.7357 5.76519 20.4853 3.51475C18.2348 1.26431 15.1826 3.26667e-05 12 3.26667e-05C11.9813 -1.08889e-05 11.9627 -1.08889e-05 11.944 3.26667e-05ZM16.906 7.22403C17.006 7.22203 17.227 7.24703 17.371 7.36403C17.4667 7.44713 17.5277 7.56311 17.542 7.68903C17.558 7.78203 17.578 7.99503 17.562 8.16103C17.382 10.059 16.6 14.663 16.202 16.788C16.034 17.688 15.703 17.989 15.382 18.018C14.686 18.083 14.157 17.558 13.482 17.116C12.426 16.423 11.829 15.992 10.804 15.316C9.619 14.536 10.387 14.106 11.062 13.406C11.239 13.222 14.309 10.429 14.369 10.176C14.376 10.144 14.383 10.026 14.313 9.96403C14.243 9.90203 14.139 9.92303 14.064 9.94003C13.958 9.96403 12.271 11.08 9.003 13.285C8.523 13.615 8.09 13.775 7.701 13.765C7.273 13.757 6.449 13.524 5.836 13.325C5.084 13.08 4.487 12.951 4.539 12.536C4.566 12.32 4.864 12.099 5.432 11.873C8.93 10.349 11.262 9.34403 12.43 8.85903C15.762 7.47303 16.455 7.23203 16.906 7.22403Z"
        fill="#515A67"
      />
    </svg>
  );
};
