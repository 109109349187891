import { FilterIcon } from "../../../icons/common-icon";
import { Checkbox, Button, Collapse, Row } from "antd";
import { useState } from "react";
import { localization } from "../../../localization/localization";
import "./filter.css";

const { Panel } = Collapse;

const Filter = ({ filterData, getSelections }) => {
  const [count, setCount] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({});

  const getSelectedValues = (selections, filterName) => {
    selectedFilters[filterName] = selections;
    setSelectedFilters({ ...selectedFilters });
    getSelections(selectedFilters);
  };

  return (
    <div className="bg-White font-Inter rounded-lg px-5">
      <div className="flex py-5">
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center text-lg gap-x-3">
            <FilterIcon className="stroke-Grey-500" />
            <div className="text-lg text-Black ">
              {localization("/common/components/filter/filter/filters")}
            </div>
          </div>
        </div>
      </div>
      <Collapse
        expandIconPosition="right"
        bordered={false}
        className="site-collapse-custom-collapse"
      >
        {filterData.map((filter, index) => {
          return (
            <Panel
              onChange={getSelectedValues}
              header={
                <p className="text-lg text-Grey-600 font-medium">
                  {filter.label}
                </p>
              }
              key={`panel-${index}`}
              className="bg-White first-line:site-collapse-custom-panel"
            >
              <Checkbox.Group
                className="flex flex-col gap-y-2"
                onChange={(event) => getSelectedValues(event, filter.name)}
              >
                {filter.data.map((option, index) => {
                  return (
                    <Row
                      className="flex flex-nowrap items-center "
                      key={`check-box-item-${index}`}
                    >
                      <Checkbox
                        id={`${option.id}`}
                        onChange={(e) =>
                          e.target.checked
                            ? setCount(count + 1)
                            : setCount(count - 1)
                        }
                        value={option.id}
                      >
                        <p className="text-Grey-600 cursor-pointer pt-2">
                          {option.name}
                        </p>
                      </Checkbox>
                    </Row>
                  );
                })}
              </Checkbox.Group>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Filter;
