import { useMutation, useQuery } from "react-query";
import {
  getTenantDetail,
  updateTenantDetail,
} from "../../api/tenant/tenant.api";

export const useGetTenantDetail = () => {
  return useQuery("get-tenant-detail", () => {
    return getTenantDetail();
  });
};

export const useUpdateTenantDetail = (onSuccess) => {
  return useMutation(
    "update-tenant-detail",
    (body) => {
      return updateTenantDetail(body);
    },
    onSuccess
  );
};
