import * as api from "../api";

export const getFilteredDatabases = (url) => {
  return api.get(url);
};

export const getFilteredPublicDatabases = (url) => {
  return api.get(url);
};

export const postDatabase = (body) => {
  return api.post(`/database`, body);
};

export const getDatabase = (id) => {
  return api.get(`/database/${id}`);
};

export const updateDatabase = (id, body) => {
  return api.put(`/database/${id}`, body);
};

export const deleteDatabase = (id) => {
  return api.del(`/database/${id}`);
};
