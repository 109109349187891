import {
  AddDatabaseIcon,
  DatabaseIcon,
  SaveIcon,
  TrashIcon,
} from "../../../../../icons/common-icon";
import { Col, Form, Input, Modal, Row, Select, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";

import UpdatePhoto from "../../../../components/photo/update-photo";
import clsx from "clsx";

import slugify from "slugify";
import { useGetDatabaseSubjects } from "../../../../../hooks/database-subject/database-subject.hook";
import { useGetDatabaseTypes } from "../../../../../hooks/database-type/database-type.hooks";
import { useGetVendors } from "../../../../../hooks/vendor/vendor-hooks";
import { useBlob, useUploadFile } from "../../../../../hooks/file/file.hooks";
import defaultPhoto from "../../../../../assets/categories/no-image.jpg";
import { localization } from "../../../../../localization/localization";
import { useGetCategories } from "../../../../../hooks/category/category.hooks";

const DeleteDatabaseWarningModal = ({ onDeleteHandler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnDelete = () => {
    onDeleteHandler();
    setIsModalOpen(false);
  };
  return (
    <>
      <div
        onClick={showModal}
        className="flex flex-row py-2 px-4 mb-3 bg-White border border-Grey-200 rounded-lg justify-between items-center gap-x-2 cursor-pointer"
      >
        <TrashIcon />
        {localization("/common/views/app/forms/database/database-form/delete")}
      </div>
      <Modal
        centered
        open={isModalOpen}
        cancelButtonProps={false}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <div className="">
          <div className="flex flex-row gap-x-1.5 mb-6">
            <div className="basis-1/4">
              <DatabaseIcon
                className="warning-500"
                iconColor={"fill-Error-50"}
                backgroundColor={"fill-Error-50"}
                strokeColor={"stroke-Error-600"}
              />
            </div>
            <div className="font-Inter">
              <p className="text-base text-Black">
                {localization(
                  "/common/views/app/forms/database/database-form/delete-database"
                )}
              </p>
              <p className="text-sm text-Grey-600">
                {localization(
                  "/common/views/app/forms/database/database-form/deletion-warn"
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-x-6">
            <div
              onClick={handleCancel}
              className="px-4 py-2 border border-Grey-200 rounded-lg bg-White text-Grey-600 text-center cursor-pointer"
            >
              {localization(
                "/common/views/app/forms/database/database-form/cancel"
              )}
            </div>
            <div
              onClick={handleOnDelete}
              className="px-4 py-2 border border-Grey-200 rounded-lg bg-Error-500 text-White text-center cursor-pointer"
            >
              {localization(
                "/common/views/app/forms/database/database-form/confirm"
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};

const UpdateButton = ({ onDeleteHandler, isUpdateForm }) => {
  return (
    <div className="flex flex-row">
      <button
        type="submit"
        className="px-2 py-1 rounded-lg bg-Primary-500  flex-row justify-around inline-flex gap-x-3 cursor-pointer mb-3"
      >
        <div className="m-auto">
          <SaveIcon />
        </div>
        <p className="text-White font-Inter text-base m-auto">
          {localization("/common/views/app/forms/database/database-form/save")}
        </p>
      </button>
      {isUpdateForm ? (
        <div className="w-full">
          <div className="float-right">
            <DeleteDatabaseWarningModal onDeleteHandler={onDeleteHandler} />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const DatabaseForm = ({
  data,
  formTitle,
  formStatement,
  isUpdateForm,
  onSubmitHandler,
  onDeleteHandler,
}) => {
  const { data: vendors, isLoading: isVendorsLoading } = useGetVendors(0, 40);
  const { data: subjects, isLoading: isSubjectsLoading } =
    useGetDatabaseSubjects(0, 40);
  const { data: categories, isLoading: isCategoriesLoading } =
    useGetCategories();

  const { data: databaseTypes, isLoading: isDatabaseLoading } =
    useGetDatabaseTypes(0, 40);

  const [form] = Form.useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [userManual, setUserManual] = useState(null);
  const [userManuelBlob, setUserManuelBlob, isDownloading] = useBlob(
    data?.userManual?.id
  );
  const { mutate: uploadFileMutation } = useUploadFile();

  const labelOptions = {
    false: localization("/common/views/app/forms/database/database-form/close"),
    true: localization("/common/views/app/forms/database/database-form/open"),
  };

  const onFinish = (values) => {
    const body = {
      description: "Empty Description",
      sorting: "TRIAL",
      status: "PUBLIC",
      name: values.databaseName,
      visible: values.visible,
      vendorId: values.vendors,
      typeIds: values.types,
      subjectIds: values.subjects,
      categoryIds: values.categories,
      url: values.databaseUrl,
      status: values.status,
      description: values.description,
      friendlyUrl: slugify(values.friendlyUrl, {
        lower: true,
        remove: /[^a-z0-9 -]/g,
      }),
    };
    if (userManual) {
      const formData = new FormData();
      formData.append("file", userManual);
      uploadFileMutation(formData, {
        onSuccess: (data) => {
          body.userManualId = data.id;
          onSubmitHandler(body);
        },
      });
    } else {
      if (userManual === false) {
        body.userManualId = null;
      } else {
        body.userManualId = data?.userManual?.id;
      }

      onSubmitHandler(body);
    }
  };

  const onVisibilityChangeHandler = () => {
    setIsVisible(!isVisible);
  };

  const getSubjects = (data) => {
    const subjectArray = [];
    for (let i = 0; i < data.subjects.length; i++) {
      subjectArray.push(data.subjects[i].id);
    }
    return subjectArray;
  };

  const getCategories = (data) => {
    var selections = [];
    data?.categories?.forEach((element) => {
      selections.push(element.id);
    });
    return selections;
  };

  const getTypes = (data) => {
    const typesArray = [];
    for (let i = 0; i < data.types.length; i++) {
      typesArray.push(data.types[i].id);
    }
    return typesArray;
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        databaseName: data.name,
        vendors: data.vendor.id,
        databaseUrl: data.url,
        types: getTypes(data),
        categories: getCategories(data),
        subjects: getSubjects(data),
        visible: data.visible,
        status: data.status,
        description: data.description,
        friendlyUrl: data?.friendlyUrl,
      });
    } else {
      form.setFieldValue({ visible: false });
    }
    setIsVisible(data?.visible ?? false);
  }, [data]);

  return (
    <div>
      {databaseTypes && subjects && vendors && categories ? (
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          layout={"vertical"}
          className="w-full"
        >
          <UpdateButton
            isUpdateForm={isUpdateForm}
            onDeleteHandler={onDeleteHandler}
          />

          <div className="bg-White p-6 rounded-md">
            <div className="flex flex-row gap-x-3 mb-6">
              <div className="p-3 bg-Secondary-100 rounded-full inline-block my-auto">
                <AddDatabaseIcon
                  width={22}
                  height={23}
                  className="stroke-Secondary-700"
                />
              </div>
              <div className="w-full">
                <label className="text-Black font-Inter text-base block">
                  {formTitle}
                </label>
                <p className="text-Grey-600 font-Inter text-sm block">
                  {formStatement}
                </p>
              </div>
              <div className="w-full  flex flex-row gap-x-3">
                <div className="w-full">
                  <div className="float-right">
                    <label className="text-Black font-Inter text-base">
                      {localization(
                        "/common/views/app/forms/database/database-form/visibility"
                      )}{" "}
                      {labelOptions[form.getFieldValue("visible")]}
                    </label>
                    <p className="text-Grey-600 font-Inter text-sm">
                      {localization(
                        "/common/views/app/forms/database/database-form/accessible"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <Form.Item
                name="visible"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  defaultChecked={false}
                  onChange={onVisibilityChangeHandler}
                  className={clsx(
                    isVisible ? "bg-Success-600" : "bg-Primary-50"
                  )}
                />
              </Form.Item>
            </div>
            <Form.Item
              name="databaseName"
              label={
                <p className="font-semibold text-sm">
                  {localization(
                    "/common/views/app/forms/database/database-form/database-name"
                  )}
                </p>
              }
              className="font-Inter ant-col-24"
              rules={[
                {
                  required: true,
                  message: localization(
                    "/common/views/app/forms/database/database-form/enter-database"
                  ),
                },
              ]}
            >
              <Input
                placeholder={localization(
                  "/common/views/app/forms/database/database-form/title"
                )}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={
                <p className="font-semibold text-sm">
                  {localization(
                    "/common/views/app/forms/database/database-form/description"
                  )}
                </p>
              }
              className="font-Inter ant-col-24"
              rules={[
                {
                  required: true,
                  min: 20,
                  message: localization(
                    "/common/views/app/forms/database/database-form/description-error"
                  ),
                },
                {
                  required: true,
                  message: localization(
                    "/common/views/app/forms/database/database-form/double-space-error"
                  ),
                  pattern: new RegExp(/^((?!\s{2}).)*$/),
                },
              ]}
            >
              <Input
                placeholder={localization(
                  "/common/views/app/forms/database/database-form/guide-description"
                )}
              />
            </Form.Item>
            <Form.Item
              label={
                <p className="font-semibold text-sm">
                  {localization(
                    "/common/views/app/forms/database/database-form/database-vendor"
                  )}
                </p>
              }
              className="font-Inter"
              name="vendors"
              rules={[
                {
                  required: true,
                  message: localization(
                    "/common/views/app/forms/database/database-form/select-vendor"
                  ),
                },
              ]}
            >
              <Select key={"vendor-select"} name="selected-vendor" showArrow>
                {vendors.content.map((vendor) => {
                  return (
                    <Select.Option key={vendor.id} value={vendor.id}>
                      {vendor.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              className="font-Inter ant-col-24"
              name="databaseUrl"
              label={
                <p className="font-semibold text-sm">
                  {localization(
                    "/common/views/app/forms/database/database-form/database-url"
                  )}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: localization(
                    "/common/views/app/forms/database/database-form/database-url-error"
                  ),
                },
                {
                  type: "url",
                  warningOnly: true,
                },
              ]}
            >
              <Input
                placeholder={localization(
                  "/common/views/app/forms/database/database-form/add-connection"
                )}
              />
            </Form.Item>
            <Row gutter={[12, 12]}>
              <Col span={8}>
                <Form.Item
                  name="subjects"
                  label={
                    <p className="font-semibold text-sm">
                      {localization(
                        "/common/views/app/forms/database/database-form/database-subjects"
                      )}
                    </p>
                  }
                  labelCol={24}
                  className="font-Inter"
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/common/views/app/forms/database/database-form/choose-subject"
                      ),
                    },
                  ]}
                >
                  <Select
                    key={"subjects-select"}
                    name="selected-subjects"
                    mode="multiple"
                    showArrow
                  >
                    {subjects.content.map((subject) => {
                      return (
                        <Select.Option key={subject.id} value={subject.id}>
                          {subject.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="categories"
                  label={
                    <p className="font-semibold text-sm">
                      {localization(
                        "/common/views/app/forms/database/database-form/database-categories"
                      )}
                    </p>
                  }
                  labelCol={24}
                  className="font-Inter"
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/common/views/app/forms/database/database-form/choose-category"
                      ),
                    },
                  ]}
                >
                  <Select
                    key={"categories-select"}
                    name="selected-categories"
                    mode="multiple"
                    showArrow
                  >
                    {categories.map((category) => {
                      return (
                        <Select.Option key={category.id} value={category.id}>
                          {category.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="types"
                  label={
                    <p className="font-semibold text-sm">
                      {localization(
                        "/common/views/app/forms/database/database-form/database-types"
                      )}
                    </p>
                  }
                  labelCol={24}
                  className="font-Inter"
                  rules={[
                    {
                      required: true,
                      message: localization(
                        "/common/views/app/forms/database/database-form/choose-type"
                      ),
                    },
                  ]}
                >
                  <Select
                    key={"type-select"}
                    name="selected-types"
                    mode="multiple"
                    showArrow
                  >
                    {databaseTypes.content.map((type) => {
                      return (
                        <Select.Option key={type.id} value={type.id}>
                          {type.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="status"
              label={
                <p className="font-semibold text-sm">
                  {localization(
                    "/common/views/app/forms/database/database-form/database-status"
                  )}
                </p>
              }
              labelCol={24}
              className="font-Inter"
              rules={[
                {
                  required: true,
                  message: localization(
                    "/common/views/app/forms/database/database-form/choose-status"
                  ),
                },
              ]}
            >
              <Select showArrow name="status-selection">
                {["NEW", "TRIAL", "NONE"].map((status) => {
                  return (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="friendlyUrl"
              required
              rules={[
                {
                  required: true,
                },
              ]}
              className="font-Inter ant-col-24"
              label={
                <p className="text-base font-semibold">
                  {localization(
                    "/common/components/guide/guide-form/define-friendly-url-prompt"
                  )}
                </p>
              }
              initialValue={data?.friendlyUrl}
            >
              <Input
                onChange={(e) => {
                  slugify(e.target.value);
                }}
                placeholder={localization(
                  "/common/components/guide/guide-form/define-friendly-url"
                )}
                rules={[{ required: true }, { warningOnly: true }]}
              />
            </Form.Item>
            <Form.Item
              name="userManual"
              label={localization(
                "/common/views/app/forms/database/database-form/select-database-document"
              )}
              className="font-Inter ant-col-24"
            >
              <Spin spinning={isDownloading}>
                <UpdatePhoto
                  setBlobPhoto={setUserManuelBlob}
                  setPhotoId={setUserManual}
                  blobPhoto={userManuelBlob}
                  defaultPhoto={defaultPhoto}
                />
              </Spin>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default DatabaseForm;
