import React, { useState } from "react";
import { Link } from "react-router-dom";
import CategoryCardButton from "../buttons/category-card-button";
import { localization } from "../../../localization/localization";
import { useBlob } from "../../../hooks/file/file.hooks";
import { Spin } from "antd";
import background from "../../../assets/categories/background.jpg";

const CategoryCard = ({ categoryId, photoId, title, logInfo }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [image, isLoading] = useBlob(photoId);

  return (
    <Spin spinning={isLoading}>
      <div
        className={`relative overflow-hidden rounded-md h-72 w-52 mr-4 mt-4 ${
          !image ? "border border-Grey-300 shadow-sm" : ""
        }`}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        <div
          className={
            showButtons
              ? "blur-md relative flex h-full w-full bg-contain bg-fixed"
              : "flex h-full w-full bg-contain bg-fixed "
          }
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <img className="flex-1" src={image} />

          <div className="absolute flex justify-center bottom-0 right-0 left-0 p-5 text-White bg-White  bg-opacity-30 backdrop-blur-md  border-t border-White border-opacity-50 ">
            {title}
          </div>
        </div>
        {showButtons && (
          <div className="absolute inset-0 flex flex-col items-center justify-between font-Inter">
            <h3 className="mt-4  font-semibold text-sm font-Inter">{title}</h3>
            <ButtonGroup
              categoryId={categoryId}
              logInfo={logInfo}
              title={title}
            />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default CategoryCard;

const ButtonGroup = ({ logInfo, categoryId }) => {
  return (
    <div className="flex flex-col my-auto w-full gap-4 px-4 ">
      <Link
        className="h-10"
        to={`${logInfo ? logInfo : ""}` + `/guide-category/${categoryId}`}
      >
        <CategoryCardButton
          title={localization("/common/components/cards/category-card/guides")}
        />
      </Link>

      <Link
        className="h-10"
        to={`${logInfo ? logInfo : ""}` + `/database-category/${categoryId}`}
      >
        <CategoryCardButton
          title={localization(
            "/common/components/cards/category-card/databases"
          )}
        />
      </Link>
    </div>
  );
};
