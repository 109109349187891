import { Button, Form, Input } from 'antd'
import React from 'react'
import { localization } from '../../../localization/localization'
import Dragger from 'antd/lib/upload/Dragger'
import SaveButton from '../buttons/save-button'

const CategoryForm = ({
  form,
  initialValues,
  handleOk,
  disabled,
  setCategoryPhoto,
}) => {
  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    accept: 'image/png, image/jpeg',
    maxCount: 1,
    showUploadList: {
      showRemoveIcon: true,
    },

    beforeUpload: (file) => {
      setCategoryPhoto(file)
      return false
    },

    itemRender: (existingComp, file) => {
      return (
        <div className='flex flex-col'>
          <div
            className='self-center bg-Grey-50 rounded-md mt-6'
            style={{ width: '212px', height: '282px' }}
          >
            <img
              className='object-fill w-full h-full'
              src={file.thumbUrl}
              alt='uploaded-img'
            />
          </div>
        </div>
      )
    },
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      className='flex flex-col'
      onFinish={handleOk}
    >
      <p className='mb-1 text-Black font-bold	text-base'>
        {localization(
          '/pages/app/category-pages/edit-categories/category-name'
        )}
      </p>
      <Form.Item
        className='mb-6'
        rules={[
          { required: true, message: 'Please input your category name!' },
        ]}
        name={['name']}
      >
        <Input
          className='rounded-md border-1 border-Grey-100'
          size='large'
          placeholder={'Örn: Sanat ve Tasarım'}
        />
      </Form.Item>

      <Form.Item className='mb-6 font-Inter' name={['photoId']}>
        <span>
          <p className='mb-1 text-Black font-bold	text-base'>
            {localization(
              '/pages/app/category-pages/edit-categories/category-photo'
            )}
          </p>

          <Dragger {...props} style={{ padding: '0px 12px' }}>
            <p className='text-Grey-500 font-normal text-sm'>
              {localization(
                '/pages/app/category-pages/edit-categories/drag-drop-info'
              )}
            </p>
            <Button
              size='large'
              className='my-2 border-1 border-Grey-200 shadow-sm shadow-Primary-500/5 rounded-lg text-Primary-500 hover:bg-Primary-50 hover:text-Primary-500 hover:border-Grey-200 hover:border-1'
            >
              {localization(
                '/pages/app/category-pages/edit-categories/choose-a-file'
              )}
            </Button>
            <p className='text-Grey-500 font-normal text-sm'>
              {localization(
                '/pages/app/category-pages/edit-categories/category-photo-format'
              )}
            </p>
          </Dragger>
        </span>
      </Form.Item>
      <span className='self-end mb-0 mt-2'>
        <Form.Item className='mb-0'>
          <SaveButton disabled={disabled}>
            {initialValues ? `Kaydet` : `Oluştur `}
          </SaveButton>
        </Form.Item>
      </span>
    </Form>
  )
}

export default CategoryForm
