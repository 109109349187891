import {
  deleteCategory,
  getCategories,
  getCategory,
  postCategory,
  updateCategory,
} from '../../api/category/category.api'
import { useMutation, useQuery } from 'react-query'

import { notification } from 'antd'
import { localization } from '../../localization/localization'

export function useCreateCategory(onSuccess, onError) {
  return useMutation(
    'post-category',
    (body) => {
      return postCategory(body)
    },
    {
      onSuccess,
      onError,
    }
  )
}

export const useDeleteCategory = (onSuccess) => {
  return useMutation(
    'delete-category',
    (id) => {
      return deleteCategory(id)
    },
    {
      onSuccess,
      onError: (error) => {
        notification.error({
          description: error.message,
          message: localization(
            '/hooks/category/category.hooks/delete-failed'
          ),
        })
      },
    }
  )
}

export const useUpdateCategory = (id,onSuccess) => {
  return useMutation(
    'update-category',
    (body) => {
      return updateCategory(id,body)
    },
    {
      onSuccess
    }
  )
}

export const useGetCategories = () => {
  return useQuery('get-categories', () => {
    return getCategories()
  })
}
export const useGetCategory = (categoryId) => {
  return useQuery('get-category', () => {
    return getCategory(categoryId)
  })
}
