import React from "react";
import { GGuidesFullIcon } from "../../../icons/gguides-icon";
import { LoginPageIcon } from "../../../icons/common-icon";
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../../../hooks/auth/auth-hooks";
import { localization } from "../../../localization/localization";

const LoginPage = () => {
  const navigate = useNavigate()

  const onSuccessRedirectUser = () => {
    navigate("/app/categories");
  };
  const { mutate } = useLogin(onSuccessRedirectUser);

  const onSubmit = (formData) => {
    mutate(formData);
  };

  return (
    <>
      <div className="bg-White flex flex-row">
        <div className="flex h-screen basis-1/2">
          <div className="m-auto">
            <div className="flex w-120 h-156 bg-White">
              <div className="my-auto mx-16">
                <div className="w-88">
                  <GGuidesFullIcon />
                  <div className="mt-8 pb-2 mb-12">
                    <h className="font-Inter text-2xl font-extrabold">{localization("/pages/public/auth-pages/login/login")}</h>
                    <h className="font-Inter text-Grey-400 block text-lg mt-2.5">
                      {localization("/pages/public/auth-pages/login/readers-waiting")}
                    </h>
                  </div>
                  <Form name="loginForm" onFinish={onSubmit} layout="vertical">
                    <Form.Item
                      name="email"
                      label={localization("/pages/public/auth-pages/login/email")}
                      className="font-Inter text-Black text-base"
                      style={{ fontSize: "16px" }}
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: localization("/pages/public/auth-pages/login/invalid-email"),
                        },
                      ]}
                    >
                      <Input placeholder={localization("/pages/public/auth-pages/login/example-email")} />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label={localization("/pages/public/auth-pages/login/password")}
                      className="font-Inter text-Black text-base"
                      style={{ fontSize: "16px" }}
                      rules={[
                        {
                          required: true,
                          type: "string",
                          min: 6,
                          message: localization("/pages/public/auth-pages/login/password-length"),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={localization("/pages/public/auth-pages/login/password-rules")}
                        type="password"
                      />
                    </Form.Item>
                    <div className="float-right">
                      <Button className="text-right mb-12 border-none cursor-default inline">
                        <Link to="/forgot-password">
                          <p className="underline text-Black text-base cursor-pointer inline-block">
                            {localization("/pages/public/auth-pages/login/forgot-password")}
                          </p>
                        </Link>
                      </Button>
                    </div>

                    <Button
                      size="large"
                      htmlType="submit"
                      className="bg-Primary1-500 rounded-md w-full text-White font-Inter"
                    >
                      <p className="text-base cursor-pointer inline-block">
                        {localization("/pages/public/auth-pages/login/login-button")}
                      </p>
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-Primary1-700 basis-1/2 flex ">
          <div className="my-auto">
            <div className="flex flex-col mx-20 gap-y-10">
              <p className="mx-auto w-3/5 text-center  text-[#64BEF9] font-Inter text-5xl block">
                {localization("/pages/public/auth-pages/login/easy-content-creation")}
              </p>
              <div className="mx-auto">
                <LoginPageIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
