import { Button, Modal, notification } from 'antd'
import React from 'react'
import { useDeleteCategory, useGetCategories } from '../../../hooks/category/category.hooks'
import { localization } from '../../../localization/localization';
import { useNavigate } from 'react-router-dom';

const CategoryDeleteModal = ({ childId, isOpen, setOpen }) => {
  const navigate = useNavigate()

  const { refetch:refetchAllCategories } = useGetCategories()

  const { mutate:mutateDeleteCategory } = useDeleteCategory((element)=>{
    setOpen(false)
    notification.success({
      message:"Success",
      description:`${element.name} is successfully deleted`
    })
    refetchAllCategories()
    handleParentPageDelete()
  })

  const handleParentPageDelete = () => {
    const pathId = window.location.pathname.split("/")[3];
    if (pathId === childId) {
      navigate(-1);
    }
  }

  const handleClick = () => {
    mutateDeleteCategory(childId)
  }

  return (
    <Modal
      centered
      onCancel={() => {
        setOpen(false);
      }}
      open={isOpen}
      setOpen={setOpen}
      footer={null}
      closable={false}
    >
      <div className="flex flex-col">
        <div className="flex items-center mb-6">
          <div className="ml-3 font-Inter flex-1">
            <div className="font-medium text-Black text-base text-center">
              {localization(
                "/common/components/category/category-delete-modal/are-you-sure"
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between ml-3">
          <Button
            onClick={handleClick}
            className="rounded-lg bg-Primary-500 text-Primary-50 border border-Primary-500 hover:bg-Primary-50 hover:border hover:border-Primary-500 hover:text-Primary-500"
          >
            {localization(
              "/common/components/category/category-delete-modal/submit"
            )}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            className="rounded-lg bg-Secondary-50 text-Secondary-500 border border-Primary-50 hover:bg-Secondary-500 hover:border hover:border-Secondary-500 hover:text-Primary-50"
          >
            {localization(
              "/common/components/category/category-delete-modal/cancel"
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CategoryDeleteModal