import clsx from 'clsx'

const NavbarButton = ({ buttonName, selected, onClick }) => {
	return (
		<div
			className={clsx(
				' font-Inter text-Primary-500 rounded-lg flex-none',
				selected && 'bg-Primary-50 text-Primary-500'
			)}
		>
			<button onClick={onClick} className='px-4 py-2 outline-none '>
				{buttonName}
			</button>
		</div>
	)
}
export default NavbarButton
