import { Button, Form } from "antd";
import clsx from "clsx";
import { useRef } from "react";
import {localization} from "../../../localization/localization";

const SearchBar = ({ className, placeholder, onSearchClicked }) => {
  const ref = useRef();
  return (
    <>
      <div className="basis-1/2 flex flex-row  gap-x-6">
        <Form onFinish={() => onSearchClicked(ref.current.value)} className={clsx("flex items-center bg-White w-1/3", className)}>
          <div className="relative w-full">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              ref={ref}
              type="text"
              id="simple-search"
              className="border border-Grey-100 text-Grey-500 font-Inter text-base rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
              placeholder={
                placeholder ? placeholder : localization("/common/components/searchBar/search-bar/search-guide")
              }
            />
          </div>
        </Form>
        <Button
          className="bg-Primary-500 text-White font-Inter text-base font-medium my-auto rounded-md"
          size="large"
          onClick={() => onSearchClicked(ref.current.value)}
        >
          {localization("/common/components/searchBar/search-bar/search")}
        </Button>
      </div>
    </>
  );
};

export default SearchBar;
