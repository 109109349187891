import React from "react";
import { Spin } from 'antd'

const IcoProfile = ({ label, image, isImageLoading, width, height}) => {
  return (
    <div className="flex gap-x-2">
      <div className="flex flex-nowrap my-auto font-Inter text-sm text-Grey-500 ">
        {label}
      </div>
      <Spin spinning={isImageLoading}>
        <div className={`w-8 h-8`}>
          <img alt="profile_ico" className="rounded-full block w-full h-full" src={image} />
        </div>
      </Spin>
    </div>
  );
};

export default IcoProfile;
