import React from 'react'

const CategoryCardButton = ({ title }) => {
	return (
		<button className='w-full h-full  bg-Primary-50  shadow-sm rounded-lg '>
			<p className='text-Primary-500 hover:text-Primary-400 font-Inter font-medium text-base '>{title}</p>
		</button>
	)
}

export default CategoryCardButton
