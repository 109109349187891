import React from 'react'
import AuthorCard from './author-card';
import { localization } from '../../../localization/localization';

const AuthorListView = ({ authorData, isAuthorDataLoading }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      {isAuthorDataLoading ? (
        <div>Loading...</div>
      ) : (
        authorData.content.map((author, index) => {
          return (
            <AuthorCard
              key={`author-${index}`}
              authorName={author.name + " " + author.surname}
              authorMail={author?.email}
              title={localization(
                "/pages/app/author-pages/author-list-editor/librarian"
              )}
              pageLink={`/app/author-detail/${author.id}`}
              photoId={author.photo?.id ?? null}
            />
          );
        })
      )}
    </div>
  );
};

export default AuthorListView