import { BadgeDatabase, BadgeSuccess } from "../../components/buttons/badge";
import {
  EditIcon,
  ExternalAccessButtonIcon,
  DotIcon,
} from "../../../icons/common-icon";
import { FilePdfOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";

import { Link } from "react-router-dom";
import { ShareButtonIcon } from "../../../icons/info-box-js";
import ShareOnSocialMedia from "../../components/buttons/share-buttons";
import { useState } from "react";
import { localization } from "../../../localization/localization";

const DatabaseInformationBox = ({
  title,
  id,
  friendlyUrl,
  text,
  tagList,
  databaseStatus,
  sourceName,
  types,
  databaseUrl,
  databaseUserManual,
  databaseDescription,
  databaseVendorName,
  isEdit,
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState();
  const onShareClick = () => {
    setIsShareModalOpen((prevState) => {
      return !prevState;
    });
  };
  return (
    <Row className="bg-White rounded-md px-6 py-3 flex justify-between">
      <Col span={12}>
        <Row>
          {types.map((type, i, {length}) => {
            return (
              <p className="text-Grey-600 font-normal font-Inter">
                {type.name}
                {i + 1 !== length ? "," : ""}
              </p>
            );
          })}
        </Row>
        <Row className="mt-2">
          <p className="text-Black text-lg font-Inter"> {title}</p>
          <Tooltip
            className="ml-2 my-auto"
            title={
              databaseDescription ??
              localization("/common/views/info-box/database-box/no-description")
            }
          >
            <InfoCircleTwoTone />
          </Tooltip>
        </Row>
        <Row className="mt-2">
          <div>
            <div className="flex gap-x-2 basis-3/6">
              {databaseStatus === "NEW" ? (
                <div className="my-auto">
                  <BadgeSuccess
                    leftChildren={<DotIcon />}
                    rightChildren={localization(
                      "/common/views/info-box/database-box/new"
                    )}
                  />
                </div>
              ) : databaseStatus === "TRIAL" ? (
                <div className="my-auto">
                  <BadgeDatabase leftChildren="*Trial" />
                </div>
              ) : (
                <div className="hidden"></div>
              )}
              {tagList.map((item, index) => {
                return <BadgeDatabase key={index} leftChildren={item.name} />;
              })}
            </div>

            <div className="mt-3 text-Grey-500 text-sm font-Inter">
              <Row>{databaseVendorName}</Row>
            </div>
          </div>
        </Row>
      </Col>

      <Col
        span={12}
        md={4}
        className="flex justify-center md:justify-start items-center"
      >
        {databaseUserManual?.url && (
          <div className="w-12 h-12 rouned-md">
            {databaseUserManual?.url && (
              <a href={databaseUserManual.url} target="_blank" rel="noreferrer">
                {databaseUserManual.contentType === "application/pdf" ? (
                  <div className="text-3xl">
                    <FilePdfOutlined />
                  </div>
                ) : (
                  <img
                    className="object-fill h-full w-full"
                    src={databaseUserManual.url}
                    alt={databaseUserManual.name}
                  />
                )}
              </a>
            )}
          </div>
        )}
      </Col>

      <Col span={24} md={4} className="mt-1 ">
        <Col className="flex justify-start md:justify-end">
          <div className="mr-3">
            {isEdit && (
              <Link
                to={`/app/update-database/${friendlyUrl ? friendlyUrl : id}`}
                className="cursor-pointer"
              >
                <EditIcon />
              </Link>
            )}
          </div>
          <div className="cursor-pointer" onClick={onShareClick}>
            <ShareButtonIcon />
            <ShareOnSocialMedia
              header={localization(
                "/common/views/info-box/database-box/share-database"
              )}
              title={title}
              url={window._env_.BASE_URL + "/databases"}
              isModalOpen={isShareModalOpen}
            />
          </div>
        </Col>
        <Col className="flex mt-3 md:mt-0">
          <div className="flex">
            <a
              id={id}
              href={databaseUrl}
              className=" rounded-lg border p-2 border-Primary-500"
              target="_blank"
            >
              <ExternalAccessButtonIcon />
            </a>
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default DatabaseInformationBox;
