import * as api from "../api";

export const getFilteredGuides = (url) => {
  return api.get(url);
};

export const getPublicGuide = (id) => {
  return api.get(`/guide/${id}`);
};

export const getFilteredPublicGuides = (url) => {
  return api.get(url);
};

export const updateGuide = (body, id) => {
  return api.put(`/guide/${id}`, body);
};

export const postGuide = (body) => {
  return api.post(`/guide`, body);
};

export const deleteGuide = (id) => {
  return api.del(`/guide/${id}`);
};
