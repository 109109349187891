import { useMutation } from "react-query";
import { downloadFile, postFile } from "../../api/file/file.api";
import { useEffect, useState } from "react";

export const useUploadFile = () => {
  return useMutation(
    "upload-file",
    (multipartFile) => {
      return postFile(multipartFile);
    },
    { onSuccess: (data) => data }
  );
};

export const useDownloadFile = (onSuccess) => {
  return useMutation(
    "download-file",
    (id) => {
      return downloadFile(id);
    },
    {
      onSuccess: onSuccess,
    }
  );
};

export const useBlob = (dataPhotoId) => {
  const [blobObject, setBlobObject] = useState(null);
  const { mutate: downloadImage, isLoading } = useDownloadFile();

  useEffect(() => {
    if (dataPhotoId) {
      downloadImage(dataPhotoId, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setBlobObject(bufferObject);
        },
        onError: () => {
          setBlobObject(null);
        },
      });
    }
  }, [dataPhotoId]);

  return [blobObject, setBlobObject, isLoading];
};
