import {
  AddGuideIcon,
  EditIcon,
  InfoIcon,
  TrashIcon,
} from "../../../icons/common-icon";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  message,
  notification,
} from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import slugify from "slugify";
import "./guide-form.css";
import BasicModal from "../modal/basic-modal";
import EmailEditor from "react-email-editor";
import GuideBox from "./guide-box";
import clsx from "clsx";
import { useDeleteGuide } from "../../../hooks/guide/guide.hooks";
import { localization } from "../../../localization/localization";
import getLanguagePreference from "../../../util/get-language-preference";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import {
  downloadFile,
  downloadFileEditor,
  postFile,
} from "../../../api/file/file.api";
import { useDownloadFile } from "../../../hooks/file/file.hooks";
import MailEditor from "../../../pages/app/mail-editor";

export const CreateGuideManagementButtons = ({ isEditPage, id, pageData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const { mutate: mutateDeleteGuide } = useDeleteGuide(
    () => {
      notification.success({
        message: localization(
          "/common/components/guide/guide-form/deletion-successful"
        ),
        description: localization(
          "/common/components/guide/guide-form/deletion-success"
        ),
      });
      navigate("../app/guides");
    },
    (response) => {
      notification.error({
        message: localization(
          "/common/components/guide/guide-form/deletion-failed"
        ),
        description: response,
      });
    }
  );

  const onRemoveButtonClicked = () => {
    mutateDeleteGuide(id);
  };

  return (
    <Row gutter={[0, 24]} className="mb-6">
      <Col span={24}>
        <Row gutter={128}>
          <Col span={2}>
            <Button
              size="large"
              className="bg-Primary-500 rounded-lg text-White font-Inter"
              htmlType="submit"
            >
              {localization("/common/components/guide/guide-form/save-button")}
            </Button>
          </Col>

          {isEditPage && (
            <>
              <Col span={18}>
                <Button
                  onClick={() => setModalOpen(true)}
                  size="large"
                  className="bg-White rounded-lg text-Primary-500 font-Inter"
                >
                  {localization(
                    "/common/components/guide/guide-form/delete-button"
                  )}
                </Button>
              </Col>

              <Modal
                onCancel={() => setModalOpen(false)}
                open={isModalOpen}
                footer={null}
                closable
                centered
              >
                <div className="flex flex-col px-6 gap-4">
                  <p className="text-center text-base font-medium">
                    {localization(
                      "/common/components/guide/guide-form/are-u-sure-guide"
                    )}
                  </p>
                  <div className="flex flex-1 justify-between">
                    <Button
                      size="large"
                      className="rounded-lg bg-Primary-500 text-Secondary-100"
                      onClick={onRemoveButtonClicked}
                    >
                      {localization("/common/components/guide/guide-form/yes")}
                    </Button>
                    <Button
                      size="large"
                      className="rounded-lg bg-Primary-500 text-Secondary-100"
                      onClick={() => setModalOpen(false)}
                    >
                      {localization("/common/components/guide/guide-form/no")}
                    </Button>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export const GuideInformationForm = ({
  values,
  guidesData,
  isMulti,
  isUrl,
  placeholder,
  options,
  fieldName,
  setForm,
}) => {
  const onChange = (event) => {
    var value = event.target.value;
    guidesData[fieldName] = isUrl ? slugify(value) : value;
    setForm({ ...guidesData });
  };
  const onMultiSelectChange = (value) => {
    var data = { ...guidesData };
    data[fieldName] = value;
    setForm(data);
  };

  return (
    <>
      {!isMulti ? (
        <div>
          <Input
            style={{ borderRadius: "4px" }}
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={values}
            required
          />
        </div>
      ) : (
        <div>
          <Select
            className="border border-Grey-200"
            style={{ borderRadius: "4px" }}
            mode="multiple"
            bordered={false}
            onChange={onMultiSelectChange}
            placeholder={placeholder}
            defaultValue={values}
            showArrow
          >
            {options?.map((option) => {
              return (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      )}
    </>
  );
};

export const GuideMetaDataForm = ({
  databaseTypes,
  subjects,
  guidesData,
  setForm,
  categories,
}) => {
  const [isVisible, setIsVisible] = useState(guidesData.visible ?? false);
  const [informationAlertVisible, setInformationAlertVisible] = useState(false);
  const onVisibilityChange = () => {
    setIsVisible(!isVisible);
    guidesData.visible = isVisible;
    setForm(guidesData);
  };

  const getSeletedGuideTypes = (key) => {
    var selections = [];
    guidesData[key]?.forEach((element) => {
      selections.push(element.id);
    });
    return selections;
  };

  return (
    <>
      <div className="flex flex-row gap-x-3 mb-8 bg-White">
        <div className="p-3 bg-Secondary-100 rounded-full inline-block mb-auto">
          <AddGuideIcon
            className={"stroke-Secondary-600"}
            width={22}
            height={23}
          />
        </div>
        <div className="w-full">
          <label className="text-Black font-Inter text-base block">
            {localization("/common/components/guide/guide-form/new-guide")}
          </label>
          <p className="text-Grey-600 font-Inter text-sm block">
            {localization("/common/components/guide/guide-form/manage-guides")}
          </p>
        </div>
        <div className="w-full  flex flex-row gap-x-3">
          <div className="w-full">
            <div className="float-right">
              <label className="text-Black font-Inter text-base">
                {localization("/common/components/guide/guide-form/visibility")}{" "}
                {isVisible
                  ? localization("/common/components/guide/guide-form/open")
                  : localization("/common/components/guide/guide-form/close")}
              </label>
              <p className="text-Grey-600 font-Inter text-sm">
                {localization(
                  "/common/components/guide/guide-form/access-granted"
                )}
              </p>
            </div>
          </div>
        </div>

        <Form.Item
          name="visible"
          valuePropName="checked"
          initialValue={isVisible}
          className="m-0"
        >
          <Switch
            name="visible"
            defaultChecked={isVisible}
            onChange={onVisibilityChange}
            className={clsx(isVisible ? "bg-Success-600" : "bg-Primary-50")}
          />
        </Form.Item>
      </div>
      <Form.Item
        label={localization(
          "/common/components/guide/guide-form/define-name-prompt"
        )}
        className="font-Inter ant-col-24 rounded-md"
        rules={[{ required: true }]}
        initialValue={guidesData.name}
      >
        <GuideInformationForm
          values={guidesData.name}
          placeholder={localization(
            "/common/components/guide/guide-form/define-name"
          )}
          collect="guideInfo"
          fieldName={"name"}
          guidesData={guidesData}
          setForm={setForm}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={localization(
              "/common/components/guide/guide-form/select-topics"
            )}
            className="font-Inter"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={getSeletedGuideTypes("subjects")}
          >
            <GuideInformationForm
              guidesData={guidesData}
              isMulti
              options={subjects}
              fieldName={"subjectIds"}
              setForm={setForm}
              placeholder={localization(
                "/common/components/guide/guide-form/define-subjects"
              )}
              values={getSeletedGuideTypes("subjects")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={localization(
              "/common/components/guide/guide-form/select-categories"
            )}
            className="font-Inter"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={getSeletedGuideTypes("categories")}
          >
            <GuideInformationForm
              guidesData={guidesData}
              isMulti
              options={categories}
              fieldName={"categoryIds"}
              setForm={setForm}
              placeholder={localization(
                "/common/components/guide/guide-form/define-categories"
              )}
              values={getSeletedGuideTypes("categories")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={localization(
              "/common/components/guide/guide-form/select-types"
            )}
            className="font-Inter"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={getSeletedGuideTypes("types")}
          >
            <GuideInformationForm
              guidesData={guidesData}
              isMulti
              options={databaseTypes}
              fieldName={"typeIds"}
              placeholder={localization(
                "/common/components/guide/guide-form/define-types"
              )}
              values={getSeletedGuideTypes("types")}
              setForm={setForm}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item
            className="text-Black text-base font-Inter"
            label={localization(
              "/common/components/guide/guide-form/define-description"
            )}
            initialValue={guidesData.description}
            name={"description"}
          >
            <GuideInformationForm
              collect="guideInfo"
              values={guidesData.description}
              placeholder={localization(
                "/common/components/guide/guide-form/write-description"
              )}
              guidesData={guidesData}
              setForm={setForm}
              fieldName={"description"}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            className="text-Black text-base font-Inter"
            label={
              <div
                className="flex flex-wrap"
                onClick={() => setInformationAlertVisible(true)}
              >
                <span className="mr-3">
                  {localization(
                    "/common/components/guide/guide-form/define-friendly-url-prompt"
                  )}
                </span>
                <div className="p-1 rounded-full flex justify-center items-center bg-Secondary-200 cursor-pointer">
                  <InfoIcon></InfoIcon>
                </div>
              </div>
            }
            initialValue={guidesData.friendlyUrl}
            name={"friendlyUrl"}
          >
            <GuideInformationForm
              collect="guideInfo"
              values={guidesData.friendlyUrl}
              placeholder={localization(
                "/common/components/guide/guide-form/define-friendly-url"
              )}
              guidesData={guidesData}
              setForm={setForm}
              isUrl
              fieldName={"friendlyUrl"}
            />
            {informationAlertVisible && (
              <Col className="mt-2 ">
                <Alert
                  message={localization(
                    "/common/components/guide/guide-form/what-is-friendly-url"
                  )}
                  className="display:n bg-Secondary-100 border-Secondary-500 cursor-pointer"
                  type="info"
                  closable
                  onClose={() => setInformationAlertVisible(false)}
                  onClick={() => setInformationAlertVisible(false)}
                />
              </Col>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export const PageSelector = ({
  pages,
  selectedPage,
  setSelectedPage,
  onAddPageButtonClicked,
  setForm,
  displayButtons = true,
}) => {
  const [form] = useForm();

  const handleOnButtonClicked = (id) => {
    setSelectedPage(id);
  };

  useEffect(() => {
    if (pages && pages[0] && !selectedPage) {
      setSelectedPage(pages[0].id);
    }
  });

  const handleAddPageClicked = () => {
    onAddPageButtonClicked();
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (!selectedPage) {
      notification.error({
        description: localization(
          '/common/components/guide/guide-form/select-page-to-rename"'
        ),
        message: localization(
          "/common/components/guide/guide-form/editing-not-possible"
        ),
      });
      return;
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const changePageName = (value) => {
    setForm((prevState) => {
      const selected = prevState.pages.find((page) => selectedPage === page.id);
      selected.name = value.pageName;
      return { ...prevState };
    });
    form.setFieldValue("pageName", "");
    setIsModalOpen(false);
  };

  const handlePageDelete = () => {
    setForm((prevState) => {
      const filteredPages = prevState.pages.filter(
        (page) => selectedPage !== page.id
      );
      prevState.pages = filteredPages;
      return { ...prevState };
    });
    setSelectedPage(null);
    setIsModalOpen(false);
  };

  return (
    <>
      {pages &&
        pages.length > 0 &&
        pages.map((page) => {
          return (
            <button
              key={page.id}
              onClick={() => handleOnButtonClicked(page.id)}
              type="button"
              className={clsx(
                selectedPage === page.id
                  ? "bg-Primary-50 text-Primary-500"
                  : "",
                "text-Grey-600 font-Inter text-base py-2 px-3 rounded-md "
              )}
            >
              {page.name}
            </button>
          );
        })}
      <>
        {displayButtons && (
          <>
            {pages?.length !== 0 && (
              <Button
                className="rounded-md"
                icon={<FormOutlined className="text-Primary-500" />}
                onClick={showModal}
              />
            )}
            <Modal
              title={localization(
                "/common/components/guide/guide-form/update-page"
              )}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <Form
                layout="vertical"
                onFinish={changePageName}
                preserve={false}
                form={form}
              >
                <Form.Item
                  className="font-Inter font-medium text-Black"
                  label={localization(
                    "/common/components/guide/guide-form/page-name-label"
                  )}
                  name={"pageName"}
                >
                  <Input
                    className="text-Grey-600 font-Inter font-normal rounded-md py-1.5"
                    placeholder={localization(
                      "/common/components/guide/guide-form/enter-new-page-name"
                    )}
                  />
                </Form.Item>

                <div className="flex justify-between">
                  <Button
                    size="middle"
                    onClick={() => handlePageDelete()}
                    className="bg-Error-500 text-Secondary-100 hover:border-Error-500 hover:text-Error-500 rounded-lg "
                  >
                    {localization(
                      "/common/components/guide/guide-form/delete-the-page"
                    )}
                  </Button>
                  <Button
                    size="middle"
                    className="bg-Primary-500 text-Secondary-100 hover:text-Primary-500 hover:bg-Seconary-100 hover:border-Primary-500 rounded-lg"
                    htmlType="submit"
                  >
                    {localization("/common/components/guide/guide-form/save")}
                  </Button>
                </div>
              </Form>
            </Modal>
            <Button
              className="rounded-md"
              icon={<PlusOutlined className="text-Primary-500" />}
              onClick={handleAddPageClicked}
            />
          </>
        )}
      </>
    </>
  );
};

export const RowDeleteModal = ({
  handleRemoveRow,
  setRowDeleteModalOpen,
  isRowDeleteModalOpen,
}) => {
  const handleCancel = () => {
    setRowDeleteModalOpen(false);
  };
  return (
    <Modal
      onCancel={handleCancel}
      open={isRowDeleteModalOpen}
      footer={null}
      closable
      centered
    >
      <div className="flex flex-col px-6 gap-4">
        <p className="text-center text-base font-medium">
          {localization(
            "/common/components/guide/guide-form/are-u-sure-content"
          )}
        </p>
        <div className="flex flex-1 justify-between">
          <Button
            size="large"
            className="rounded-lg bg-Primary-500 text-Secondary-100"
            onClick={handleRemoveRow}
          >
            {localization("/common/components/guide/guide-form/yes")}
          </Button>
          <Button
            size="large"
            className="rounded-lg bg-Primary-500 text-Secondary-100"
            onClick={handleCancel}
          >
            {localization("/common/components/guide/guide-form/no")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const PageBuilder = ({
  page,
  selectedPage,
  guidesData,
  setForm,
  isEdit,
}) => {
  const [isRowDeleteModalOpen, setRowDeleteModalOpen] = useState(false);

  var sortedRows = page.rows.sort((first, second) => {
    return first.index <= second.index;
  });

  const handleRemoveRow = (row) => {
    var selectedPageData = guidesData.pages.find((element) => {
      return element.id === selectedPage;
    });
    selectedPageData.rows = selectedPageData.rows.filter((element) => {
      return element.id !== row.id;
    });
    setForm({ ...guidesData });
    setRowDeleteModalOpen(false);
  };
  return (
    <div className={clsx(selectedPage !== page.id && "hidden")}>
      {sortedRows.map((row, index) => {
        return (
          <Row key={`row-${index}`}>
            <Col span={isEdit ? 23 : 24}>
              <RowBuilder
                pageInfo={{ pageId: page.id }}
                row={row}
                guidesData={guidesData}
                setForm={setForm}
                isEdit={isEdit}
                key={`row-builder-${index}`}
              />
            </Col>
            {isEdit && (
              <>
                <Col
                  span={1}
                  className="my-auto cursor-pointer flex justify-center"
                  onClick={() => setRowDeleteModalOpen(true)}
                >
                  <TrashIcon />
                </Col>
                <RowDeleteModal
                  setRowDeleteModalOpen={setRowDeleteModalOpen}
                  isRowDeleteModalOpen={isRowDeleteModalOpen}
                  handleRemoveRow={() => handleRemoveRow(row)}
                />
              </>
            )}
          </Row>
        );
      })}
    </div>
  );
};
export const RowBuilder = ({ pageInfo, row, guidesData, setForm, isEdit }) => {
  var sortedColumns = row.columns.sort((first, second) => {
    return first.index <= second.index;
  });
  return (
    <>
      <Row className=" mx-4" gutter={[24, 24]}>
        {sortedColumns.map((column, index) => {
          return (
            <ColumnBuilder
              pageInfo={{ ...pageInfo, rowId: row.id }}
              column={column}
              guidesData={guidesData}
              setForm={setForm}
              isEdit={isEdit}
              key={`column-builder-${index}`}
            />
          );
        })}
      </Row>
    </>
  );
};

export const ColumnBuilder = ({
  pageInfo,
  column,
  guidesData,
  setForm,
  isEdit,
}) => {
  const calculateLayoutWeight = (width) => {
    const percentageOfWidth = width / 100;
    return Math.ceil(24 * percentageOfWidth);
  };
  return (
    <>
      <Col order={column.index} span={calculateLayoutWeight(column.width)}>
        {column.boxes &&
          column.boxes.map((box, index) => {
            return (
              <GuideContentCard
                pageInfo={{
                  ...pageInfo,
                  columnId: column.id,
                  boxId: box.id,
                }}
                guidesData={guidesData}
                setForm={setForm}
                isEdit={isEdit}
                key={`guide-content-${index}`}
                boxInfo={box}
              />
            );
          })}
      </Col>
    </>
  );
};

export const GuideContentCard = ({
  pageInfo,
  guidesData,
  setForm,
  isEdit,
  boxInfo,
}) => {
  const [contentData, setContentData] = useState({});

  return (
    <>
      {isEdit ? (
        <div className="bg-White rounded-md font-Inter p-3 my-3">
          <Form.Item
            label={<div className="flex flex-row gap-x-2 ml-5 mt-2"></div>}
            className=" font-medium font-Inter text-Black text-medium"
            name={"guideInfo"}
          >
            <CustomGuideContentInput
              pageInfo={pageInfo}
              guidesData={guidesData}
              fieldName="content"
              isTextArea={true}
              setForm={setForm}
              setContentData={setContentData}
            />
          </Form.Item>
        </div>
      ) : (
        <GuideBox guideBoxData={boxInfo} />
      )}
    </>
  );
};
export const CustomGuideContentInput = ({
  pageInfo,
  guidesData,
  fieldName,
  isTextArea,
  setForm,
  setContentData,
}) => {
  const editorLanguage = getLanguagePreference();
  const findByIdInObject = (array, id) => {
    return array.find((element) => element.id === id);
  };

  const getSelectedBox = () => {
    var selectedBox = findByIdInObject(
      findByIdInObject(
        findByIdInObject(
          findByIdInObject(guidesData.pages, pageInfo.pageId).rows,
          pageInfo.rowId
        ).columns,
        pageInfo.columnId
      ).boxes,
      pageInfo.boxId
    );
    return selectedBox;
  };

  const addGuideInformation = (event) => {
    var selectedBox = getSelectedBox();
    selectedBox[fieldName] = event.target.value;
    return guidesData;
  };

  const onChangeHandler = (event) => {
    setForm(addGuideInformation(event));
  };

  const getEditor = (editor) => {
    console.log("Editor information ", editor);
    var selectedBox = getSelectedBox();
    selectedBox.design = JSON.stringify(editor.getProjectData());
    selectedBox.content = editor.getHtml();
    setForm(guidesData);
    setContentData(editor.getHtml());
    message.success({
      content:localization("/pages/app/mail-editor/changes-done")
    })
  };

  return (
    <>
      {isTextArea ? (
        <div>
          <div>
            <MailEditor
              props={{
                url: `https://iytetest.gguides.cloud/`,
              }}
              getEditor={getEditor}
              projectData={getSelectedBox().design ?? ""}
            />
          </div>
        </div>
      ) : (
        <Input
          className="font-medium text-Grey-600"
          type="text"
          onChange={onChangeHandler}
          defaultValue={getSelectedBox()[fieldName]}
        />
      )}
    </>
  );
};
