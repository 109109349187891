import { Row } from "antd";

import React from "react";
import { useParams, NavLink, Routes, Route, Navigate } from "react-router-dom";

import "../userTable.css";
import DetailsUsers from "./detail-pages/users";
import BulletinMailCreator from "./create-email-template";
import { useGetMailGroup } from "../../../../hooks/mailGroup/mailGroup.hook";

const BulletinDetail = () => {
  const { id } = useParams();

  const { data: mailGroupData, isLoading: isMailGroupLoading } =
    useGetMailGroup(id);
  const buttons = [
    {
      name: "Kullanıcılar",
      linkTo: "users",
    },
    {
      name: "Rehberler Bülteni",
      linkTo: "guides_bulletin",
    },
    {
      name: "Veri Tabanları Bülten",
      linkTo: "database_bulletin",
    },
  ];

  return (
    <>
      {isMailGroupLoading ? (
        <div>Loading..</div>
      ) : (
        <div>
          <Row className="flex flex-col p-6 bg-White mt-6 rounded-md">
            <div className="flex items-start justify-between">
              <div className="text-Black font-semibold text-lg">
                {mailGroupData.category.name}
              </div>
            </div>
            <div className="flex gap-2 mt-4">
              {buttons.map((buttonDetail) => (
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "bg-Primary-50 rounded-md text-Primary-500 hover:text-Primary-600"
                      : " text-Primary-500 hover:text-Primary-600"
                  }
                  to={buttonDetail.linkTo}
                >
                  <div className="py-2 px-3 hover:bg-Primary-50 rounded-md">
                    {buttonDetail.name}
                  </div>
                </NavLink>
              ))}
            </div>
          </Row>
          <Routes>
            <Route path="users" element={<DetailsUsers />} />
            <Route path="" element={<Navigate to={"users"}></Navigate>} />
            <Route
              path="database_bulletin"
              element={
                <BulletinMailCreator id={id} type="databaseMailTemplate" />
              }
            />
            <Route
              path="guides_bulletin"
              element={<BulletinMailCreator id={id} type="guideMailTemplate" />}
            />
            <Route path="special" element={<BulletinMailCreator id={id} />} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default BulletinDetail;
