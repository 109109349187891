import { useState } from "react";
import CustomModal from "../../../common/components/modal/modal";
import EditTypes from "../../../common/views/app/edit-types/edit-types";

import {
  useGetDatabaseTypes,
  useAddDatabaseType,
  useDeleteDatabaseType,
} from "../../../hooks/database-type/database-type.hooks";
import { PlusSign } from "../../../icons/common-icon";
import {
  useCreateDatabaseSubject,
  useDeleteDatabaseSubject,
  useGetDatabaseSubjects,
} from "../../../hooks/database-subject/database-subject.hook";
import {
  useCreateGuideType,
  useDeleteGuideType,
  useGetGuideTypes,
} from "../../../hooks/guide-type/guide-type.hook";
import {
  useCreateGuideSubject,
  useDeleteGuideSubject,
  useGetGuideSubjects,
} from "../../../hooks/guide-subject/guide-subject.hook";
import { notification } from "antd";
import {
  useCreateVendor,
  useDeleteVendor,
  useGetVendors,
} from "../../../hooks/vendor/vendor-hooks";
import { localization } from "../../../localization/localization";

const ModalContent = ({
  title,
  statement,
  label,
  placeholder,
  getFormData,
}) => {
  const [value, setValue] = useState();
  getFormData(value);
  return (
    <div>
      <div id="heading-section" className="flex flex-row gap-3">
        <div
          id="heading-section-icon"
          className="flex w-12 h-12 bg-Secondary-100 rounded-full"
        >
          <div className=" m-auto">
            <PlusSign className={'stroke-Secondary-600'} />
          </div>
        </div>
        <div id="heading-section-content">
          <div className="heading-section-content-title">
            <p className="font-Inter text-Black text-base">{title}</p>
          </div>
          <div id="heding-section-content-statement">
            <p className="font-Inter text-Grey-600 text-base">{statement}</p>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div>
          <label className="font-Inter text-Black text-base">{label}</label>
          <div className="border border-Grey-200 rounded px-4 py-2">
            <input
              name="input-name"
              type="text"
              value={value}
              className="block text-base font-Inter w-full focus:outline-none"
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EditTypesPage = () => {
  const [formData, setFormData] = useState(null);
  const getFormData = (value) => {
    setFormData(value);
  };

  const onSuccess = (text, refetch) => {
    notification.success({
      message:"Success!",
      description:text
    })
    refetch()
  }

  const onError = (text, refetch) => {
    notification.error({
      message:"Error!",
      description:text
    })
    refetch()
  }

  /* Database types */
  const {
    data: databaseTypes,
    isLoading: isdatbaseTypeLoading,
    refetch: refetchDatabaseTypes,
  } = useGetDatabaseTypes(0, 999);

  const { mutate: mutateDatabaseType } = useAddDatabaseType(
    () => onSuccess("New Database Type Successfully Added", refetchDatabaseTypes),
    () => onError("The Database Type Could Not Added", refetchDatabaseTypes)
  );
  const { mutate: mutateDeleteDatabaseType } = useDeleteDatabaseType(() =>
    onSuccess(
      "Database Type Has Been Succesfully Removed",
      refetchDatabaseTypes
    )
  );

  /* Database  subjects*/
  const {
    data: databaseSubjects,
    isLoading: isDatabaseSubjectsLoading,
    refetch: refetchDatabaseSubjects,
  } = useGetDatabaseSubjects(0, 999);

  const { mutate: mutateCreateDatabaseSubject } = useCreateDatabaseSubject(
    () => onSuccess("New Database Subject Successfully Added", refetchDatabaseSubjects),
    () => onError("The Database Subject Could Not Added", refetchDatabaseSubjects)
  );

  const { mutate: muatateDeleteDatabaseSubject } = useDeleteDatabaseSubject(() =>
    onSuccess(
      "Database Subject Has Been Succesfully Removed",
      refetchDatabaseSubjects
    )
  );

  /* Guide types */
  const {
    data: guideTypes,
    isLoading: isGuideTypesLoading,
    refetch: refetchGuideTypes,
  } = useGetGuideTypes(0, 999);

  const { mutate: mutateCreateGuideType } =
    useCreateGuideType(
      () => onSuccess("New Guide Type Successfully Added", refetchGuideTypes),
      () => onError("The Guide Type Could Not Added", refetchGuideTypes)
  );

  const { mutate: mutateDeleteGuideType } = useDeleteGuideType(
    () => onSuccess(
      "Guide Type Has Been Succesfully Removed",
      refetchGuideTypes
    )
  );
  
  /* Guide subjects */
  const {
    data: guideSubjects,
    isLoading: isGuideSubjectsLoading,
    refetch: refetchGuideSubjects,
  } = useGetGuideSubjects(0, 999);
  const { mutate: mutateCreateGuideSubject } =
    useCreateGuideSubject(
      () => onSuccess("New Guide Subject Successfully Added", refetchGuideSubjects),
      () => onError("The Guide Subject Could Not Added", refetchGuideSubjects)
    );
  const { mutate: mutateDeleteGuideSubject } = useDeleteGuideSubject(
    () => onSuccess(
      "Guide Subject Has Been Succesfully Removed",
      refetchGuideSubjects
    )
  );

  /* Vendors */
  const {
    data: vendors,
    isLoading: isVendorsLoading,
    refetch: refetchVendors,
  } = useGetVendors(0, 999);

  const { mutate: mutateCreateVendor } = useCreateVendor(
    () => onSuccess("New Vendor Successfully Added", refetchVendors),
    () => onError("The Vendor Could Not Added", refetchVendors)
  );
  const { mutate: mutateDeleteVendor } = useDeleteVendor(
    () => onSuccess(
      "Vendor Has Been Succesfully Removed",
      refetchVendors
    )
  );
  //end

  const [isCreateDatabaseTypeModal, setIsCreateDatabaseTypeModal] =
    useState(false);
  const [isCreateDatabaseSubjectModal, setIsCreateDatabaseSubjectModal] =
    useState(false);
  const [isCreateGuideTypeModal, setIsCreateGuideTypeModal] = useState(false);
  const [isCreateGuideSubjectModal, setIsCreateGuideSubjectModal] =
    useState(false);
  const [isCreateVendorModal, setIsCreateVendorModal] = useState(false);

  const openCreateDatabaseTypeModal = () => setIsCreateDatabaseTypeModal(true);
  const closeCreateDatabaseTypeModal = () =>
    setIsCreateDatabaseTypeModal(false);

  const onDatabaseTypeRemoveClicked = (id) => mutateDeleteDatabaseType(id);

  const openCreateDatabaseSubjectModal = () =>
    setIsCreateDatabaseSubjectModal(true);

  const closeCreateDatabaseSubjectModal = () =>
    setIsCreateDatabaseSubjectModal(false);

  const onDatabaseSubjectRemoveClicked = (id) =>
    muatateDeleteDatabaseSubject(id);

  const openCreateGuideTypeModal = () => setIsCreateGuideTypeModal(true);
  const closeCreateGuideTypeModal = () => setIsCreateGuideTypeModal(false);
  const onGuideTypeRemoveClicked = (id) => mutateDeleteGuideType(id);

  const openCreateGuideSubjectModal = () => setIsCreateGuideSubjectModal(true);
  const closeCreateGuideSubjectModal = () =>
    setIsCreateGuideSubjectModal(false);
  const onGuideSubjectRemoveClicked = (id) => mutateDeleteGuideSubject(id);

  const openCreateVendorModal = () => setIsCreateVendorModal(true);
  const closeCreateVendorModal = () => setIsCreateVendorModal(false);
  const onVendorRemoveClick = (id) => mutateDeleteVendor(id);
  return (
    <>
      {!isdatbaseTypeLoading &
        !isDatabaseSubjectsLoading &
        !isGuideTypesLoading &
        !isVendorsLoading &
        !isGuideSubjectsLoading && (
        <>
          <h6 className="font-Inter text-Black text-xl my-6">
            {localization("/pages/app/types-page/edit-subjects-database-types/edit-database-types")}
          </h6>
          <div className="flex flex-row gap-x-6 mb-6">
            
            <div className="basis-1/2">
              <EditTypes
                title={localization("/pages/app/types-page/edit-subjects-database-types/types")}
                statement={localization("/pages/app/types-page/edit-subjects-database-types/manage-resources-types")}
                data={databaseTypes.content}
                onAddNewClicked={openCreateDatabaseTypeModal}
                onRemoveClicked={onDatabaseTypeRemoveClicked}
              />
              <CustomModal
                isOpen={isCreateDatabaseTypeModal}
                closeModal={closeCreateDatabaseTypeModal}
                buttonName={localization("/pages/app/types-page/edit-subjects-database-types/add")}
                onAddButtonClicked={mutateDatabaseType}
                formData={formData}
                setModalOpen={setIsCreateDatabaseTypeModal}
              >
                <ModalContent
                  title={localization("/pages/app/types-page/edit-subjects-database-types/add-new-type")}
                  statement={localization("/pages/app/types-page/edit-subjects-database-types/add-type-for-categorization")}
                  label={localization("/pages/app/types-page/edit-subjects-database-types/add-type-name")}
                  placeholder={localization("/pages/app/types-page/edit-subjects-database-types/example-art-design")}
                  getFormData={getFormData}
                />
              </CustomModal>
            </div>

            <div className="basis-1/2">
              <EditTypes
                title={localization("/pages/app/types-page/edit-subjects-database-types/subjects")}
                statement={localization("/pages/app/types-page/edit-subjects-database-types/manage-resources-subject")}
                data={databaseSubjects.content}
                onAddNewClicked={openCreateDatabaseSubjectModal}
                onRemoveClicked={onDatabaseSubjectRemoveClicked}
              />
              <CustomModal
                isOpen={isCreateDatabaseSubjectModal}
                closeModal={closeCreateDatabaseSubjectModal}
                buttonName={localization("/pages/app/types-page/edit-subjects-database-types/add")}
                onAddButtonClicked={mutateCreateDatabaseSubject}
                formData={formData}
                setModalOpen={setIsCreateDatabaseSubjectModal}
              >
                <ModalContent
                  title={localization("/pages/app/types-page/edit-subjects-database-types/add-new-subject")}
                  statement={localization("/pages/app/types-page/edit-subjects-database-types/add-subject-for-categorization")}
                  label={localization("/pages/app/types-page/edit-subjects-database-types/subject-name")}
                  placeholder={localization("/pages/app/types-page/edit-subjects-database-types/example-art-design")}
                  getFormData={getFormData}
                />
              </CustomModal>
            </div>
          </div>
          <h6 className="font-Inter text-Black text-xl my-6">
            {localization("/pages/app/types-page/edit-subjects-database-types/edit-guides")}
          </h6>
          <div className="flex flex-row gap-x-6">
            <div className="basis-1/2">
              <EditTypes
                title={localization("/pages/app/types-page/edit-subjects-database-types/types")}
                statement={localization("/pages/app/types-page/edit-subjects-database-types/manage-resources-types")}
                data={guideTypes.content}
                onAddNewClicked={openCreateGuideTypeModal}
                onRemoveClicked={onGuideTypeRemoveClicked}
              />
              <CustomModal
                isOpen={isCreateGuideTypeModal}
                closeModal={closeCreateGuideTypeModal}
                buttonName={localization("/pages/app/types-page/edit-subjects-database-types/add")}
                onAddButtonClicked={mutateCreateGuideType}
                formData={formData}
                setModalOpen={setIsCreateGuideTypeModal}
              >
                <ModalContent
                  title={localization("/pages/app/types-page/edit-subjects-database-types/add-new-guide-type")}
                  statement={localization("/pages/app/types-page/edit-subjects-database-types/add-type-for-categorization")}
                  label={localization("/pages/app/types-page/edit-subjects-database-types/name-of-new-type")}
                  placeholder={localization("/pages/app/types-page/edit-subjects-database-types/example-art-design")}
                  getFormData={getFormData}
                />
              </CustomModal>
            </div>

            <div className="basis-1/2">
              <EditTypes
                title={localization("/pages/app/types-page/edit-subjects-database-types/subjects")}
                statement={localization("/pages/app/types-page/edit-subjects-database-types/manage-resources-subject")}
                data={guideSubjects.content}
                onAddNewClicked={openCreateGuideSubjectModal}
                onRemoveClicked={onGuideSubjectRemoveClicked}
              />
              <CustomModal
                isOpen={isCreateGuideSubjectModal}
                closeModal={closeCreateGuideSubjectModal}
                buttonName={localization("/pages/app/types-page/edit-subjects-database-types/add")}
                onAddButtonClicked={mutateCreateGuideSubject}
                formData={formData}
                setModalOpen={setIsCreateGuideSubjectModal}
              >
                <ModalContent
                  title={localization("/pages/app/types-page/edit-subjects-database-types/add-new-subject")}
                  statement={localization("/pages/app/types-page/edit-subjects-database-types/add-subject-for-categorization")}
                  label={localization("/pages/app/types-page/edit-subjects-database-types/new-subject-name")}
                  placeholder={localization("/pages/app/types-page/edit-subjects-database-types/example-art-design")}
                  getFormData={getFormData}
                />
              </CustomModal>
            </div>
          </div>

          <h6 className="font-Inter text-Black text-xl my-6">
            {localization("/pages/app/types-page/edit-subjects-database-types/edit-providers")}
          </h6>
          <div className="flex flex-row gap-x-6">
            <div className="basis-1/2">
              <EditTypes
                title={localization("/pages/app/types-page/edit-subjects-database-types/providers")}
                statement={localization("/pages/app/types-page/edit-subjects-database-types/manage-providers")}
                data={vendors.content}
                onAddNewClicked={openCreateVendorModal}
                onRemoveClicked={onVendorRemoveClick}
              />
              <CustomModal
                isOpen={isCreateVendorModal}
                closeModal={closeCreateVendorModal}
                buttonName={localization("/pages/app/types-page/edit-subjects-database-types/add")}
                onAddButtonClicked={mutateCreateVendor}
                formData={formData}
                setModalOpen={setIsCreateVendorModal}
              >
                <ModalContent
                  title={localization("/pages/app/types-page/edit-subjects-database-types/add-new-provider")}
                  statement={localization("/pages/app/types-page/edit-subjects-database-types/add-database-provider")}
                  label={localization("/pages/app/types-page/edit-subjects-database-types/new-provider-name")}
                  placeholder={localization("/pages/app/types-page/edit-subjects-database-types/example")}
                  getFormData={getFormData}
                />
              </CustomModal>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditTypesPage;
