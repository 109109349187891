import Input from "antd/lib/input/Input";
import clsx from "clsx";
import { SearchOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { Button, Col, Row } from "antd";
import {localization} from "../../../localization/localization";

const InformationBox = ({
  title,
  text,
  extendedText,
  searchBarPlaceholder,
  isSearchbar = true,
  setSearchKey,
  children,
}) => {
  const ref = useRef();
  const onSearchButtonClickedHandler = () => {
    setSearchKey(ref.current.input.value);
  };

  return (
    <div className="bg-White h-full w-full rounded-md py-6 pl-6">
      <div className="flex flex-row pb-2">
        <p className=" text-Black text-lg font-Inter my-auto mr-3"> {title}</p>
        {children}
      </div>
      <div className="w-full flex flex-row pb-8">
        <p
          className={clsx(
            "basis-1/2 font-Inter text-Grey-500 text-sm",
            extendedText && "basis-3/4"
          )}
        >
          {text}
        </p>
      </div>
      {isSearchbar && (
        <Row gutter={24}>
          <Col span={8}>
            <Input
              ref={ref}
              size="large"
              prefix={<SearchOutlined />}
              placeholder={searchBarPlaceholder}
              className="rounded-md"
            />
          </Col>
          <Col>
            <Button
              onClick={onSearchButtonClickedHandler}
              className="bg-Primary-500 w-32 text-White font-Inter rounded-md text-center"
              size="large"
            >
            {localization("/common/views/info-box/information-box/search")}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InformationBox;
