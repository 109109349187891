import * as api from "../api";

export const postMailUser = (body) => {
  return api.post("/mail-user", body);
};

export const getMailUser = (id)=>{
  return api.get(`/mail-user/${id}`);
}

export const getMailUsers = () => {
  return api.get("/mail-user");
};

export const deleteMailUser = (id) => {
  return api.del(`/mail-user/${id}`);
};
