import { Link } from 'react-router-dom'

import { BadgeAuthor } from '../../components/buttons/badge'
import { localization } from '../../../localization/localization'
import { useEffect, useState } from 'react'
import { useDownloadFile } from '../../../hooks/file/file.hooks'
import defaultPhoto from "../../../assets/avatar_image.png"
import { Spin } from 'antd'

const AuthorPageButton = ({ buttonName, pageLink, authorMail }) => {
  const emailButtonClickHandler = () => {
    window.location = 'mailto:' + authorMail
  }

  return (
    <Link to={pageLink}>
      <div className="font-Inter text-Primary-500 text-base border border-Grey-200 rounded-lg flex py-2.5 shadow-sm hover:bg-Primary-500 hover:text-Primary-50">
        {
          <button
            onClick={authorMail && emailButtonClickHandler}
            className="w-full h-full"
          >
            {buttonName}
          </button>
        }
      </div>
    </Link>
  );
}
const AuthorCard = ({
  pageLink,
  photoId,
  authorName,
  authorMail,
  title,
  researchTopics,
}) => {

  const [ image, setImage ] = useState(null)
  const { mutate:downloadImage,isLoading:isDownloading } = useDownloadFile();

  useEffect(()=>{
    downloadImage(photoId, {
      onSuccess: (response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let bufferObject = window.URL.createObjectURL(blob);
        setImage(bufferObject);
      },
      onError: () => {
        setImage(null)
      }
    });
  },[])

  return (
    <div className="bg-White p-4">
      <div className="flex flex-row gap-x-6">
        <Spin spinning={isDownloading}>
          <div className='flex w-20 h-20' >
            <img src={image ? image : defaultPhoto} className="flex-1 rounded-full" />
          </div>
        </Spin>
        <div className="flex flex-col">
          <div className="font-Inter text-lg text-Black">{authorName}</div>
          <div className="font-Inter text-base text-Grey-600">{title}</div>
          <div className="flex flex-row mt-3 gap-x-2">
            {researchTopics &&
              researchTopics.map((topic, index) => {
                return <BadgeAuthor key={index} leftChildren={topic} />;
              })}
          </div>
        </div>
      </div>
      <div className="mx-4">
        <div className="flex flex-row mt-6 gap-x-6">
          <div className="basis-1/2">
            <AuthorPageButton
              buttonName={localization(
                "/common/views/author/author-card/send-mail"
              )}
              authorMail={authorMail}
            />
          </div>

          <div className="basis-1/2">
            <AuthorPageButton
              buttonName={localization(
                "/common/views/author/author-card/profile"
              )}
              pageLink={pageLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorCard
