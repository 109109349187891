import {
  CreateGuideManagementButtons,
  GuideMetaDataForm,
  PageBuilder,
  PageSelector,
} from "../../../common/components/guide/guide-form";
import { Form, notification } from "antd";
import {localization} from "../../../localization/localization";
import GuideRowCreator from "../../../common/components/guide/guide-row-creator";
import { useCreateGuide } from "../../../hooks/guide/guide.hooks";
import { useEffect } from "react";
import { useGetCategories } from "../../../hooks/category/category.hooks";
import { useGetGuideSubjects } from "../../../hooks/guide-subject/guide-subject.hook";
import { useGetGuideTypes } from "../../../hooks/guide-type/guide-type.hook";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateGuidePage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { data: guideSubjects, isLoading: isSubjectsLoading } =
    useGetGuideSubjects(0, 999);
  const { data: guideTypes, isLoading: isDabaseTypesLoading } =
    useGetGuideTypes(0, 999);
  const { data: guideCategories, isLoading: isGuideCategoriesLoading } =
    useGetCategories();

  const { mutate: mutateCreateGuide } = useCreateGuide(() => {
    notification.success({
      description: localization("/pages/app/guide-pages/create-guide/success"),
      message: localization("/pages/app/guide-pages/create-guide/guide-created"),
    });
    navigate("/app/guides");
  });

  const [guideDataTmp, setGuideDataTmp] = useState({ pages: [] });
  const [selectedPage, setSelectedPage] = useState("");

  const onSubmit = (formData) => {
    guideDataTmp.visible = guideDataTmp.visible ?? false;
    mutateCreateGuide(guideDataTmp);
  };

  const addNewPageHandler = () => {
    const tempPage = {
      id: Math.random().toString(36).substring(2, 7),
      created: new Date().getTime(),
      updated: new Date().getTime(),
      name: localization("/pages/app/guide-pages/create-guide/page") + (guideDataTmp.pages.length + 1),
      index: guideDataTmp.pages.length,
      descriptions: "There is no desc",
      rows: [],
    };

    const data = { ...guideDataTmp };

    data.pages.push(tempPage);

    setGuideDataTmp(data);
  };

  const addNewRowHandler = () => {
    const data = { ...guideDataTmp };
    const selected = data.pages.find((page) => page.id === selectedPage);

    if (!selected) {
      notification.error({
        description: localization("/pages/app/guide-pages/create-guide/new-row-failed"),
        message: localization("/pages/app/guide-pages/create-guide/select-page"),
      });
      return;
    }
    const tempRow = {
      id: Math.random().toString(36).substring(2, 7),
      created: new Date().getTime(),
      updated: new Date().getTime(),
      index: selected.rows.length,
      columns: [],
    };

    const column = {
      id: Math.random().toString(36).substring(2, 7),
      updated: new Date().getTime(),
      created: new Date().getTime(),
      index: 0,
      width: 100,
      boxes: [
        {
          id: Math.random().toString(36).substring(2, 7),
          created: new Date().getTime(),
          updated: new Date().getTime(),
          name: `Box_${0}`,
          index: 0,
          content: "Empty",
          contentType: "HTML",
        },
      ],
    };

    tempRow.columns.push(column);
    

    if (selected.rows) {
      selected.rows.push(tempRow);
    } else {
      selected.rows = [];
      selected.rows.push(tempRow);
    }
    setGuideDataTmp(data);
  };

  useEffect(() => {
    setGuideDataTmp((prevState) => {
      return {
        ...prevState,
        name: "",
      };
    });
  }, []);
  return (
    <>
      {isDabaseTypesLoading || isSubjectsLoading || isGuideCategoriesLoading ? (
        <div>Loading ..</div>
      ) : (
        <>
          <Form
            form={form}
            layout="vertical"
            className="p-6 rounded-md"
            onFinish={onSubmit}
          >
            <CreateGuideManagementButtons pageData={guideDataTmp} />
            <div className="bg-White p-6">
              <GuideMetaDataForm
                databaseTypes={guideTypes.content}
                subjects={guideSubjects.content}
                categories={guideCategories}
                guidesData={guideDataTmp}
                setForm={setGuideDataTmp}
              />
            </div>
            <div className="bg-White my-3 px-6 py-3 flex flex-row gap-x-4 rounded-md">
              <PageSelector
                pages={guideDataTmp.pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                onAddPageButtonClicked={addNewPageHandler}
                setForm={setGuideDataTmp}
              />
            </div>
            {guideDataTmp.pages.map((page, index) => {
              return (
                <PageBuilder
                  page={page}
                  selectedPage={selectedPage}
                  guidesData={guideDataTmp}
                  setForm={setGuideDataTmp}
                  isEdit={true}
                  key={`page-${index}`}
                />
              );
            })}
          </Form>
          <div className="p-6">
            <GuideRowCreator onCreateNewRowButtonClicked={addNewRowHandler} />
          </div>
        </>
      )}
    </>
  );
};
export default CreateGuidePage;
