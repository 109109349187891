import React from 'react'

const AddButton = ({ onClick, icon, text }) => {
  return (
    <div className="flex flex-row mt-10 mb-8 ">
      <div
        className="bg-Primary-500 text-Primary-50 rounded-lg px-3 py-2 text-base flex flex-row gap-x-3 items-center hover:shadow-sm hover:cursor-pointer"
        onClick={onClick}
      >
        {icon}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default AddButton