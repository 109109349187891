import { useState } from "react";
import { useParams } from "react-router-dom";
import { BadgeInfo } from "../../../common/components/buttons/badge";
import { PageBuilder } from "../../../common/components/guide/guide-form";
import GuidePageLayout from "../../../common/components/guide/guide-page-layout";
import MainLayout from "../../../common/layouts/main-layout";
import InformationBox from "../../../common/views/info-box/information-box";
import { PageSelector } from "../../../common/components/guide/guide-form";
import { useGetPublicGuide } from "../../../hooks/guide/guide.hooks";
import { localization } from "../../../localization/localization";
const mockText =
  localization("/pages/public/guide-pages/guide-detail/resource-use-limitation");

const GuideDetailPage = () => {
  const { guideId } = useParams();
  const { data: guideDetailData, isLoading: guideDetailIsLoading } =
    useGetPublicGuide(guideId);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  return (
    <MainLayout>
      {guideDetailIsLoading ? (
        <div>Loading ...</div>
      ) : (
        <>
          <div className="bg-White rounded-md pb-2">
            <InformationBox
              title={guideDetailData.name}
              text={mockText}
              searchBarPlaceholder={localization("/pages/public/guide-pages/guide-detail/search-in-guide")}
              isSearchbar={false}
            ></InformationBox>
            <div className="mx-6 flex flex-row gap-x-2">
              {guideDetailData.subjects.map((subject) => {
                return (
                  <BadgeInfo
                    leftChildren={subject.name}
                    leftChildrenClassName="text-xs font-Inter"
                  />
                );
              })}
            </div>
            <div
              id="guide-pages"
              className="m-6 bg-White flex flex-row flex-wrap"
            >
              <PageSelector
                pages={guideDetailData.pages}
                selectedPage={selectedPageIndex}
                setSelectedPage={setSelectedPageIndex}
                displayButtons={false}
              />
            </div>
          </div>
          {guideDetailData.pages.map((page) => {
            return (
              <PageBuilder
                page={page}
                selectedPage={selectedPageIndex}
                guidesData={guideDetailData}
                isEdit={false}
              />
            );
          })}
        </>
      )}
    </MainLayout>
  );
};

export default GuideDetailPage;
