import {
	CreateGuideManagementButtons,
	GuideMetaDataForm,
	PageBuilder,
	PageSelector,
} from '../../../common/components/guide/guide-form'
import { Form, notification } from 'antd'
import { useEffect, useState } from 'react'
import {
	useGetPublicGuide,
	useUpdateGuide,
} from '../../../hooks/guide/guide.hooks'

import GuideRowCreator from '../../../common/components/guide/guide-row-creator'
import { useGetCategories } from '../../../hooks/category/category.hooks'
import { useGetGuideSubjects } from '../../../hooks/guide-subject/guide-subject.hook'
import { useGetGuideTypes } from '../../../hooks/guide-type/guide-type.hook'
import { useParams } from 'react-router-dom'
import { localization } from '../../../localization/localization'

const EditGuidePage = () => {
	const [form] = Form.useForm()
	const { data: guideSubjects, isLoading: isSubjectsLoading } =
		useGetGuideSubjects(0, 9999)
	const { data: guideTypes, isLoading: isDabaseTypesLoading } =
		useGetGuideTypes(0, 9999)
	const { data: guideCategories, isLoading: isGuideCategoriesLoading } =
		useGetCategories()

	const { id } = useParams()

	const {
		data: guideDatas,
		isLoading: isGuideDataLoading,
		refetch: refetchGuideDatas,
	} = useGetPublicGuide(id)

	const { mutate: mutateUpdateGuide } = useUpdateGuide(id, () =>
		notification.success({
			description: localization("/pages/app/guide-pages/edit-guide/success"),
			message: localization("/pages/app/guide-pages/edit-guide/changes-saved"),
		})
	)

	const [guideDataTmp, setGuideDataTmp] = useState()
	const [selectedPage, setSelectedPage] = useState()

	const onSubmit = (formData) => {
		mutateUpdateGuide({ ...guideDataTmp, visible: formData.visible })
	}

	const getSeletedGuideTypes = (data, key) => {
		var selections = []
		data[key].forEach((element) => {
			selections.push(element.id)
		})
		return selections
	}

	useEffect(() => {
		if (!isGuideDataLoading) {
			var data = { ...guideDatas }
			data['subjectIds'] = getSeletedGuideTypes(guideDatas, 'subjects')
			data['typeIds'] = getSeletedGuideTypes(guideDatas, 'types')
			data['categoryIds'] = getSeletedGuideTypes(guideDatas, 'categories')
			setGuideDataTmp(data)
		}
	}, [isGuideDataLoading])

	const addNewPageHandler = () => {
		const tempPage = {
			id: Math.random().toString(36).substring(2, 7),
			created: new Date().getTime(),
			updated: new Date().getTime(),
			name: localization("/pages/app/guide-pages/edit-guide/page") + (guideDataTmp.pages.length + 1),
			index: guideDataTmp.pages.length,
			descriptions: 'There is no desc',
			rows: [],
		}

		const data = { ...guideDataTmp }

		data.pages.push(tempPage)
		setGuideDataTmp(data)
	}

	const addNewRowHandler = () => {
		const data = { ...guideDataTmp }
		const selected = data.pages.find((page) => page.id === selectedPage)

		const tempRow = {
			id: Math.random().toString(36).substring(2, 7),
			created: new Date().getTime(),
			updated: new Date().getTime(),
			index: selected.rows.length,
			columns: [],
		}

	
		const column = {
			id: Math.random().toString(36).substring(2, 7),
			updated: new Date().getTime(),
			created: new Date().getTime(),
			index: 0,
			width: 100,
			boxes: [
				{
					id: Math.random().toString(36).substring(2, 7),
					created: new Date().getTime(),
					updated: new Date().getTime(),
					name: `Box_${0}`,
					index: 0,
					content: 'Empty',
					contentType: 'HTML',
				},
			],
		}
		tempRow.columns.push(column)
	

		selected.rows.push(tempRow)
		setGuideDataTmp(data)
	}

	return (
		<>
			{isDabaseTypesLoading ||
			isSubjectsLoading ||
			isGuideCategoriesLoading ||
			isGuideDataLoading ||
			!guideDataTmp ? (
				<div> Loading ...</div>
			) : (
				<>
					<Form
						form={form}
						layout='vertical'
						className='p-6 rounded-md'
						onFinish={onSubmit}
					>
						<CreateGuideManagementButtons
							id={id}
							isEditPage={true}
							pageData={guideDataTmp}
						/>
						<div className='bg-White p-6'>
							<GuideMetaDataForm
								databaseTypes={guideTypes.content}
								subjects={guideSubjects.content}
								categories={guideCategories}
								guidesData={guideDataTmp}
								setForm={setGuideDataTmp}
							/>
						</div>
						<div className='bg-White my-3 px-6 py-3 flex flex-row gap-x-4 rounded-md'>
							<PageSelector
								pages={guideDataTmp.pages}
								selectedPage={selectedPage}
								setSelectedPage={setSelectedPage}
								onAddPageButtonClicked={addNewPageHandler}
								setForm={setGuideDataTmp}
							/>
						</div>
						{guideDataTmp.pages.map((page) => {
							return (
								<PageBuilder
									page={page}
									selectedPage={selectedPage}
									guidesData={guideDataTmp}
									setForm={setGuideDataTmp}
									isEdit={true}
								/>
							)
						})}
					</Form>
					<div className='p-6'>
						<GuideRowCreator
							onCreateNewRowButtonClicked={addNewRowHandler}
						/>
					</div>
				</>
			)}
		</>
	)
}

export default EditGuidePage
