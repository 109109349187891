import { useEffect, useState } from "react";
import { useGetAuthors } from "../../../hooks/user/user.hooks";
import { useGetDatabaseTypes } from "../../../hooks/database-type/database-type.hooks";
import Filter from "../../components/filter/filter";
import { useGetGuideTypes } from "../../../hooks/guide-type/guide-type.hook";
import { useGetDatabaseSubjects } from "../../../hooks/database-subject/database-subject.hook";
import { useGetGuideSubjects } from "../../../hooks/guide-subject/guide-subject.hook";
import { localization } from "../../../localization/localization";

const FilterView = ({ filterData, selections, getSelections }) => {
  return (
    <div>
      <div>
        <Filter
          filterData={filterData}
          selections={selections}
          getSelections={getSelections}
        />
      </div>
    </div>
  );
};

export const DatabaseFilterView = ({ getSelections }) => {
  const { data: databasesTypes, isLoading: isDatabaseTypesLoading } =
    useGetDatabaseTypes(0, 9999);
  const { data: authors, isLoading: isAuthorsLoading } = useGetAuthors(
    0,
    9999,
    ""
  );
  const { data: databaseSubjects, isLoading: isDatabaseSubjectsLoading } =
    useGetDatabaseSubjects(0, 9999);

  const isLoading =
    isAuthorsLoading || isDatabaseTypesLoading || isDatabaseSubjectsLoading;

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData((prevState) => {
        prevState = [];

        prevState.push({
          name: "subjects",
          label: localization("/common/views/filter-view/filter-view/topics"),
          data: databaseSubjects.content,
        });

        prevState.push({
          name: "types",
          label: localization("/common/views/filter-view/filter-view/types"),
          data: databasesTypes.content,
        });

        prevState.push({
          name: "authors",
          label: localization("/common/views/filter-view/filter-view/authors"),
          data: authors.content,
        });

        prevState.push({
          name: "statuses",
          label: "Status",
          data: [
            { name: "NEW", id: "NEW" },
            { name: "TRIAL", id: "TRIAL" },
          ],
        });
        return prevState;
      });
    }
  }, [isLoading]);

  return (
    <div>
      {isLoading ? (
        <div> Loading ...</div>
      ) : (
        <FilterView filterData={filterData} getSelections={getSelections} />
      )}
    </div>
  );
};

export const AdminDatabaseFilterView = ({ getSelections }) => {
  const { data: databasesTypes, isLoading: isDatabaseTypesLoading } =
    useGetDatabaseTypes(0, 9999);
  const { data: authors, isLoading: isAuthorsLoading } = useGetAuthors(
    0,
    9999,
    ""
  );
  const { data: databaseSubjects, isLoading: isDatabaseSubjectsLoading } =
    useGetDatabaseSubjects(0, 9999);

  const isLoading =
    isAuthorsLoading || isDatabaseTypesLoading || isDatabaseSubjectsLoading;

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData((prevState) => {
        prevState = [];

        prevState.push({
          name: "subjects",
          label: localization("/common/views/filter-view/filter-view/topics"),
          data: databaseSubjects.content,
        });

        prevState.push({
          name: "types",
          label: localization("/common/views/filter-view/filter-view/types"),
          data: databasesTypes.content,
        });

        prevState.push({
          name: "authors",
          label: localization("/common/views/filter-view/filter-view/authors"),
          data: authors.content,
        });

        prevState.push({
          name: "visible",
          label: localization(
            "/common/views/filter-view/filter-view/visibility"
          ),
          data: [
            {
              id: "true",
              name: localization(
                "/common/views/filter-view/filter-view/visible-databases"
              ),
            },
          ],
        });

        prevState.push({
          name: "statuses",
          label: "Status",
          data: [
            { name: "NEW", id: "NEW" },
            { name: "TRIAL", id: "TRIAL" },
          ],
        });
        return prevState;
      });
    }
  }, [isLoading]);

  return (
    <div>
      {isLoading ? (
        <div> Loading ...</div>
      ) : (
        <FilterView filterData={filterData} getSelections={getSelections} />
      )}
    </div>
  );
};

export const AuthorDatabaseFilterView = ({ getSelections }) => {
  const { data: databasesTypes, isLoading: isDatabaseTypesLoading } =
    useGetDatabaseTypes(0, 9999);
  const { data: databaseSubjects, isLoading: isSubjectLoading } =
    useGetDatabaseSubjects(0, 9999);
  const isLoading = isSubjectLoading || isDatabaseTypesLoading;

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData((prevState) => {
        prevState = [];

        prevState.push({
          name: "subjects",
          label: localization("/common/views/filter-view/filter-view/topics"),
          data: databaseSubjects.content,
        });

        prevState.push({
          name: "types",
          label: localization("/common/views/filter-view/filter-view/types"),
          data: databasesTypes.content,
        });

        return prevState;
      });
    }
  }, [isLoading]);
  return (
    <div>
      {isLoading ? (
        <div> Loading ...</div>
      ) : (
        <FilterView filterData={filterData} getSelections={getSelections} />
      )}
    </div>
  );
};

export const AuthorGuideFilterView = ({ getSelections }) => {
  const { data: guideTypes, isLoading: isGuideTypesLoading } = useGetGuideTypes(
    0,
    9999
  );
  const { data: guideSubjects, isLoading: isGuideSubjectLoading } =
    useGetGuideSubjects(0, 9999);
  const isLoading = isGuideTypesLoading || isGuideSubjectLoading;

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData((prevState) => {
        prevState = [];

        prevState.push({
          name: "subjects",
          label: localization("/common/views/filter-view/filter-view/topics"),
          data: guideSubjects.content,
        });

        prevState.push({
          name: "types",
          label: localization("/common/views/filter-view/filter-view/types"),
          data: guideTypes.content,
        });

        return prevState;
      });
    }
  }, [isLoading]);
  return (
    <div>
      {isLoading ? (
        <div> Loading ...</div>
      ) : (
        <FilterView filterData={filterData} getSelections={getSelections} />
      )}
    </div>
  );
};
export const GuideFilterView = ({ getSelections }) => {
  const { data: guideTypes, isLoading: isGuideTypesLoading } = useGetGuideTypes(
    0,
    9999
  );
  const { data: authors, isLoading: isAuthorsLoading } = useGetAuthors(
    0,
    9999,
    ""
  );
  const { data: guideSubjects, isLoading: isGuideSubjectLoading } =
    useGetDatabaseSubjects(0, 9999);
  const isLoading =
    isAuthorsLoading || isGuideTypesLoading || isGuideSubjectLoading;

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData((prevState) => {
        prevState = [];

        prevState.push({
          name: "subjects",
          label: localization("/common/views/filter-view/filter-view/topics"),
          data: guideSubjects.content,
        });

        prevState.push({
          name: "types",
          label: localization("/common/views/filter-view/filter-view/types"),
          data: guideTypes.content,
        });

        prevState.push({
          name: "authors",
          label: localization("/common/views/filter-view/filter-view/authors"),
          data: authors.content,
        });
        return prevState;
      });
    }
  }, [isLoading]);

  return (
    <div>
      {isLoading ? (
        <div> Loading ...</div>
      ) : (
        <div>
          <FilterView filterData={filterData} getSelections={getSelections} />
        </div>
      )}
    </div>
  );
};

export const AdminGuideFilterView = ({ getSelections }) => {
  const { data: guideTypes, isLoading: isGuideTypesLoading } = useGetGuideTypes(
    0,
    9999
  );
  const { data: authors, isLoading: isAuthorsLoading } = useGetAuthors(
    0,
    9999,
    ""
  );
  const { data: guideSubjects, isLoading: isGuideSubjectsLoading } =
    useGetGuideSubjects(0, 9999);

  const isLoading =
    isAuthorsLoading || isGuideSubjectsLoading || isGuideTypesLoading;

  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData((prevState) => {
        prevState = [];

        prevState.push({
          name: "subjects",
          label: localization("/common/views/filter-view/filter-view/topics"),
          data: guideSubjects.content,
        });

        prevState.push({
          name: "types",
          label: localization("/common/views/filter-view/filter-view/types"),
          data: guideTypes.content,
        });

        prevState.push({
          name: "authors",
          label: localization("/common/views/filter-view/filter-view/authors"),
          data: authors.content,
        });

        prevState.push({
          name: "visible",
          label: localization(
            "/common/views/filter-view/filter-view/visibility"
          ),
          data: [
            {
              id: "true",
              name: localization(
                "/common/views/filter-view/filter-view/visible-guides"
              ),
            },
          ],
        });
        return prevState;
      });
    }
  }, [isLoading]);

  return (
    <div>
      {isLoading ? (
        <div> Loading ...</div>
      ) : (
        <FilterView filterData={filterData} getSelections={getSelections} />
      )}
    </div>
  );
};
