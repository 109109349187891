import React, { useEffect, useState } from "react";

import SaveButton from "../../../common/components/buttons/save-button";
import CategoryButtonGroup from "../../../common/components/category/category-button-group";
import CategoryButton from "../../../common/components/category/category-button";
import CategoryDeleteModal from "../../../common/components/category/category-delete-modal";

import { localization } from "../../../localization/localization";
import { Button, Form, Spin, Table, notification } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import {
  AlignLeftOutlined,
  UpOutlined,
  PictureOutlined,
  DownOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import { useDownloadFile, useUploadFile } from "../../../hooks/file/file.hooks";
import defaultPhoto from '../../../assets/categories/no-image.jpg'
import {
  useGetCategories,
  useGetCategory,
  useUpdateCategory,
} from "../../../hooks/category/category.hooks";

function getChildren(data, categoryId) {
  const itemsById = data.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});

  data.forEach((item) => {
    if (item.ancestorCategory) {
      const parent = itemsById[item.ancestorCategory.id];
      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }
        item.key = item.id;
        parent.children.push(item);
      }
    }
  });

  const category = itemsById[categoryId];
  if (category && category.children) {
    return category.children;
  } else {
    return null;
  }
}

const columns = [
  {
    render: (child) => (
      <p className="text-Black text-base font-medium">{child.name}</p>
    ),
  },

  {
    width: 1,
    render: (child) => (
      <div className="flex justify-end gap-4">
        <CategoryButtonGroup child={child} />
      </div>
    ),
  },
];

const EditCategoryPage = () => {
  const [showUploadList, setShowUploadList] = useState(true);
  const [uploadedPhoto, setUploadedPhoto] = useState(true);
  const [children, setChildren] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const { id: categoryId } = useParams();
  const { mutate: mutateFile, isSuccess: isUploaded } = useUploadFile();
  const {
    data: categories,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetCategories();
  const {
    data: categoryData,
    isFetching: isCategoryDataFetching,
    refetch: refetchGuideData,
  } = useGetCategory(categoryId);

  const [image, setImage] = useState(null);
  const {
    mutate: downloadImage,
    isLoading: isDownloading,
    isError: isDownloadError,
  } = useDownloadFile();

  const onSuccess = () => {
    notification.success({
      message: localization("/pages/app/category-pages/edit-category/success"),
      description: localization(
        "/pages/app/category-pages/edit-category/update-success"
      ),
    });
    refetchGuideData();
    setShowUploadList(false);
  };

  const { mutate: mutateUpdateCategory } = useUpdateCategory(
    categoryId,
    onSuccess
  );

  const [form] = useForm();

  useEffect(() => {
    if (categories) {
      const children = getChildren(categories, categoryId);
      setChildren(children);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (categoryData) {
      downloadImage(categoryData.photo.id, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setImage(bufferObject);
        },
      });
    }
  }, [categoryData]);

  const showExpandIcon = (record) => {
    const { expanded, onExpand } = record;

    if (expanded) {
      return (
        <UpOutlined
          className="float-right mr-1.5 pt-1.5"
          onClick={(e) => onExpand(record.record, e)}
        />
      );
    } else {
      return (
        <DownOutlined
          className={`float-right mr-1.5 pt-1.5 ${
            !record.record.children ? `hidden` : null
          }`}
          onClick={(e) => onExpand(record.record, e)}
        />
      );
    }
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    accept: "image/png, image/jpeg",
    maxCount: 1,
    showUploadList: showUploadList,

    beforeUpload: (file) => {
      setShowUploadList(true);
      setUploadedPhoto(file);
      return false;
    },
  };

  const handleSubmit = () => {
    const body = {
      name: categoryData?.name,
    };
    if (uploadedPhoto) {
      const formData = new FormData();
      formData.append("file", uploadedPhoto);
      mutateFile(formData, {
        onSuccess: (data) => {
          body.photoId = data.id;
          mutateUpdateCategory(body);
        },
      });
    } else {
      body.photoId = categoryData?.photo.id;
      mutateUpdateCategory(body, {
        onSuccess: () => {
          refetchGuideData();
        },
      });
    }
  };
  return (
    <div className="flex flex-col items-start">
      <div className="w-full">
        <Spin spinning={isCategoryDataFetching}>
          <Form form={form} onFinish={handleSubmit}>
            <div className="flex justify-between">
              <Form.Item className="mb-8">
                <SaveButton>
                  {localization(
                    "/pages/app/category-pages/edit-category/save-the-changes"
                  )}
                </SaveButton>
              </Form.Item>
              <div>
                <Button
                  onClick={() => setDeleteModalOpen(true)}
                  size="large"
                  className="text-base flex flex-col items-center justify-center rounded-lg text-Error-500 bg-Error-200 border-Error-500 hover:bg-Error-500 hover:text-Error-200 hover:border-Error-500"
                >
                  {localization(
                    "/pages/app/category-pages/edit-category/delete-the-category"
                  )}
                </Button>
                <CategoryDeleteModal
                  isOpen={isDeleteModalOpen}
                  setOpen={() => setDeleteModalOpen()}
                  childId={categoryData?.id}
                />
              </div>
            </div>
            <div className="w-full bg-White p-6">
              <div className="mb-6 flex flex-1">
                <div className="text-xl text-black mr-3">
                  <PictureOutlined className="bg-Primary-50 p-3 rounded-full " />
                </div>

                <div className="flex flex-col font-Inter">
                  <div className="font-medium text-Black text-base">
                    {localization(
                      "/pages/app/category-pages/edit-category/category-photo"
                    )}
                  </div>
                  <p className="text-Grey-600 font-normal text-sm">
                    {localization(
                      "/pages/app/category-pages/edit-category/organize-all-categories"
                    )}
                  </p>
                </div>
              </div>

              <div className="flex w-full">
                <div className="flex items-center basis-8/12">
                    <Spin spinning={isDownloading}>
                      <div
                        style={{
                          width: "212px",
                          height: "282px",
                        }}
                        className="mr-6"
                      >
                        <img
                          alt="category_photo"
                          src={image || defaultPhoto}
                          className="object-fill h-full rounded-md"
                        />
                      </div>
                    </Spin>

                  <Form.Item className="mr-6 flex-1" name={["photo"]}>
                    <Dragger
                      {...draggerProps}
                      className="border-solid"
                      style={{
                        padding: "0px 12px",
                        border: "solid 1px",
                        borderColor: "#E8EAEB",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="box-border ">
                        <p className="text-Grey-500 font-normal text-sm">
                          {localization(
                            "/pages/app/category-pages/edit-categories/drag-drop-info"
                          )}
                        </p>
                      </div>
                      <Button
                        size="large"
                        className="my-2 border-1 border-Grey-200 shadow-sm shadow-Primary-500/5 rounded-lg text-Primary-500 hover:bg-Primary-50 hover:text-Primary-500 hover:border-Grey-200 hover:border-1"
                      >
                        {localization(
                          "/pages/app/category-pages/edit-categories/choose-a-file"
                        )}
                      </Button>
                      <p className="text-Grey-500 font-normal text-sm">
                        {localization(
                          "/pages/app/category-pages/edit-categories/category-photo-format"
                        )}
                      </p>
                    </Dragger>
                  </Form.Item>
                </div>

                <div className="flex flex-col font-Inter self-start gap-y-3 basis-4/12 text-base">
                  <h3 className="font-medium text-Black text-base">
                    {localization(
                      "/pages/app/category-pages/edit-category/suggestions"
                    )}
                  </h3>

                  <div className="text-Grey-600 font-normal flex items-start  ">
                    <CheckCircleOutlined className="mt-1 mr-2" />

                    <span className="self-center text-sm">
                      {localization(
                        "/pages/app/category-pages/edit-category/sufficient-photo"
                      )}
                    </span>
                  </div>
                  <div className="text-Grey-600 font-normal flex items-start ">
                    <CheckCircleOutlined className="mt-1 mr-2" />

                    <span className="self-center text-sm">
                      {localization(
                        "/pages/app/category-pages/edit-category/high-res-photo"
                      )}
                    </span>
                  </div>
                  <div className="text-Grey-600 font-normal flex items-start ">
                    <CheckCircleOutlined className="mt-1 mr-2" />

                    <span className="self-center text-sm">
                      {localization(
                        "/pages/app/category-pages/edit-category/real-photo"
                      )}
                    </span>
                  </div>
                  <div className="text-Grey-600 font-normal  flex items-start ">
                    <CheckCircleOutlined className="mt-1 mr-2" />

                    <span className="self-center text-sm">
                      {localization(
                        "/pages/app/category-pages/edit-category/copyright-photo"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </div>

      <div className="mt-4 w-full ">
        <Spin spinning={isLoading}>
          <Table
            expandIconColumnIndex={0}
            title={() => (
              <div className="rounded-md flex items-center bg-White">
                <div className="bg-Primary-50 p-3 rounded-full flex items-center text-2xl text-Primary-500 ">
                  <AlignLeftOutlined />
                </div>

                <div className="mx-3 font-Inter flex-1 ">
                  <div className="font-medium text-Black text-base mb-2">
                    {localization(
                      "/pages/app/category-pages/edit-category/category-management"
                    )}
                  </div>
                  <div className="font-normal text-Grey-600 text-sm">
                    {localization(
                      "/pages/app/category-pages/edit-category/organize-all-categories"
                    )}
                  </div>
                </div>
                {children && (
                  <div className="mt-4">
                    <CategoryButton
                      size={"large"}
                      child={categoryData}
                      buttonClass="text-base text-Primary-500 items-center rounded-lg bg-Primary-50 hover:shadow-sm hover:text-Primary-500 hover:border-Primary-500 hover:bg-Primary-50"
                      text={"Alt Kategori Ekle"}
                    />
                  </div>
                )}
              </div>
            )}
            locale={{
              emptyText: (
                <div className="border-2 border-Gray border-dashed p-3">
                  <p className="text-lg mb-2 font-Inter">
                    {localization(
                      "/pages/app/category-pages/edit-category/empty-category"
                    )}
                  </p>
                  <CategoryButton
                    size={"large"}
                    child={categoryData}
                    buttonClass="text-base items-center rounded-lg bg-Primary-500 text-White hover:bg-Primary-50 hover:text-Primary-500 hover:border-Primary-500"
                    text={localization(
                      "/pages/app/category-pages/edit-category/new-category"
                    )}
                  />
                </div>
              ),
            }}
            columns={columns}
            showHeader={false}
            dataSource={children}
            expandIcon={(record) => showExpandIcon(record)}
          />
        </Spin>
      </div>
    </div>
  );
};

export default EditCategoryPage;
