import * as api from "../api";

export const postGuideSubject = (body) => {
  return api.post(`/guide-subject`, body);
};

export const deleteGuideSubject = (id) => {
  return api.del(`/guide-subject/${id}`);
};

export const getGuideSubjects = (page, size) => {
  return api.get(`/guide-subject?page=${page}&size=${size}`);
};
