import React from "react";
import library from "../../../assets/carousel/library.jpg";
import { localization } from "../../../localization/localization";

const CategoryCarousel = () => (
  <div className="relative overflow-clip max-h-[480px]">
    <img
      className="max-h-[840px] object-cover object-center w-full"
      src={library}
      alt="libraryImg"
    />
    <div className="flex items-center justify-center absolute top-0 left-0 right-0 bottom-0 ">
      <p className="basis-3/5 text-center text-6xl font-bold text-White">
        {localization(
          "/common/components/carousel/category-carousel/gguides-presentation"
        )}
      </p>
    </div>
  </div>
);
export default CategoryCarousel;
