import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SearchButton from "../../../common/components/buttons/search-button";
import SearchBar from "../../../common/components/searchBar/search-bar";
import MainLayout from "../../../common/layouts/main-layout";
import AuthorDetailView from "../../../common/views/author/author-detail-view";
import DatabaseListView from "../../../common/views/database/database-list-view";
import {
  AuthorDatabaseFilterView,
  AuthorGuideFilterView,
  DatabaseFilterView,
  GuideFilterView,
} from "../../../common/views/filter-view/filter-view";
import GuideListView from "../../../common/views/guide/guide-list-view";
import PageSelector from "../../../common/views/page-selection/page-selector";
import { useGetAuthor } from "../../../hooks/user/user.hooks";
import { useGetFilteredPublicDatabases } from "../../../hooks/database/database.hooks";
import { useGetFilteredPublicGuides } from "../../../hooks/guide/guide.hooks";
import { Col, Row } from "antd";
import { localization } from "../../../localization/localization";
import { useDownloadFile } from "../../../hooks/file/file.hooks";

const AuthorDetailPage = () => {
  const { authorId } = useParams();
  const { data: authorData, isLoading: isAuthorDataLoading } =
    useGetAuthor(authorId);

  const [ image, setImage ] = useState(null)
  const { mutate:downloadImage } = useDownloadFile();

  useEffect(() => {
    if (authorData && authorData.photo) {
      downloadImage(authorData.photo.id, {
        onSuccess: (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let bufferObject = window.URL.createObjectURL(blob);
          setImage(bufferObject);
        },
      });
    }
  }, [authorData]);

  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  const sortingType = [
    { name: localization("/pages/public/author-pages/author-detail/guides"), fields: "guides" },
    { name: localization("/pages/public/author-pages/author-detail/databases"), fields: "databases" },
  ];

  const [searchKey, setSearchKey] = useState("");
  const [filterList, setFilterList] = useState(null);

  const getSelections = (selectedFilters) => {
    setFilterList(selectedFilters);
  };

  const { data: databases, isLoading: isDatabaseLoading } =
    useGetFilteredPublicDatabases(0, 40, searchKey, {
      ...filterList,
      authors: [authorId],
    }, "", "");
  
  const { data: guidesData, isLoading: isGuideLoading } =
    useGetFilteredPublicGuides(0, 40, searchKey, {
      ...filterList,
      authors: [authorId],
    });

  const onSearchClicked = (value) => {
    setSearchKey(value);
  };

  return (
    <MainLayout>
      {isAuthorDataLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="pb-6">
          <div className="bg-White rounded-md p-6">
            <AuthorDetailView image={image} authorData={authorData} />
          </div>
          <div
            id="guide-pages"
            className="py-3 px-6 bg-White mt-6 flex flex-row flex-wrap"
          >
            <div className="my-auto">
              <PageSelector
                buttonInformation={sortingType}
                setSelectedPage={setSelectedPageIndex}
              />
            </div>
          </div>
          <div className="my-6 bg-White px-6 py-3 rounded-md">
            <div className="flex flex-row">
              <div className="block w-full">
                <SearchBar
                  className="my-auto "
                  placeholder={localization("/pages/public/author-pages/author-detail/search-authors")}
                  onSearchClicked={onSearchClicked}
                />
              </div>
            </div>
          </div>
          {sortingType.at(selectedPageIndex).fields === "guides" ? (
            <>
              <Row gutter={24}>
                <Col span={16}>
                  <GuideListView
                    guidesData={guidesData}
                    isLoading={isGuideLoading}
                  />
                </Col>
                <Col span={8}>
                  <AuthorGuideFilterView getSelections={getSelections} />
                </Col>
              </Row>
            </>
          ) : sortingType.at(selectedPageIndex).fields === "databases" ? (
            <div className="mt-4 grid grid-cols-4 gap-4">
              <DatabaseListView
                databases={databases}
                isLoading={isDatabaseLoading}
              />
              <AuthorDatabaseFilterView getSelections={getSelections} />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </MainLayout>
  );
};

export default AuthorDetailPage;
