import clsx from "clsx";

const Badge = ({
  leftChildren,
  leftChildrenClassName,
  rightChildren,
  onBadgeClicked,
  children,
  bgColor,
  textColor,
  extraRound,
}) => {
  return (
    <div className="flex flex-row">
      <button
        onClick={onBadgeClicked}
        className={clsx(
          "rounded-md flex justify-center gap-1 font-Inter text-sm my-auto py-0.5 px-2 bg-Grey-50 text-Primary-500 items-center cursor-default",
          bgColor,
          textColor,
          extraRound
        )}
      >
        <div className={clsx(leftChildrenClassName && leftChildrenClassName)}>
          {leftChildren}
        </div>
        {rightChildren && <div>{rightChildren}</div>}
        {children && <div className="my-auto">{children}</div>}
      </button>
    </div>
  );
};

export const BadgeInfo = ({
  leftChildren,
  leftChildrenClassName,
  rightChildren,
  children,
}) => {
  return (
    <Badge
      bgColor={" bg-Grey-50 hover:shadow-sm"}
      textColor={" text-Grey-700"}
      leftChildren={leftChildren}
      rightChildren={rightChildren}
      leftChildrenClassName={leftChildrenClassName}
      children={children}
    />
  );
};


export const BadgeSuccess = ({
  leftChildren,
  rightChildren,
  leftChildrenClassName,
  children,
  info,
}) => {
  return (
    <Badge
      bgColor={" bg-Success-50"}
      textColor={" text-Success-700"}
      extraRound={!info ? "rounded-2xl" : ""}
      leftChildren={leftChildren}
      rightChildren={rightChildren}
      leftChildrenClassName={leftChildrenClassName}
      children={children}
    />
  );
};


export const BadgeGuide = ({
  leftChildren,
  leftChildrenClassName,
  rightChildren,
  children,
}) => {
  return (
    <Badge
      bgColor={" bg-Grey-50"}
      textColor={" text-Grey-700"}
      extraRound={" rounded-2xl"}
      leftChildren={leftChildren}
      rightChildren={rightChildren}
      leftChildrenClassName={leftChildrenClassName}
      children={children}
    />
  );
};

export const BadgeDatabase = ({
  leftChildren,
  rightChildren,
  leftChildrenClassName,
  children,
}) => {
  return (
    <Badge
      bgColor={"bg-Grey-50"}
      textColor={" text-Grey-700"}
      extraRound={" rounded-2xl"}
      leftChildren={leftChildren}
      rightChildren={rightChildren}
      leftChildrenClassName={leftChildrenClassName}
      children={children}
    />
  );
};

export const BadgeAuthor = ({
  leftChildren,
  rightChildren,
  leftChildrenClassName,
  children,
}) => {
  return (
    <Badge
      bgColor={"bg-Primary-50"}
      textColor={" text-Primary-500"}
      extraRound={" rounded-2xl"}
      leftChildren={leftChildren}
      rightChildren={rightChildren}
      leftChildrenClassName={leftChildrenClassName}
      children={children}
    />
  );
};
