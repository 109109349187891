import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./navbar-dropdown.css";

export const DropdownButton = ({ title, children, to, onClicked }) => {
  return (
    <Link to={to} onClick={onClicked}>
      <div className="cursor-pointer text-Grey-600 font-medium">
        <div className="flex flex-row">
          <div className="justify-start">{children}</div>
          <div className="self-center">{title}</div>
        </div>
      </div>
    </Link>
  );
};

const Dropdown = ({ children, buttons }) => {
  return (
    <div className="dropdown" style={{ float: "right" }}>
      <div className="dropbtn">{children}</div>
      <div className="dropdown-content w-52">
        {buttons ?? buttons.map((element) => element)}
      </div>
    </div>
  );
};

export default Dropdown;
