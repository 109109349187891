import { Button, Col, Row } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BadgeInfo } from "../../../common/components/buttons/badge";
import DatabaseListView from "../../../common/views/database/database-list-view";
import { AdminDatabaseFilterView } from "../../../common/views/filter-view/filter-view";
import InformationBox from "../../../common/views/info-box/information-box";
import { useGetFilteredDatabases } from "../../../hooks/database/database.hooks";
import { useGetTenantDetail } from "../../../hooks/tenant/tenant.hooks";
import {
  AddDatabaseIcon,
  ExternalAccessIcon,
} from "../../../icons/common-icon";
import { localization } from "../../../localization/localization";
import AddButton from "../../../common/components/buttons/add-button";
import AZFilterView from "../../../common/views/filter-view/az-filter-view";

const DatabaseListEditorPage = () => {
  const navigate = useNavigate();
  const [filterList, setFilterList] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [firstChar, setFirstChar] = useState("");
  const { id: categoryIds } = useParams();

  const getSelections = (selections) => {
    setFilterList(selections);
  };

  const { data: databases, isLoading } = useGetFilteredDatabases(
    0,
    40,
    searchKey,
    filterList,
    firstChar,
    categoryIds
  );

  const { data: tenantSettings, isLoading: isTenantDetailLoading } =
    useGetTenantDetail();

  const onReadDescriptionClicked = (address) => {
    window.open(`http://${address}`, "_blank");
  };
  return (
    <>
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-9 rounded-md ">
          <div className="h-full">
            {!(isTenantDetailLoading || isLoading) && (
              <InformationBox
                setSearchKey={setSearchKey}
                searchBarPlaceholder={localization(
                  "/pages/app/database-pages/database-list-editor/search-database"
                )}
                title={localization(
                  "/pages/app/database-pages/database-list-editor/databases"
                )}
                text={tenantSettings.databaseDescription}
                extendedText
              >
                <BadgeInfo
                  leftChildren={databases.totalElements}
                  rightChildren={localization(
                    "/pages/app/database-pages/database-list-editor/database"
                  )}
                />
              </InformationBox>
            )}
          </div>
        </div>
        <div className="col-span-3 p-6 bg-White">
          <div className="flex flex-col gap-y-2">
            <div className="m-auto">
              <ExternalAccessIcon />
            </div>

            <div className="m-auto font-Inter text-Grey-600 text-xl">
              {localization(
                "/pages/app/database-pages/database-list-editor/off-campus-access"
              )}
            </div>
            <Button
              size="large"
              className="text-Primary-500 bg-Primary-50 hover:text-Primary-500 hover:border-Primary-500 text-base rounded-md font-Inter font-medium mt-4"
              onClick={() =>
                onReadDescriptionClicked(tenantSettings.databaseExternalLink)
              }
            >
              {localization(
                "/pages/app/database-pages/database-list-editor/learn-more"
              )}
            </Button>
          </div>
        </div>
      </div>
      <AddButton
        text={localization(
          "/pages/app/database-pages/database-list-editor/add-database"
        )}
        icon={<AddDatabaseIcon width="24" height="24" />}
        onClick={() => navigate("/app/add-database")}
      />
      <Row gutter={[24, 24]} className="my-2">
        <Col span={18}>
          <AZFilterView firstChar={firstChar} setFirstChar={setFirstChar} />
          <DatabaseListView
            databases={databases}
            isLoading={isLoading}
            isEdit={true}
          />
        </Col>
        <Col span={6}>
          <AdminDatabaseFilterView getSelections={getSelections} />
        </Col>
      </Row>
    </>
  );
};

export default DatabaseListEditorPage;
