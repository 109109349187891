import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  getVendors,
  postVendor,
  deleteVendor,
} from "../../api/vendor/vendor.api";
import { localization } from "../../localization/localization";

export const useGetVendors = (page, size) => {
  return useQuery("get-vendors-list", () => {
    return getVendors(page, size);
  });
};

export const useCreateVendor = (onSuccess, onError) => {
  return useMutation(
    "create-vendor",
    (body) => {
      return postVendor(body);
    },
    {
      onSuccess,
      onError,
    }
  );
};

export const useDeleteVendor = (onSuccess, onError) => {
  return useMutation(
    "delete-vendor",
    (id) => {
      return deleteVendor(id);
    },
    {
      onSuccess,
      onError: (error) => {
        notification.error({
          description: localization("/hooks/vendor/vendor-hooks/delete-failed"),
          message: error.message,
        });
      },
    }
  );
};
